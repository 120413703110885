<template>
    <v-container fluid class="py-6" style="padding: 0px;">
        <!-- Заголовок страницы -->
        <h1 class="text-h4 mb-6">Календарь</h1>

        <v-row>
            <!-- Левая часть: вкладки и список мероприятий -->
            <v-col cols="12" md="8">
                <!-- Вкладки -->
                <v-tabs v-model="activeTab" background-color="primary" dark slider-color="#7B65F7" class="mb-4">
                    <v-tab v-for="tab in tabs" :key="tab.id">
                        {{ tab.label }}
                    </v-tab>
                </v-tabs>

                <!-- Фильтры (только для истории) -->
                <v-chip-group v-if="activeTab === (isMentor ? 2 : 1)" v-model="selectedFilter" mandatory class="mb-4">
                    <v-chip v-for="filter in filters" :key="filter.id" :value="filter.id" variant="outlined"
                        color="primary" class="mr-2">
                        {{ filter.label }}
                    </v-chip>
                </v-chip-group>

                <!-- Содержимое вкладок -->
                <v-window v-model="activeTab">
                    <!-- Вкладка: "Веду" (только для менторов) -->
                    <v-window-item v-if="isMentor" :value="0">
                        <TemplateBlock :status="status" :error-message="errorMessage" :filtered-events="leadingEvents"
                            empty-text="Нет мероприятий, которые вы ведёте" mode="leading" @fetchAgain="fetchEvents"
                            @openEvent="openEvent" @joinEvent="joinEvent" @editEvent="editEvent"
                            @cancelEvent="cancelEvent" />
                    </v-window-item>

                    <!-- Вкладка: "Участвую" -->
                    <v-window-item :value="isMentor ? 1 : 0">
                        <TemplateBlock :status="status" :error-message="errorMessage"
                            :filtered-events="participatingEvents" empty-text="Нет мероприятий, в которых вы участвуете"
                            mode="participating" @fetchAgain="fetchEvents" @openEvent="openEvent" @joinEvent="joinEvent"
                            @leaveEvent="leaveEvent" />
                    </v-window-item>

                    <!-- Вкладка: "История" -->
                    <v-window-item :value="isMentor ? 2 : 1">
                        <TemplateBlock :status="status" :error-message="errorMessage" :filtered-events="historyEvents"
                            empty-text="Ваша история пока пуста" mode="history" @fetchAgain="fetchEvents"
                            @openEvent="openEvent" @joinEvent="joinEvent" />
                    </v-window-item>
                    <!-- Вкладка: "Календарь" -->
                    <v-window-item v-if="isMobile">
                        <CalendarComponent :events="events" @day-selected="handleDaySelected" @open-event="openEvent" />
                    </v-window-item>
                </v-window>
            </v-col>

            <!-- Правая часть: календарь -->
            <v-col cols="12" md="4" v-if="!isMobile">
                <CalendarComponent :events="events" @day-selected="handleDaySelected" @open-event="openEvent" />
            </v-col>
        </v-row>
        <ConsultationModal v-model="consultationModal" :event="selectedConsultation"
            @confirmed="handleConsultationConfirmed" />
    </v-container>
</template>

<script setup lang="ts">
/* eslint-disable */
import { ref, computed, onMounted } from 'vue';
import { useWindowSize } from '@vueuse/core';
import { format, subMonths, addMonths } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useDate } from 'vuetify';
import TemplateBlock from '@/components/pages/calendar/TemplateBlock.vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import CalendarComponent from '@/components/pages/calendar/CalendarComponent.vue';
import ConsultationModal from '@/components/pages/calendar/ConsultationModal.vue';



const dateAdapter = useDate();
const { width } = useWindowSize();
const isMobile = computed(() => width.value < 960);
const store = useStore();
const router = useRouter();
const isMentor = computed(() => store.getters.userRole === 'mentor');

// const calendarOptions = ref({
//     plugins: [dayGridPlugin],
//     initialView: 'dayGridMonth',
//     weekends: false,
//     events: [
//         { title: 'Meeting', start: new Date() }
//     ]
// })

// Динамические вкладки в зависимости от роли
function getTabs(isMobile: boolean, isMentor: boolean) {
    // Мобильная версия
    if (isMobile) {
        return isMentor
            ? [
                { id: 0, label: 'Веду' },
                { id: 1, label: 'Участвую' },
                { id: 2, label: 'История' },
                { id: 3, label: 'Календарь' },
            ]
            : [
                { id: 0, label: 'Участвую' },
                { id: 1, label: 'История' },
                { id: 2, label: 'Календарь' },
            ];
    }

    // Десктопная версия
    return isMentor
        ? [
            { id: 0, label: 'Веду' },
            { id: 1, label: 'Участвую' },
            { id: 2, label: 'История' },
        ]
        : [
            { id: 0, label: 'Участвую' },
            { id: 1, label: 'История' },
        ];
}

const tabs = computed(() => {
    return getTabs(isMobile.value, isMentor.value);
});


const activeTab = ref(0);

// Фильтры для "Истории"
const filters = [
    { id: 0, label: 'Все' },
    { id: 1, label: 'Проведённые' },
    { id: 2, label: 'Посещённые' },
    { id: 3, label: 'Отменённые' },
];
const selectedFilter = ref(0);

// Интерфейс события
interface EventData {
    id: number;
    title: string;
    date: string;
    description?: string;
    status?: 'live' | 'cancelled' | 'done' | 'pending';
    role: 'leading' | 'participating';
    sessionType?: string;
    bookingId?: number;
}

const events = ref<EventData[]>([]);

// Статус и ошибка
const status = ref<'idle' | 'loading' | 'success' | 'error'>('idle');
const errorMessage = ref('');

const selectedDate = ref<Date | null>(new Date());

// Форматированная выбранная дата
const formatSelectedDate = computed(() => {
    if (!selectedDate.value) return '';
    return format(new Date(selectedDate.value), 'd MMMM yyyy', { locale: ru });
});



// Modal state
const consultationModal = ref(false);
const selectedConsultation = ref<EventData | null>(null);

function findEventById(id: number): EventData | undefined {
    return events.value.find((e) => e.id === id);
}

// Даты с событиями для календаря
const eventDates = computed(() => {
    const dates = new Set<string>();
    events.value.forEach(event => {
        const date = new Date(event.date).toISOString().substr(0, 10);
        dates.add(date);
    });
    return Array.from(dates);
});

// Проверка наличия события на дату
function hasEventOnDate(dateVal: string | Date): boolean {
    const dateStr = typeof dateVal === 'string' ? dateVal : dateAdapter.toISO(dateVal).substr(0, 10);
    return eventDates.value.includes(dateStr);
}

async function openEvent(id: number) {
    console.log('open event was requested')
    const event = findEventById(id);
    if (!event) {
        console.error('Event not found:', id);
        return;
    }
    console.log('open event was requested with id ', id)

    if (event.sessionType === 'Event') {
        console.log('EVENT : open event was requested with id ', id)
        router.push(`/event_info/${id}/`);
    } else if (event.sessionType === 'Consultation') {
        console.log('CONSULTATION : open event was requested with id ', id)
        selectedConsultation.value = event;
        consultationModal.value = true;
    }
}

async function joinEvent(id: number) {
    const event = findEventById(id);
    if (!event) {
        console.error('Event not found:', id);
        return;
    }

    if (event.sessionType === 'Event') {
        if (event.status === 'live') {
            router.push(`/event-conference/${id}/`);
        }
    } else if (event.sessionType === 'Consultation') {
        if (event.status === 'live') {
            const bookingId = event.bookingId || event.id; // bookingId for leading, id for participating
            router.push(`/booking-conference/${bookingId}/`);
        }
    }
}

async function handleConsultationConfirmed(event: EventData) {
    console.log('Consultation confirmed:', event);
    // Здесь можно добавить API-вызов для подтверждения, например:
    // await axios.post(`/consultation-bookings/${event.bookingId}/confirm/`);
    await fetchEvents(); // Обновляем события после подтверждения
}


async function leaveEvent(id: number) {
    const event = findEventById(id);
    if (!event) {
        console.error('Event not found:', id);
        return;
    }

    const userId = store.getters.userId;
    if (event.sessionType === 'Event') {
        try {
            await axios.post('/events/unregister/', {
                event_id: event.id,
                user_id: userId,
            });
            fetchEvents(); // Refresh events
        } catch (error) {
            console.error('Failed to unregister from event:', error.response?.data || error.message);
        }
    } else if (event.sessionType === 'Consultation') {
        try {
            await axios.post(`/consultation-bookings/${event.id}/cancel/`, {
                user_id: userId,
            });
            fetchEvents();
        } catch (error) {
            console.error('Failed to cancel consultation booking:', error.response?.data || error.message);
        }
    }
}

function editEvent(id: number) {
    const event = findEventById(id);
    if (event && event.sessionType === 'Event' && event.role === 'leading') {
        router.push(`/edit_event/${id}/`); // Assuming an edit route exists
    }
}

async function cancelEvent(id: number) {
    const event = findEventById(id);
    if (event && event.sessionType === 'Event' && event.role === 'leading') {
        try {
            await axios.post('/events/cancel_event/', { event_id: id });
            fetchEvents();
        } catch (error) {
            console.error('Failed to cancel event:', error);
        }
    }
}

// Загрузка событий с бэкенда
async function fetchEvents() {
    status.value = 'loading';
    errorMessage.value = '';

    try {
        const startDate = format(subMonths(new Date(), 1), 'yyyy-MM-dd');
        const endDate = format(addMonths(new Date(), 1), 'yyyy-MM-dd');

        const response = await axios.get('/calendars/get_user_schedule/', {
            params: { start_date: startDate, end_date: endDate },
        });

        const data = response.data;

        const mentorEvents = (data.mentor_events || []).map(event => ({
            id: event.id,
            title: event.title,
            date: event.date,
            description: event.description,
            status: event.is_canceled ? 'cancelled' : (new Date(event.date) < new Date() ? 'done' : 'live'),
            conferenceStatus: event.conference_status,
            role: 'leading',
            sessionType: 'Event',
        }));

        const registeredEvents = (data.registered_events || []).map(event => ({
            id: event.id,
            title: event.title,
            date: event.date,
            description: event.description,
            status: event.is_canceled ? 'cancelled' : (new Date(event.date) < new Date() ? 'done' : 'live'),
            conferenceStatus: event.conference_status,
            role: 'participating',
            sessionType: 'Event',
        }));

        // const consultationSlots = (data.consultation_slots || []).map(slot => ({
        //     id: slot.id,
        //     title: slot.consultation_type?.title || 'Консультация',
        //     date: slot.start_time,
        //     description: slot.consultation_type?.description,
        //     status: slot.is_booked ? (new Date(slot.start_time) < new Date() ? 'done' : 'live') : 'pending',
        //     conferenceStatus: slot.conference_status,
        //     role: 'leading' as const,
        //     sessionType: 'Consultation',
        //     bookingId: slot.is_booked ? slot.booking_id : undefined,
        //     mentorName: slot.consultation_type?.user?.mentor_name,
        //     mentorSurname: slot.consultation_type?.user?.mentor_surname,
        //     mentorAvatar: slot.consultation_type?.user?.avatar,
        //     duration: slot.duration ? `${slot.duration} минут` : '60 минут',
        //     cost: slot.consultation_type?.cost ? `${slot.consultation_type.cost} руб` : '1500',
        // }));

        const bookedConsultationsByUser = (data.booked_by_user || []).map(booking => {
            const slot = booking.slot || {};
            const cType = slot.consultation_type || {};
            const mentorUser = cType.user || {};
            return {
                id: booking.id,
                title: cType.name || 'Консультация',
                date: slot.start_time,
                description: cType.description || '',
                status: new Date(slot.start_time) < new Date() ? 'done' : 'live',
                conferenceStatus: booking.conference_status,
                role: 'participating' as const,
                sessionType: 'Consultation',
                bookingId: booking.id,
                mentorName: mentorUser.mentor_name || 'Имя',
                mentorSurname: mentorUser.mentor_surname || 'Фамилия',
                mentorAvatar: mentorUser.mentor_avatar_url || '/placeholder.svg?height=56&width=56',
                duration: slot.duration ? `${slot.duration} минут` : '60 минут',
                cost: cType.cost ? `${cType.cost} руб` : '1500 руб',
                question: booking.question || '',
            };
        });

        const bookedConsultationsForMentor = (data.booked_for_mentor || []).map(booking => {
            const slot = booking.slot || {};
            const cType = slot.consultation_type || {};
            const mentorUser = cType.user || {};
            return {
                id: booking.id,
                title: cType.name || 'Консультация',
                date: slot.start_time,
                description: cType.description || '',
                status: new Date(slot.start_time) < new Date() ? 'done' : 'live',
                conferenceStatus: booking.conference_status,
                role: 'leading' as const,
                sessionType: 'Consultation',
                bookingId: booking.id,
                mentorName: mentorUser.mentor_name || 'Имя',
                mentorSurname: mentorUser.mentor_surname || 'Фамилия',
                mentorAvatar: mentorUser.mentor_avatar_url || '/placeholder.svg?height=56&width=56',
                duration: slot.duration ? `${slot.duration} минут` : '60 минут',
                cost: cType.cost ? `${cType.cost} руб` : '1500 руб',
                question: booking.question || '',
            };
        });

        events.value = [...mentorEvents, ...registeredEvents, ...bookedConsultationsByUser, ...bookedConsultationsForMentor];
        console.log(events)
        console.log(events.value)
        status.value = 'success';

        if (!hasEventOnDate(selectedDate.value) && eventDates.value.length > 0) {
            selectedDate.value = eventDates.value.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0];
        }
    } catch (err) {
        status.value = 'error';
        errorMessage.value = err.response?.data?.detail || 'Ошибка загрузки расписания';
        console.error('Fetch events error:', err);
    }
}

// Фильтрация событий для вкладок
function isPast(dateStr: string): boolean {
    return new Date(dateStr).getTime() < Date.now();
}

const leadingEvents = computed(() => {
    return events.value.filter(e => e.role === 'leading' && !isPast(e.date));
});

const participatingEvents = computed(() => {
    return events.value.filter(e => e.role === 'participating' && !isPast(e.date));
});

const historyEvents = computed(() => {
    let filtered = events.value.filter(e => isPast(e.date));
    if (selectedFilter.value === 1) {
        filtered = filtered.filter(e => e.role === 'leading');
    } else if (selectedFilter.value === 2) {
        filtered = filtered.filter(e => e.role === 'participating');
    } else if (selectedFilter.value === 3) {
        filtered = filtered.filter(e => e.status === 'cancelled');
    }
    return filtered;
});

// Жизненный цикл
onMounted(() => {
    fetchEvents();
});
</script>

<style scoped>
/* Стили остаются без изменений */
.date-circle {
    width: 48px;
    height: 48px;
    background-color: #E0DFFF;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.date-number {
    font-weight: 600;
    color: #3F2E85;
    font-size: 16px;
}

.date-weekday {
    font-size: 12px;
    color: #3F2E85;
}

:deep(.borderless-calendar) {
    border: none !important;
    box-shadow: none !important;
}

/* :deep(.borderless-calendar .v-date-picker-header) {
    padding: 0 12px;
}

:deep(.borderless-calendar .v-date-picker-month) {
    padding: 0 12px;
} */

.custom-day-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.custom-day {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.selected-day {
    background-color: #DCD8FC !important;
    color: #333333 !important;
}

.current-day {
    font-weight: bold;
}

.has-event {
    font-weight: 600;
}

.event-indicator {
    width: 4px;
    height: 4px;
    background-color: #7B65F7;
    border-radius: 50%;
    margin-top: 2px;
}

:deep(.v-tabs .v-tab.v-tab--selected) {
    color: #7B65F7 !important;
}

:deep(.v-tabs .v-tab.v-tab--selected .v-tab__slider) {
    background-color: #7B65F7 !important;
}
</style>