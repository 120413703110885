import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "auth-wrapper" }
const _hoisted_2 = {
  key: "login",
  class: "auth-content"
}
const _hoisted_3 = {
  key: "verification",
  class: "auth-content"
}
const _hoisted_4 = { class: "auth-description" }

import { ref, onMounted, onUnmounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  setup(__props) {

/* eslint-disable */
axios.defaults.withCredentials = true;

const store = useStore();
const router = useRouter();

const email = ref('');
const otpCode = ref('');
const loading = ref(false);
const showVerification = ref(false);
const resendTimer = ref(30);
let timerInterval = null;

const emailRules = [
    (v: string) => !!v || 'Email обязателен',
    (v: string) => /.+@.+\..+/.test(v) || 'Неверный формат email',
];

const handleRequestPasscode = async () => {
    loading.value = true;
    try {
        await axios.post('/auth/request_login_passcode/', { email: email.value });
        showVerification.value = true;
        startResendTimer();
    } catch (error) {
        console.error('Ошибка при запросе кода:', error);
        alert('Пользователь не найден или произошла ошибка');
    } finally {
        loading.value = false;
    }
};

const handleVerifyPasscode = async (code: string) => {
    loading.value = true;
    try {
        await store.dispatch('login', { email: email.value, passcode: code });
        router.push('/AllMentors');
    } catch (error) {
        console.error('Ошибка при входе:', error);
        alert('Неверный код или ошибка сервера');
    } finally {
        loading.value = false;
    }
};

const startResendTimer = () => {
    resendTimer.value = 30;
    if (timerInterval) clearInterval(timerInterval);
    timerInterval = setInterval(() => {
        if (resendTimer.value > 0) resendTimer.value--;
        else clearInterval(timerInterval);
    }, 1000);
};

const resendCode = async () => {
    await handleRequestPasscode();
};

onUnmounted(() => {
    if (timerInterval) clearInterval(timerInterval);
});

return (_ctx: any,_cache: any) => {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_otp_input = _resolveComponent("v-otp-input")!
  const _component_v_fade_transition = _resolveComponent("v-fade-transition")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, {
      class: "auth-card",
      elevation: "0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_fade_transition, { mode: "out-in" }, {
          default: _withCtx(() => [
            (!showVerification.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "auth-title" }, "Вход в аккаунт", -1)),
                  _cache[5] || (_cache[5] = _createElementVNode("p", { class: "auth-description" }, "Введите email, чтобы получить код для входа.", -1)),
                  _createVNode(_component_v_form, {
                    onSubmit: _withModifiers(handleRequestPasscode, ["prevent"]),
                    ref: "loginForm"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: email.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
                        rules: emailRules,
                        label: "Email",
                        "prepend-inner-icon": "mdi-email",
                        variant: "outlined",
                        class: "mb-6",
                        "hide-details": "auto",
                        placeholder: "sergey@mail.ru"
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_v_btn, {
                        block: "",
                        color: "#7b65f7",
                        size: "large",
                        type: "submit",
                        class: "white--text mb-8",
                        loading: loading.value,
                        height: "56"
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode(" Получить код ")
                        ])),
                        _: 1
                      }, 8, ["loading"])
                    ]),
                    _: 1
                  }, 512),
                  _cache[6] || (_cache[6] = _createElementVNode("div", { class: "register-link" }, [
                    _createElementVNode("span", null, "Нет аккаунта? "),
                    _createElementVNode("a", {
                      href: "/register",
                      class: "register-link-text"
                    }, "Зарегистрироваться")
                  ], -1))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "auth-title" }, "Введите код", -1)),
                  _createElementVNode("p", _hoisted_4, "Мы отправили код на " + _toDisplayString(email.value), 1),
                  _createVNode(_component_v_otp_input, {
                    modelValue: otpCode.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((otpCode).value = $event)),
                    length: 4,
                    variant: "underlined",
                    onFinish: handleVerifyPasscode
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_v_btn, {
                    block: "",
                    variant: "text",
                    disabled: resendTimer.value > 0,
                    class: "mb-4",
                    onClick: resendCode
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Отправить код повторно через " + _toDisplayString(resendTimer.value) + " сек. ", 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"]),
                  _createVNode(_component_v_btn, {
                    block: "",
                    variant: "text",
                    color: "#7b65f7",
                    class: "mb-8",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (showVerification.value = false))
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" Войти с другим email ")
                    ])),
                    _: 1
                  })
                ]))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})