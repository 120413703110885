import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-center align-center py-8"
}
const _hoisted_2 = {
  key: 3,
  class: "event-list"
}


import EventCard from '@/components/pages/calendar/EventCard.vue';

interface Event {
    id: number;
    title: string;
    date: string;
    description?: string;
    status?: string;
    role?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TemplateBlock',
  props: {
    status: {},
    errorMessage: {},
    filteredEvents: {},
    emptyText: {},
    mode: {}
  },
  emits: ["fetchAgain", "openEvent", "joinEvent", "editEvent", "cancelEvent", "leaveEvent"],
  setup(__props: any) {

/* eslint-disable*/
const props = __props;



return (_ctx: any,_cache: any) => {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_ctx.status === 'loading')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_progress_circular, {
          color: "primary",
          indeterminate: "",
          size: "40"
        })
      ]))
    : (_ctx.status === 'error')
      ? (_openBlock(), _createBlock(_component_v_alert, {
          key: 1,
          type: "error",
          variant: "tonal",
          border: "start",
          class: "mb-4",
          closable: ""
        }, {
          append: _withCtx(() => [
            _createVNode(_component_v_btn, {
              variant: "text",
              color: "primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('fetchAgain'))),
              density: "comfortable"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Повторить ")
              ])),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.errorMessage) + " ", 1)
          ]),
          _: 1
        }))
      : (_ctx.filteredEvents.length === 0)
        ? (_openBlock(), _createBlock(_component_v_sheet, {
            key: 2,
            class: "text-body-1 text-medium-emphasis py-4 bg-transparent"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.emptyText), 1)
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredEvents, (event) => {
              return (_openBlock(), _createElementBlock("div", {
                key: event.id,
                class: "mb-4"
              }, [
                _createVNode(EventCard, {
                  event: event,
                  mode: _ctx.mode,
                  onOpenEvent: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('openEvent', $event))),
                  onEditEvent: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('editEvent', $event))),
                  onCancelEvent: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('cancelEvent', $event))),
                  onLeaveEvent: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('leaveEvent', $event))),
                  onJoinEvent: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('joinEvent', $event)))
                }, null, 8, ["event", "mode"])
              ]))
            }), 128))
          ]))
}
}

})