import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "Review" }
const _hoisted_2 = { class: "ReviewItem" }
const _hoisted_3 = { class: "ReviewText" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "ReviewText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spacer16 = _resolveComponent("Spacer16")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "ReviewAuthor" }, [
          _createElementVNode("img", {
            src: "https://storage.yandexcloud.net/testbucketplatforma34/photo_woman_beauty.webp",
            alt: "Mentor Image",
            class: "ReviewPFP"
          }),
          _createElementVNode("div", { class: "ReviewName" }, " Ольга Захарова ")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.truncatedReviewText) + " ", 1),
          (_ctx.reviewText.length > 140)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args))),
                class: "modal-btn"
              }, " Показать больше "))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_Spacer16)
      ])
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_Transition, { name: "modal" }, {
        default: _withCtx(() => [
          (_ctx.isModalOpen)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "modal-overlay",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
              }, [
                _createElementVNode("div", {
                  class: "modal-content",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
                }, [
                  _createElementVNode("button", {
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
                    class: "close-btn"
                  }, "×"),
                  _createElementVNode("div", _hoisted_4, [
                    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "ReviewAuthor" }, [
                      _createElementVNode("img", {
                        src: "https://storage.yandexcloud.net/testbucketplatforma34/photo_woman_beauty.webp",
                        alt: "Mentor Image",
                        class: "ReviewPFP"
                      }),
                      _createElementVNode("div", { class: "ReviewName" }, " Ольга Захарова ")
                    ], -1)),
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.reviewText), 1)
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]))
  ], 64))
}