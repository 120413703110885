import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "profile-menu" }
const _hoisted_2 = { class: "profile-header" }
const _hoisted_3 = { class: "profile-info" }
const _hoisted_4 = { class: "profile-name" }
const _hoisted_5 = { class: "menu-list" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "text" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("img", {
        src: "https://storage.yandexcloud.net/testbucketplatforma34/woman_blue_background.jpeg",
        alt: "Profile photo",
        class: "profile-image"
      }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString($setup.userName), 1),
        _createElementVNode("a", {
          href: "#",
          class: "profile-link",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => ($setup.goToProfile && $setup.goToProfile(...args)), ["prevent"]))
        }, "Показать профиль")
      ])
    ]),
    _createElementVNode("nav", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredNavItems, (item, index) => {
        return (_openBlock(), _createElementBlock("a", {
          key: item.id,
          class: _normalizeClass(['menu-item', { 'mentor-button': index === 0, active: $setup.activeNavItem === item.id }]),
          onClick: ($event: any) => ($setup.handleNavItemClick(item.id))
        }, [
          _createElementVNode("span", {
            class: "icon",
            innerHTML: $setup.iconItems[item.id]
          }, null, 8, _hoisted_7),
          _createElementVNode("span", _hoisted_8, _toDisplayString(item.text), 1),
          _createElementVNode("span", {
            class: "chevron",
            innerHTML: $setup.iconItems['chevron']
          }, null, 8, _hoisted_9),
          ($setup.filteredNavItems.length === 2)
            ? (_openBlock(), _createElementBlock("hr", _hoisted_10))
            : _createCommentVNode("", true)
        ], 10, _hoisted_6))
      }), 128))
    ])
  ]))
}