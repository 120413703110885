import { createApp } from 'vue';

// --- Vuetify ---
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import '@mdi/font/css/materialdesignicons.css';
import PrimeVue from 'primevue/config';
// import '@fullcalendar/daygrid/main.css'
// import '@fullcalendar/timegrid/main.css'
// import '@fullcalendar/core/locales/ru'
// import { createDateAdapter } from 'vuetify/lib/composables/date/adapters/date-fns';  
// import { ru } from 'date-fns/locale';

// --- Tailwind и прочие стили (если нужны) ---
// import './assets/tailwind.css';

import App from './App.vue';
import router from './router';
import store from '../store';

import axios from 'axios';
import VCalendar from 'v-calendar';
import DayJsAdapter from '@date-io/dayjs'
import 'v-calendar/style.css';

// Создаём Vuetify-инстанс
const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                colors: {
                    primary: '#6750A4',
                    secondary: '#625B71',
                    accent: '#7D5260',
                },
            },
        },
    },
    date: {
        adapter: DayJsAdapter,
      },
});

// --------------------------------------------------
// 1) Глобальная настройка axios
// --------------------------------------------------
axios.defaults.withCredentials = true; // Отправляем http-only cookies
// axios.defaults.baseURL = 'https://platforma34.tw1.ru/api/'; // Базовый URL для API
axios.defaults.baseURL = 'https://logora.ru/api/'; // Базовый URL для API

// Перехватчик для отладки запросов
axios.interceptors.request.use(
    (config) => {
        console.log('Request Config:', config);
        return config;
    },
    (error) => Promise.reject(error)
);

// // Перехватчик для обработки 401 + обновления токена
// axios.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         const originalRequest = error.config;
//         if (error.response?.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true;
//             try {
//                 // Пытаемся рефрешнуть токен
//                 await axios.post('/token/refresh/');
//                 // Повторяем оригинальный запрос
//                 return axios(originalRequest);
//             } catch (refreshError) {
//                 // Если рефреш не сработал — разлогиниваем и уводим на /login
//                 store.dispatch('logout');
//                 router.push('/login');
//                 return Promise.reject(refreshError);
//             }
//         }
//         return Promise.reject(error);
//     }
// );

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refreshResponse = await axios.post('/token/refresh/', {}, { withCredentials: true });
                console.log('Токен успешно обновлен:', refreshResponse.data);
                return axios(originalRequest);
            } catch (refreshError) {
                console.error('Ошибка обновления токена:', refreshError);
                router.push('/login');  // Просто перенаправляем на логин
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

// axios.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         const originalRequest = error.config;
//         if (error.response?.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true;
//             try {
//                 // Пытаемся обновить токен
//                 const refreshResponse = await axios.post('/token/refresh/', {}, { withCredentials: true });
//                 console.log('Токен успешно обновлен:', refreshResponse.data);
//                 // Повторяем оригинальный запрос с новым токеном (cookies обновятся автоматически)
//                 return axios(originalRequest);
//             } catch (refreshError) {
//                 console.error('Ошибка обновления токена:', refreshError);
//                 // Если обновление не удалось, разлогиниваем и перенаправляем
//                 await store.dispatch('logout');
//                 router.push('/login');
//                 return Promise.reject(refreshError);
//             }
//         }
//         return Promise.reject(error);
//     }
// );

// axios.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         const originalRequest = error.config;
//         // Обрабатываем 401 только если запрос требует аутентификации и это не повторная попытка
//         if (
//             error.response?.status === 401 &&
//             !originalRequest._retry &&
//             originalRequest.requiresAuth
//         ) {
//             originalRequest._retry = true;
//             try {
//                 // Пытаемся обновить токен
//                 await axios.post('/token/refresh/');
//                 // Повторяем оригинальный запрос
//                 return axios(originalRequest);
//             } catch (refreshError) {
//                 // Если обновление токена не удалось, разлогиниваем и перенаправляем
//                 store.dispatch('logout');
//                 router.push('/login');
//                 return Promise.reject(refreshError);
//             }
//         }
//         // Для всех остальных случаев возвращаем ошибку как есть
//         return Promise.reject(error);
//     }
// );


// --------------------------------------------------
// 2) Асинхронная инициализация приложения
// --------------------------------------------------
async function initializeApp() {
    try {
        // Попробуем узнать, авторизован ли пользователь (endpoпint /users/me/)
        await store.dispatch('fetchUser');
    } catch (error) {
        console.warn('Не удалось получить пользователя при старте:', error);
    }

    // Создаём Vue-приложение
    const app = createApp(App);

    // Подключаем Vuetify, Router, Store, VCalendar
    app.use(vuetify);
    app.use(router);
    app.use(store);
    app.use(PrimeVue);
    app.use(VCalendar, {});

    // Монтируем приложение только после fetchUser
    app.mount('#app');
}

// Запускаем всё
initializeApp();
