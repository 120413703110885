<template>
    <header class="header">
        <a href="/" class="logo">ФОРМА</a>

        <div class="toggle-container">
            <div class="toggle-wrapper">
                <div class="toggle-slider" :style="{ transform: `translateX(${currentPath === '/' ? '100%' : '0'})` }">
                </div>
                <router-link to="/LandingMenteePage" class="toggle-button"
                    :class="{ active: currentPath === '/LandingMenteePage' }">
                    Хочу учиться
                </router-link>
                <router-link to="/" class="toggle-button" :class="{ active: currentPath === '/' }">
                    Хочу учить
                </router-link>
            </div>
        </div>

        <!-- <button @click="navigateToPage('/ProfileSettings')" class="login-button">Войти</button> -->
        <div>
            <NotificationComponent :avatarUrl="avatarUrl"
                @login-click="navigateToPage('/login')" />
        </div>
    </header>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import NotificationComponent from './NotificationComponent.vue'; // Убедись, что путь к файлу правильный

const route = useRoute()
const router = useRouter()
const currentPath = ref(route.path)
const avatarUrl = ref("https://storage.yandexcloud.net/testbucketplatforma34/woman_blue_background.jpeg")

// Watch for route changes
watch(
    () => route.path,
    (newPath) => {
        currentPath.value = newPath
    }
)

const navigateToPage = (route) => {
    router.push(route);
}

// Set initial path on component mount
onMounted(() => {
    currentPath.value = route.path
})
</script>

<style scoped>
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    /* background-color: #ffffff; */
    background: var(--neutral-neutral100, #F3F4F5);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.logo {
    color: #8162e9;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
}

.toggle-container {
    position: relative;
}

.toggle-wrapper {
    position: relative;
    display: flex;
    /* background-color: #f3f4f5; */
    background-color: white; 

    border-radius: 100px;
    padding: 4px;
    cursor: pointer;
    width: 280px;
}

.toggle-slider {
    position: absolute;
    width: 49%;
    height: calc(100% - 8px);
    background: var(--accent-accent100, #DCD8FC);
    border-radius: 100px;
    transition: transform 0.3s ease;
    
}

.toggle-button {
    position: relative;
    z-index: 1;
    width: 50%;
    padding: 8px 16px;
    border: none;
    background: none;
    color: var(--accent-accent800, #5747AF);

    /*color: #454152;*/
    font-size: 0.9rem;
    cursor: pointer;
    transition: color 0.3s ease;
    text-decoration: none;
    text-align: center;
}

.toggle-button.active {
    color: var(--accent-accent600, #705CE2);
}

.login-button {
    padding: 8px 24px;
    border: none;
    background: none;
    color: #454152;
    font-size: 0.9rem;
    cursor: pointer;
    transition: color 0.2s ease;
}

.login-button:hover {
    color: #8162e9;
}
</style>