<template>
    <v-card class="mb-4 rounded-lg" :color="isHidden ? 'rgba(123, 101, 247, 0.15)' : '#8162e9'">
        <v-card-text>
            <div class="d-flex justify-space-between align-top">
                <div>
                    <div style="font-weight: 600;font-size: 32px;line-height: 38px;letter-spacing: 0%; color: rgb(255, 255, 255, 0.7);">{{
                        consultationType.name }}</div>
                    <div style="font-weight: 600;font-size: 24px;line-height: 28px;letter-spacing: 0%; color: white; margin-top: 1rem;">{{
                        consultationType.duration_minutes }} мин | {{ consultationType.costs }} руб.</div>
                    <!-- <div style="font-weight: 600;font-size: 24px;line-height: 28px;letter-spacing: 0%; color: white;">{{ consultationType.costs }} руб.</div> -->
                </div>

                <div style="display: inline-flex">
                    <!-- <v-btn icon variant="text" @click="toggleVisibility">
                        <v-icon>mdi-eye{{ isHidden ? '-off' : '' }}</v-icon>
                    </v-btn> -->
                    <v-btn icon variant="text" @click="showEditDialog = true">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon variant="text" @click="confirmDelete">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </div>

            <!-- Информация о выбранном слоте -->
            <v-expand-transition>
                <!-- <div v-if="selectedSlot" class="mt-4 pa-3 bg-blue-lighten-5 rounded"> -->
                <div v-if="selectedSlot" class="mt-4 pa-3 bg-white rounded-lg">
                    <h4 class="text-subtitle-1 mb-2">Информация о выбранном времени:</h4>
                    <p><strong>Время:</strong> {{ formatTime(selectedSlot.start_time) }} - {{
                        formatTime(selectedSlot.end_time) }}</p>
                    <p><strong>Повторение:</strong> {{ getRepeatInfo(selectedSlot) }}</p>
                    <p v-if="selectedSlot.repeat_until"><strong>Повторяется до:</strong> {{
                        formatDate(selectedSlot.repeat_until) }}</p>
                    <!-- <div class="d-flex justify-space-between align-center mt-2"> -->
                    <!-- <v-btn color="primary" variant="text" @click="editSelectedSlot">Редактировать</v-btn> -->
                    <!-- <v-btn color="error" variant="text" @click="deleteSelectedSlot">Удалить</v-btn> -->
                    <!-- </div> -->
                </div>
            </v-expand-transition>

            <!-- Time slots -->
            <div class="my-3">
                <h3
                    style="font-weight: 400;font-size: 20px;line-height: 28px;letter-spacing: 0%; color: white; padding-bottom: 0.5rem;">
                    Расписание:</h3>
                <div v-if="timeSlots.length === 0" class="text-body-2" style="color: rgba(255, 255, 255, 0.5)">
                    Нет запланированных сессий
                </div>
                <div v-else class="d-flex flex-wrap gap-2">
                    <time-slot v-for="slot in timeSlots" :key="slot.id" :start-time="formatTime(slot.start_time)"
                        :end-time="formatTime(slot.end_time)" :repeat-type="slot.repeat_type"
                        :custom-weekdays="slot.custom_weekdays"
                        :is-selected="selectedSlot && selectedSlot.id === slot.id" @click="selectSlot(slot)"
                        @delete="deleteTimeSlot(slot.id)" />
                </div>
            </div>

            <!-- Visibility banner for hidden consultations -->
            <v-banner v-if="isHidden" class="mt-3" color="grey-lighten-3">
                <div class="d-flex align-center justify-space-between">
                    <div class="d-flex align-center">
                        <v-icon class="me-2">mdi-eye-off</v-icon>
                        <span>Скрыто</span>
                    </div>
                    <v-btn color="primary" variant="text" @click="makeVisibleToAll">
                        Сделать видимым для всех
                    </v-btn>
                </div>
            </v-banner>
        </v-card-text>

        <!-- Dialog for editing consultation type -->
        <edit-consultation-type-dialog v-model="showEditDialog" :consultation-type="consultationType" :slots="timeSlots"
            @update="updateConsultationType" />
            
        <!-- Диалог подтверждения удаления -->
        <v-dialog v-model="deleteDialog" max-width="400px">
            <v-card>
                <v-card-title class="text-h5">Подтверждение удаления</v-card-title>
                <v-card-text>
                    Вы действительно хотите удалить тип консультации "{{ consultationType.name }}"?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey-darken-1" variant="text" @click="deleteDialog = false">Отмена</v-btn>
                    <v-btn color="error" variant="text" @click="deleteConsultationTypeConfirmed">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, defineEmits, defineProps } from 'vue';
import TimeSlot from './TimeSlot.vue';
import EditConsultationTypeDialog from './EditConsultationTypeDialog.vue';
import {
    deleteConsultationTypeApi,
    deleteSlotApi,
    deleteSeriesApi
} from '@/services/api';

const props = defineProps({
    consultationType: {
        type: Object,
        required: true
    },
    slots: {
        type: Array,
        default: () => []
    }
});

const emit = defineEmits(['update']);

// State
const isHidden = ref(false);
const showEditDialog = ref(false);
const selectedSlot = ref(null);
const deleteDialog = ref(false); // Добавляем состояние для диалога подтверждения

// Computed
const timeSlots = computed(() => props.slots);

// Methods
const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
};
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('ru-RU', options);
};

const toggleVisibility = () => {
    isHidden.value = !isHidden.value;
    // API call to update visibility would go here
};

const makeVisibleToAll = () => {
    isHidden.value = false;
    // API call to update visibility would go here
};

// Обновляем метод удаления, чтобы показать диалог подтверждения
const confirmDelete = () => {
    deleteDialog.value = true;
};

const deleteConsultationTypeConfirmed = async () => {
    try {
        await deleteConsultationTypeApi(props.consultationType.id, props.consultationType.user);
        emit('update');
        deleteDialog.value = false;
    } catch (error) {
        console.error('Error deleting consultation type:', error);
    }
};

const selectSlot = (slot) => {
    selectedSlot.value = selectedSlot.value && selectedSlot.value.id === slot.id ? null : slot;
};

const deleteTimeSlot = async (slotId) => {
    if (confirm('Вы уверены, что хотите удалить это время?')) {
        try {
            const slot = props.slots.find(s => s.id === slotId);
            if (!slot) {
                throw new Error('Слот не найден');
            }

            const userId = props.consultationType.user?.id || props.consultationType.user;
            if (slot.repeat_type === 'none') {
                await deleteSlotApi(slotId, userId);
            } else {
                await deleteSeriesApi(slotId, userId);
            }

            if (selectedSlot.value && selectedSlot.value.id === slotId) {
                selectedSlot.value = null;
            }
            emit('update');
        } catch (error) {
            console.error('Error deleting time slot:', error);
            alert('Ошибка при удалении слота: ' + error.message);
        }
    }
};

const updateConsultationType = () => {
    emit('update');
};

const getRepeatInfo = (slot) => {
    switch (slot.repeat_type) {
        case 'daily':
            return 'Каждый день';
        case 'weekly':
            return 'Каждую неделю';
        case 'custom':
            return slot.custom_weekdays?.length ? `По дням: ${formatWeekdays(slot.custom_weekdays)}` : 'По выбранным дням';

        default:
            return 'Без повторений';
    }
};

const formatWeekdays = (weekdays) => {
    if (!weekdays || !weekdays.length) return ''; // Проверка на пустой или неопределённый массив

    const dayMap = {
        'mon': 'Пн',
        'tue': 'Вт',
        'wed': 'Ср',
        'thu': 'Чт',
        'fri': 'Пт',
        'sat': 'Сб',
        'sun': 'Вс'
    };

    // Определяем порядок дней недели
    const dayOrder = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

    // Преобразуем входные данные в массив, если это строка
    const days = Array.isArray(weekdays) ? weekdays : weekdays.split(',');

    // Сортируем дни по заданному порядку
    const sortedDays = days.sort((a, b) => {
        return dayOrder.indexOf(a.toLowerCase()) - dayOrder.indexOf(b.toLowerCase());
    });

    // Преобразуем отсортированные дни в строку
    return sortedDays.map(day => dayMap[day.toLowerCase()] || day).join(', ');
};


const editSelectedSlot = () => {
    showEditDialog.value = true;
};

const deleteSelectedSlot = async () => {
    if (selectedSlot.value) {
        await deleteTimeSlot(selectedSlot.value.id);
    }
};
</script>

<style scoped>
/* Стили остаются без изменений */
</style>