import axios from 'axios';

// ======================
// Базовая настройка API
// ======================

const API_URL = 'https://logora.ru/api';

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 15000, // 15 seconds timeout
});

// Interceptor для обработки ошибок
api.interceptors.response.use(
    response => response,
    error => {
        // Логирование ошибок
        const errorMessage = error.response?.data?.message || error.message;
        console.error(`API Error: ${errorMessage}`);

        return Promise.reject(error);
    }
);

// Вспомогательные функции
const validateId = (id) => {
    if (!id && id !== 0) {
        throw new Error('ID is required');
    }
    return id;
};

const validateUserId = (userId) => {
    if (!userId && userId !== 0) {
        throw new Error('User ID is required');
    }
    return userId;
};

const handleApiError = (error, customMessage) => {
    const errorMessage = customMessage || 'API request failed';
    console.error(`${errorMessage}:`, error.response?.data || error.message);
    throw new Error(errorMessage);
};

// ===============================
// 📌 EVENT REGISTRATIONS
// ===============================

export const getEventRegistrationsApi = async (eventId) => {
    try {
        validateId(eventId);
        const response = await api.get('/events/get_event_registrations/', {
            params: { event_id: eventId }
        });
        return response.data;
    } catch (error) {
        handleApiError(error, `Failed to fetch registrations for event with ID: ${eventId}`);
    }
};

export const checkRegistrationApi = async (eventId, userId) => {
    try {
        validateId(eventId);
        validateUserId(userId);
        const response = await api.get('/events/check_registration/', {
            params: { event_id: eventId, user_id: userId }
        });
        return response.data.is_registered;
    } catch (error) {
        handleApiError(error, `Failed to check registration for event: ${eventId}, user: ${userId}`);
        return false;
    }
};

export const registerForEventApi = async (eventId, userId) => {
    try {
        validateId(eventId);
        validateUserId(userId);
        const response = await api.post('/events/register_for_event/', {
            event_id: eventId,
            user_id: userId
        });
        return response.data;
    } catch (error) {
        handleApiError(error, `Failed to register for event: ${eventId}`);
    }
};

export const unregisterFromEventApi = async (eventId, userId) => {
    try {
        validateId(eventId);
        validateUserId(userId);
        const response = await api.post('/events/unregister_from_event/', {
            event_id: eventId,
            user_id: userId
        });
        return response.data;
    } catch (error) {
        handleApiError(error, `Failed to unregister from event: ${eventId}`);
    }
};

// ===============================
// 📌 EVENTS (Мероприятия)
// ===============================

export const getEventsApi = async () => {
    try {
        const res = await api.get('/events/GetEvents/');
        return res.data;
    } catch (error) {
        handleApiError(error, 'Failed to fetch events');
    }
};

export const getUserEventsAPI = async (userId) => {
    try {
        validateUserId(userId);
        const res = await api.get('/events/GetUserEvents/', {
            params: { user_id: userId }
        });
        return res.data;
    } catch (error) {
        handleApiError(error, 'Failed to fetch user events');
    }
};

export const getEventByIdApi = async (id) => {
    try {
        validateId(id);
        const res = await api.get('/events/GetEvent/', {
            params: { id }
        });
        return res.data;
    } catch (error) {
        handleApiError(error, `Failed to fetch event with ID: ${id}`);
    }
};

export const getTopEventsApi = async () => {
    try {
        const res = await api.get('/events/GetTopEvents');
        return res.data;
    } catch (error) {
        handleApiError(error, 'Failed to fetch top events');
    }
};

export const loadMoreEventsApi = async (offset = 0, limit = 10) => {
    try {
        if (offset < 0) offset = 0;
        if (limit <= 0) limit = 10;

        const res = await api.get('/events/load_more/', {
            params: { offset, limit }
        });
        return res.data;
    } catch (error) {
        handleApiError(error, 'Failed to load more events');
    }
};

export const getPaginatedEventsApi = async (page = 1) => {
    try {
        const res = await api.get(`/events/paginated_events/`, {
            params: { page }
        });
        return res.data;
    } catch (error) {
        handleApiError(error, `Failed to fetch paginated events for page ${page}`);
    }
};

export const createEventApi = async (eventData, imageFile = null) => {
    try {
        if (!eventData) {
            throw new Error('Event data is required');
        }

        const formData = new FormData();

        // Validate required fields
        const requiredFields = ['title', 'date', 'mentor_id'];
        for (const field of requiredFields) {
            if (!eventData[field]) {
                throw new Error(`${field} is required`);
            }
        }

        // Add all event data to formData
        Object.entries(eventData).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                formData.append(key, value);
            }
        });

        // Add image file if provided
        if (imageFile) {
            formData.append('event_image', imageFile);
            console.log('Adding image file to form data:', imageFile.name, imageFile.size);
        }

        console.log('Sending form data to API:', Object.fromEntries(formData.entries()));

        const res = await api.post('/events/AddEvent/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return res.data;
    } catch (error) {
        console.error('Create event error details:', error.response?.data);
        handleApiError(error, 'Failed to create event');
    }
};

export const updateEventApi = async (id, eventData, imageFile = null) => {
    try {
        validateId(id);

        if (!eventData) {
            throw new Error('Event data is required');
        }

        // Если есть файл изображения или флаг удаления, используем FormData
        if (imageFile || eventData.remove_image) {
            const formData = new FormData();
            
            // Добавляем все поля в FormData
            Object.entries(eventData).forEach(([key, value]) => {
                if (value !== undefined && value !== null) {
                    formData.append(key, value);
                }
            });
            
            // Добавляем файл изображения, если он есть
            if (imageFile) {
                formData.append('event_image', imageFile);
                console.log('Adding image file to form data for update:', imageFile.name, imageFile.size);
            }
            
            

            console.log('Sending form data to API for update:', Object.fromEntries(formData.entries()));
            
            const res = await api.put(`/events/UpdateEvent/`, formData, {
                params: { id },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return res.data;
        } else {
            // Обычный JSON запрос, если нет изменений с изображением
            const res = await api.put(`/events/UpdateEvent/`, eventData, {
                params: { id },
            });
            return res.data;
        }
    } catch (error) {
        console.error('Update event error details:', error.response?.data);
        handleApiError(error, `Failed to update event with ID: ${id}`);
    }
};

// export const createEventApi = async (eventData, imageFile = null) => {
//     try {
//         if (!eventData) {
//             throw new Error('Event data is required');
//         }

//         const formData = new FormData();

//         // Validate required fields
//         const requiredFields = ['title', 'date'];
//         for (const field of requiredFields) {
//             if (!eventData[field]) {
//                 throw new Error(`${field} is required`);
//             }
//         }

//         Object.entries(eventData).forEach(([key, value]) => {
//             if (value !== undefined && value !== null) {
//                 formData.append(key, value);
//             }
//         });

//         if (imageFile) {
//             formData.append('event_image', imageFile);
//         }

//         const res = await api.post('/events/AddEvent/', formData, {
//             headers: {
//                 'Content-Type': 'multipart/form-data',
//             },
//         });

//         return res.data;
//     } catch (error) {
//         handleApiError(error, 'Failed to create event');
//     }
// };

// export const updateEventApi = async (id, eventData) => {
//     try {
//         validateId(id);

//         if (!eventData) {
//             throw new Error('Event data is required');
//         }

//         const res = await api.put(`/events/UpdateEvent`, eventData, {
//             params: { id },
//         });
//         return res.data;
//     } catch (error) {
//         handleApiError(error, `Failed to update event with ID: ${id}`);
//     }
// };

export const deleteEventApi = async (id) => {
    try {
        validateId(id);

        const res = await api.delete('/events/DeleteEvent/', {
            params: { id }
        });
        return res.data;
    } catch (error) {
        handleApiError(error, `Failed to delete event with ID: ${id}`);
    }
};

export const fetchConsultationTypesApi = async (userId) => {
    try {
        const response = await api.get('/consultation-types/all/', {
            params: { user_id: userId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching consultation types:', error.response?.data || error.message);
        throw new Error('Failed to fetch consultation types');
    }
};

export const createConsultationTypeApi = async (data) => {
    console.log(data)
    try {
        const response = await api.post('/consultation-types/create/', data);
        return response.data;
    } catch (error) {
        console.error('Error creating consultation type:', error.response?.data || error.message);
        throw new Error('Failed to create consultation type');
    }
};

export const updateConsultationTypeApi = async (data) => {
    
    try {
        const userId = typeof data.user === 'object' && data.user.id ? data.user.id : data.user;
        const response = await api.patch(`/consultation-types/${data.id}/update/`, {
            ...data,
            user_id: userId
        });
        return response.data;
    } catch (error) {
        console.error('Error updating consultation type:', error.response?.data || error.message);
        throw new Error('Failed to update consultation type');
    }
};

export const deleteConsultationTypeApi = async (id, userId) => {
    try {
        await api.delete(`/consultation-types/${id}/delete/`, {
            params: { user_id: userId }
        });
        return true;
    } catch (error) {
        console.error('Error deleting consultation type:', error.response?.data || error.message);
        throw new Error('Failed to delete consultation type');
    }
};

// ===============================
// 📌 CONSULTATION SLOTS
// ===============================

export const fetchSlotsApi = async (userId) => {
    const response = await api.get('/consultation-type-slots/all/', {
        params: { user_id: userId }
    });
    return response.data;
};

export const createSlotApi = async (data) => {
    const response = await api.post('/consultation-type-slots/create/', data);
    return response.data;
};

export const deleteSlotApi = async (id, userId) => {
    await api.delete(`/consultation-type-slots/${id}/delete/`, {
        params: { user_id: userId }
    });
    return true;
};

export const deleteSeriesApi = async (slotId, userId) => {
    const response = await api.delete(`/consultation-type-slots/${slotId}/delete_series/`, {
        params: { user_id: userId }
    });
    return response.data;
};

export const updateSlotApi = async (slotId, data) => {
    const response = await api.patch(`/consultation-type-slots/${slotId}/update/`, data);
    return response.data;
};

export const createRecurrentSlotsApi = async (data) => {
    const response = await api.post('/consultation-type-slots/create_recurrent_slots/', data);
    return response.data;
};

// ===============================
// 📌 BOOKINGS
// ===============================

export const fetchBookingsApi = async (userId) => {
    const response = await api.get('/consultation-bookings/all', {
        params: { user_id: userId }
    });
    return response.data;
};


// Экспортируем остальные функции API...
// (Остальные функции для консультаций, слотов и бронирований остаются без изменений)

export default api;