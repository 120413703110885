<template>
    <div class="error-container">
        <div class="error-content">
            <h1 class="error-code">401</h1>
            <h2 class="error-title">Неавторизован</h2>
            <p class="error-message">
                Для доступа к этой странице требуется авторизация.
            </p>
            <router-link to="/login" class="error-button">Авторизоваться</router-link>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'UnauthorizedError',
});
</script>

<style scoped>
.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
}

.error-content {
    text-align: center;
    max-width: 500px;
    width: 100%;
}

.error-code {
    font-weight: 700;
    font-size: 200px;
    line-height: 240px;
    letter-spacing: 0%;
    color: #2980B9;
}

.error-title {
    font-weight: 600;
    font-size: 40px;
    text-align: center;
    color: #2980B9;
    margin-top: 20px;
}

.error-message {
    font-weight: 400;
    font-size: 20px;
    color: #525A7D;
    margin-top: 10px;
}

.error-button {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    display: inline-block;
    padding: 12px 20px;
    margin-top: 20px;
    background-color: #2980B9;
    color: white;
    text-decoration: none;
    border-radius: 12px;
    transition: background-color 0.3s;
}

.error-button:hover {
    background-color: #1C6B8B;
}
</style>
