<template>
    <div>
        <transition name="fade" @before-leave="beforeLeave" @leave="leave">
            <div v-if="loading" class="loading">
                <JumpingDotLoaderWithShadows />
            </div>
        </transition>
        <div v-if="loading == false" id="Mentor" style="display: flex; justify-content: center;">
            <!-- <BackButton />
            <Spacer32 /> -->
            <div class="mentor-content">
                <div class="MentorHero" ref="mentorHeroRef">
                    <div class="MentorHeroWrapper" :style="{ width: mentorHeroWrapperWidth }">
                        <div class="MentorAvatar">
                            <div class="profile-container">
                                <div class="profile-photo">
                                    <img :src="mentor.mentor_avatar_url" :alt="alt" @error="handleImageError">
                                </div>
                            </div>
                        </div>
                        <div class="MentorInfo">
                            <div class="InfoWrapper">
                                <div class="verified-badge">
                                    <span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M9.9999 19.6004C15.3018 19.6004 19.5999 15.3023 19.5999 10.0004C19.5999 4.69846 15.3018 0.400391 9.9999 0.400391C4.69797 0.400391 0.399902 4.69846 0.399902 10.0004C0.399902 15.3023 4.69797 19.6004 9.9999 19.6004ZM14.4484 8.44892C14.9171 7.98029 14.9171 7.22049 14.4484 6.75186C13.9798 6.28323 13.22 6.28323 12.7514 6.75186L8.7999 10.7033L7.24843 9.15186C6.7798 8.68323 6.02 8.68323 5.55137 9.15186C5.08275 9.62049 5.08275 10.3803 5.55137 10.8489L7.95137 13.2489C8.42 13.7175 9.1798 13.7175 9.64843 13.2489L14.4484 8.44892Z"
                                                fill="#998BF8" />
                                        </svg>
                                    </span>
                                    <span class="verified-text">Профиль подтверждён</span>
                                </div>
                                <div class="profile-name">{{ mentor.mentor_name }} {{ mentor.mentor_surname }}</div>
                                <p class="profile-title">{{ mentor.mentor_tg_title }}</p>
                                <div @click="openLink(mentor.mentor_title)" class="profile-actions">
                                    <a :href="telegramLink" class="telegram-button">
                                        <span class="telegram-icon">
                                            <svg width="25" height="26" viewBox="0 0 25 26" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.5 0C5.6 0 0 5.824 0 13C0 20.176 5.6 26 12.5 26C19.4 26 25 20.176 25 13C25 5.824 19.4 0 12.5 0ZM18.3 8.84C18.1125 10.894 17.3 15.886 16.8875 18.187C16.7125 19.162 16.3625 19.487 16.0375 19.526C15.3125 19.591 14.7625 19.032 14.0625 18.551C12.9625 17.797 12.3375 17.329 11.275 16.601C10.0375 15.756 10.8375 15.288 11.55 14.534C11.7375 14.339 14.9375 11.31 15 11.037C15.0087 10.9957 15.0075 10.9527 14.9966 10.9119C14.9857 10.8712 14.9654 10.8338 14.9375 10.803C14.8625 10.738 14.7625 10.764 14.675 10.777C14.5625 10.803 12.8125 12.012 9.4 14.404C8.9 14.755 8.45 14.937 8.05 14.924C7.6 14.911 6.75 14.664 6.1125 14.443C5.325 14.183 4.7125 14.04 4.7625 13.585C4.7875 13.351 5.1 13.117 5.6875 12.87C9.3375 11.219 11.7625 10.127 12.975 9.607C16.45 8.099 17.1625 7.839 17.6375 7.839C17.7375 7.839 17.975 7.865 18.125 7.995C18.25 8.099 18.2875 8.242 18.3 8.346C18.2875 8.424 18.3125 8.658 18.3 8.84Z"
                                                    fill="white" />
                                            </svg>
                                        </span>
                                        Написать в Telegram
                                    </a>
                                    <button class="bookmark-button" @click="toggleBookmark">
                                        <span class="bookmark-icon" :class="{ 'active': isBookmarked }">
                                            <svg viewBox="0 0 24 24" width="24" height="24">
                                                <path fill="currentColor"
                                                    d="M17 3H7c-1.1 0-2 .9-2 2v16l7-3 7 3V5c0-1.1-.9-2-2-2z" />
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="MentorDescription">
                            <component :is="isMobile ? DescriptionComponent : DescriptionComponentDesktop"
                                :description="mentor.mentor_description">
                            </component>
                        </div>
                        <div class="MentorExperience">
                            <component :is="isMobile ? ExperienceBlock : ExperienceBlockDesktop" :items="experienceItems"
                                :initialItemsCount="1">
                            </component>
                        </div>
                        <div class="MentorEducation">
                            <component :is="isMobile ? EducationBlock : EducationBlockDesktop" :items="educationItems"
                                :initialItemsCount="1">
                            </component>
                        </div>
                    </div>
                    <Spacer32 />
                    <!-- <div class="reviews-content"> -->
                    <ReviewBlock :instance="instance" :id="mentorId" :reviews="reviews"></ReviewBlock>
                    <!-- </div> -->
                    <Spacer32 />
                    <PersonalConsultationsList @openModal="openModal" :consultations="consultations" />
                    <BaseModal :isOpen="showModal" @close="closeModal">
                        <BookingModal :isOpen="showModal" :consultationTypeID="consultationTypeID" :loadingModal="loadingModal" :slots="availiableSlots" @close="closeModal"></BookingModal>
                    </BaseModal>

                    <div v-if="events.length > 0" class="Title" style="padding-bottom: 2rem;"> Актуальные мероприятия</div>
                    <div class="masonry-container">
                        <div class="masonry-column" v-for="n in colNum" :key="n">
                            <div v-for="(event, index) in events.filter((_, i) => i % colNum === n - 1)" :key="index"
                                style="margin-bottom: 2rem;">
                                <link-to-event :id="event.id">
                                    <EventCardVue :event_title="event.event_title" :event_date="event.event_date"
                                        :event_price="event.event_price" :event_imageUrl="event.event_imageUrl"
                                        :event_ageRestriction="event.event_ageRestriction"
                                        :event_availableSpots="event.event_availableSpots"
                                        :event_totalSpots="event.event_totalSpots" :event_category="event.event_category"
                                        :id="event.id" :event_id_mentor="event.event_id_mentor">
                                    </EventCardVue>
                                </link-to-event>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </div>
</template>

<script lang="ts" setup>
// Importing necessary components
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import BaseModal from '@/components/Layouts/BaseModal.vue';
// import BackButton from '../components/Navigations/BackButton.vue';
import Spacer32 from '../components/Spacers/SpacerModule32.vue';
import ReviewBlock from '../components/Review/ReviewBlock.vue';
import ExperienceBlock from '../components/pages/mentor/ExperienceComponent.vue';
import EducationBlock from '../components/pages/mentor/EducationComponent.vue';
import BookingModal from '../components/pages/mentor/BookingModal.vue';
import DescriptionComponent from '../components/pages/mentor/DescriptionComponent.vue';
import ExperienceBlockDesktop from '../components/pages/mentor/ExperienceBlockDesktop.vue';
import EducationBlockDesktop from '../components/pages/mentor/EducationBlockDesktop.vue';
import DescriptionComponentDesktop from '../components/pages/mentor/DescriptionComponentDesktop.vue';
import JumpingDotLoaderWithShadows from '@/components/Animations/JumpingDotLoaderWithShadows.vue';
import EventCardVue from "../components/EventCard.vue";
// import EventCard from '@/components/EventCard.vue';
// import { useWindowSize } from '@vueuse/core';
import PersonalConsultationsList from '@/components/pages/mentor/PersonalConsultationsList.vue';

interface Consultation {
    id: number,
    name: string;
    duration_minutes: number;
    // start_time: Date;
    // end_time: Date;
}

interface AvailiableSlot {
    id: number, 
    start_time: Date, 
    end_time: Date,
    consultation_type: number
}

const route = useRoute();
const mentorId = ref<string>(route.params.mentorId as string);
const mentor = ref<any>({});
const events = ref<any[]>([]);
const reviews = ref<any[]>([]);
const isMobile = ref<boolean>(false);
const isBookmarked = ref<boolean>(false);
const instance = ref<string>('mentor');
const loading = ref<boolean>(true);
const selectedConsultation = ref<any>(null);
const showModal = ref<boolean>(false);
const consultations = ref<Consultation[]>([]);
const consultationTypeID = ref<number>(0);
const availiableSlots = ref<AvailiableSlot>([]);
const loadingModal = ref(true)

// const avatarUrl = ref<string>('https://avatars.dzeninfra.ru/get-zen_doc/1860870/pub_630be06b4adab22ce4602f20_630be0af5847ad612e927127/scale_1200');
const alt = ref<string>('Mentor Avatar');
// const mentorName = ref<string>('Сергей Мейерхольд');
// const mentorTitle = ref<string>('Профессиональный бариста');
const telegramLink = ref<string>('#');

const colNum = ref<number>(3);

const experienceItems = ref([
    { dateRange: 'Янв 2020 — Настоящее время', position: 'Владелец', company: 'Грана кафе 12' },
    { dateRange: 'Янв 2018 — Дек 2019', position: 'Управляющий', company: 'Кафе Центральное' },
    { dateRange: 'Март 2015 — Дек 2017', position: 'Шеф-повар', company: 'Ресторан Премиум' }
]);

const educationItems = ref([
    { program: 'Технология аналитического контроля химических соединений', institution: 'РАНХиГС', years: '2011 - 2014' },
    { program: 'Химическая технология органических веществ', institution: 'МГУ', years: '2014 - 2016' }
]);

// const mentorDescription = ref<string>("Я профессиональный инструктор с более чем 10-летним опытом работы. Специализируюсь на проведении тренингов по личностному росту и развитию коммуникативных навыков.");

// const { width } = useWindowSize()

const mentorHeroRef = ref<HTMLElement | null>(null);

const mentorHeroWrapperWidth = computed(() => {
    const minWidth = 320;
    return mentorHeroWrapperWidth.value < minWidth ? minWidth : mentorHeroWrapperWidth.value;
});

const updateWidth = () => {
    if (mentorHeroRef.value) {
        mentorHeroWrapperWidth.value = mentorHeroRef.value.offsetWidth - 40;
    }
};

const handleImageError = (e: Event) => {
    const target = e.target as HTMLImageElement;
    target.src = 'fallback-image-url.jpg';
};

const toggleBookmark = () => {
    isBookmarked.value = !isBookmarked.value;
};

const fetchData = async () => {
    try {
        const response_mentor = await axios.get(`/users/GetUser/?id=${mentorId.value}`);
        const response_events = await axios.get(`/events/GetUserEvents/?user_id=${mentorId.value}`);
        const response_reviews = await axios.get(`/user_reviews/GetUserReviews/?to_user_id=${mentorId.value}`);
        const responseConsultations = await axios.get(`/consultation-types/all/?user_id=${mentorId.value}`);
        
        events.value = response_events.data;
        reviews.value = response_reviews.data;
        mentor.value = response_mentor.data;
        consultations.value = responseConsultations.data;
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loading.value = false;
    }
};

const checkMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

const openLink = (tgLink: string) => {
    window.open(tgLink, '_blank');
}

const openModal = async (consultation: any) => {
    loadingModal.value = true;
    selectedConsultation.value = consultation;
    const someDate = new Date();
    const startDate = new Date(someDate);
    const endDate = new Date(someDate);
    startDate.setDate(someDate.getDate() + 1);
    endDate.setDate(someDate.getDate() + 365);
    showModal.value = true;

    try {
        const response_slots = await axios.get(`/consultation-type-slots/available?consultation_type_id=${consultation.id}&start_date=${startDate}&end_date=${endDate}`)
        availiableSlots.value = response_slots.data
    } catch (e) {
        console.error(e) 
    } finally {
        loadingModal.value = false
    }
};

const closeModal = () => {
    showModal.value = false;
};

// const onBookAction = () => {
//     alert(`Записались на консультацию: ${selectedConsultation.value.name}`);
//     closeModal();
// };

onMounted(() => {
    fetchData();
    checkMobile();
    updateWidth();
    window.addEventListener('resize', checkMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', checkMobile);
});
</script>


<style scoped>
/* The CSS styles remain the same as in the original code */
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

/* .mentor-content {
    display:flex;
    justify-content: center;
    max-width: 1200px;
} */

.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    /* Чтобы индикатор покрывал весь экран */
    top: 0;
    left: 0;
    display: flex;
    /* Для центрирования содержимого */
    justify-content: center;
    /* Горизонтальное центрирование */
    align-items: center;
    /* Вертикальное центрирование */
    opacity: 1;
    transition: opacity 0.5s;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
    /* Время анимации */
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active в версиях <2.1.8 */
    {
    opacity: 0;
    /* Начальное значение прозрачности */
}

.card-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px 8px;
    width: 100%;
}

.MentorImage {
    width: 100%;
    height: auto;
    aspect-ratio: 4 / 5;
    /* Maintains the desired aspect ratio */
    object-fit: cover;
    border-radius: 8px;
    /* Use px or rem for consistency */
}

/* .MentorImage {
    width: calc((100vw - 3.3333vw) / 3 - 0.8333vw);
    height: calc(5 / 4 * calc((100vw - 3.3333vw) / 3 - 0.8333vw));
    object-fit: cover;
    border-radius: 0.5208vw;
} */

.MentorDescription {
    height: 100%;
    min-height: 0;
    grid-area: B;
}

.DescriptionWrapper {
    border-radius: 12px;
    background-color: #F6F6F6;
    padding: 24px;
    display: flex;
    flex-direction: column;
}

.MentorContainerDesciption {
    display: flex;
}

.MentorExperience {
    grid-area: C;
    height: 100%;
    min-height: 0;
}

.MentorEducation {
    grid-area: D;
    height: 100%;
    min-height: 0;
}

.MentorAvatar {
    grid-area: A;
    background-color: #F6F6F6;
    border-radius: 0.5208vw;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    border-radius: 12px;
}

.MentorInfo {
    grid-area: E;
}

.InfoWrapper {
    background-color: white;
    border-radius: 12px;
    padding: 24px;
    height: 224px
}

.MentorHeroWrapper {
    display: grid;
    /* width: 100%; */
    gap: 20px;
    /*gap: 16px;*/
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
        "A B B"
        "E C D";
}

.MentorHero {
    max-width: 1200px;
    display: grid;
    gap: 8px;
    width: 100%;
}

.Title {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.01em;
    text-align: left;
}

.ActualMero {
    display: flex;
    flex-direction: column;
}

.MentorRating {
    padding: 1.5vw;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
}

.BigInfoText {
    font-size: 2.4vw;
    font-weight: 500;
    color: #454152;
    text-align: left;
}

.MentorDescriptionText {
    color: #454152;
    font-size: 20px;
    font-weight: 300;
}

.SmallInfoText {
    color: #454152;
    font-size: 1vw;
    font-weight: 300;
}

.profile-container {
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(45deg,
            #f0f0f0,
            #f0f0f0 10px,
            #e0e0e0 10px,
            #e0e0e0 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    overflow: hidden;
}

.profile-photo {
    width: 100%;
    max-width: 220px;
    /* Ensures it doesn't exceed 150px */
    height: auto;
    aspect-ratio: 1 / 1;
    /* Maintains a square aspect ratio */
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}



/* .profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

.profile-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.verified-badge {
    display: flex;
    gap: 6px;
    font-size: 14px;
    /* margin-bottom: 12px; */
}

.verified-text {
    color: #6B4EE6;
}

.profile-name {
    font-size: 32px;
    font-weight: 600;
    margin: 0 0 4px 0;
    color: #2A2037;
}

.profile-title {
    font-size: 16px;
    color: #666;
}

/*
.profile-title:hover {
    cursor: pointer;
    text-decoration: underline;
}
*/

.profile-actions {
    display: flex;
    gap: 12px;
    align-items: center;
}

.telegram-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #6B4EE6;
    color: white;
    padding: 12px 24px;
    border-radius: 8px;
    text-decoration: none;
    font-size: 16px;
    border: none;
    cursor: pointer;
    flex-grow: 1;
}

.telegram-button:hover {
    background: #5B3ED6;
}

.bookmark-button {
    background: #F5F5F5;
    border: none;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.bookmark-button:hover {
    background: #EBEBEB;
}

.bookmark-icon {
    color: #666;
}

.bookmark-icon.active {
    color: #6B4EE6;
}

.telegram-icon,
.bookmark-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.masonry-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2vw;
    margin-left: 2vw;
    margin-right: 2vw;
}

/*
.masonry-container {
    display: flex;
    
    gap: 3vw;
    
    margin-left: 3vw;
} */

/* Each column */

.masonry-column {
    flex: 0 0 calc(33.33% - 2vw);
    max-width: calc(33.33% - 2vw);
}

/* .masonry-column {
    flex: 1;
    
    display: flex;
    flex-direction: column;
    
    gap: 3vw;
    
    max-width: calc((100vw - 16vw - 2*4vw)/3);
} */

.personal-consultation {
    /*padding: 20px;*/
    padding: 2vw;
    background-color: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.personal-consultation-container {
    display: grid;
    width: 100%;
    gap: 0.4167vw;
    grid-template-columns: repeat(3, 1fr);

}

.personal-consultation-second-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #434966;
    margin-bottom: 13px;

}

.personal-consultation-text {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    color: #2A2037;
    text-decoration-skip-ink: none;
    margin-bottom: 5px;
}

.personal-consultation-btn-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    display: inline-block;
}

.personal-consultation-btn {
    padding: 10px 20px;
    background: #705CE2;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    border-radius: 8px;
    cursor: pointer;
    width: 130px;
    border: none;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    white-space: nowrap;
    transition: background 0.3s ease;
}

.personal-consultation-btn:hover {
    background: #5b49c9;
}

@media (max-width: 768px) {
    .MentorHeroWrapper {
        grid-template-columns: 1fr;
        grid-template-areas:
            "A"
            "E"
            "B"
            "C"
            "D";
        gap: 20px;
    }

    .MentorExperience,
    .MentorEducation {
        height: auto;
    }

    .BigInfoText {
        font-size: 24px;
    }

    .MentorDescriptionText,
    .SmallInfoText {
        font-size: 16px;
    }

    .InfoWrapper {
        background-color: white;
        border-radius: 12px;
        padding: 24px;
        height: auto;
    }

    .personal-consultation-container {
        display: grid;
        width: 100%;
        gap: 20px;
        grid-template-columns: 1fr
    }

    .masonry-container {
        gap: 4vw;
        margin-left: 1vw;
        margin-right: 1vw;
    }

    .masonry-column {
        flex: 0 0 calc(50% - 2vw);
        max-width: calc(50% - 2vw);
    }

    .masonry-container>.masonry-column:nth-child(3) {
        display: none;
    }
}
</style>
