import { ref, computed, onMounted } from 'vue';
import { getEventsApi, loadMoreEventsApi } from '../api.js';

export function useEvents() {
    const events = ref([]);
    const loading = ref(true);
    const error = ref(null);
    const offset = ref(0); // Теперь используем offset напрямую, а не страницы
    const limit = 10; // Размер порции данных
    const hasNext = ref(true);

    // Filters
    const searchQuery = ref('');
    const selectedCategories = ref(['Все категории']);
    const minPrice = ref(null);
    const maxPrice = ref(null);
    const sortingOption = ref('newest');

    const filteredEvents = computed(() => {
        let result = [...events.value];

        // Apply search filter
        if (searchQuery.value) {
            const query = searchQuery.value.toLowerCase();
            result = result.filter(event =>
                event.title?.toLowerCase().includes(query)
            );
        }

        // Apply category filter
        if (selectedCategories.value.length && !selectedCategories.value.includes('Все категории')) {
            result = result.filter(event =>
                selectedCategories.value.includes(event.event_category)
            );
        }

        // Apply price filters
        if (minPrice.value !== null) {
            result = result.filter(event => event.price >= minPrice.value);
        }
        if (maxPrice.value !== null) {
            result = result.filter(event => event.price <= maxPrice.value);
        }

        // Apply sorting
        if (sortingOption.value === 'newest') {
            result.sort((a, b) => new Date(b.date) - new Date(a.date));
        } else if (sortingOption.value === 'oldest') {
            result.sort((a, b) => new Date(a.date) - new Date(b.date));
        } else if (sortingOption.value === 'price-asc') {
            result.sort((a, b) => a.price - b.price);
        } else if (sortingOption.value === 'price-desc') {
            result.sort((a, b) => b.price - a.price);
        }

        return result;
    });

    const fetchEvents = async () => {
        // if (loading.value || !hasNext.value) return;

        try {
            loading.value = true;
            error.value = null;

            // const data = await loadMoreEventsApi(offset.value, limit);
            const data = await getEventsApi();
            console.log("\n\n\nBIG LOG 1\n\n\n")


            if (Array.isArray(data)) {
                events.value.push(...data); // Добавляем события, а не перезаписываем
                offset.value += data.length; // Увеличиваем offset на количество полученных событий
                hasNext.value = data.length === limit; // Если вернулось меньше limit, данных больше нет
            } else if (data.results) {
                events.value.push(...data.results); // Добавляем события, а не перезаписываем
                offset.value += data.results.length; // Увеличиваем offset на количество полученных событий
                hasNext.value = data.results.length === limit; // Если вернулось меньше limit, данных больше нет
            } else {
                console.error('Unexpected data format:', data);
                hasNext.value = false;
            }
        } catch (err) {
            error.value = err.message || 'Failed to fetch events';
            console.error('Error fetching events:', err);
            hasNext.value = false;
        } finally {
            loading.value = false;
            console.log("\n\n\nBIG LOG\n\n\n")
        }
    };

    const loadMoreEvents = async () => {
        if (loading.value || !hasNext.value) return;

        try {
            loading.value = true;
            error.value = null;

            const data = await loadMoreEventsApi(offset.value, limit);

            if (Array.isArray(data)) {
                events.value.push(...data); // Добавляем новые события к существующим
                offset.value += data.length; // Увеличиваем offset
                hasNext.value = data.length === limit; // Проверяем, есть ли еще данные
            } else if (data.results) {
                events.value.push(...data.results); // Добавляем события, а не перезаписываем
                offset.value += data.results.length; // Увеличиваем offset на количество полученных событий
                hasNext.value = data.results.length === limit; // Если вернулось меньше limit, данных больше нет
            } else {
                console.error('Unexpected data format:', data);
                hasNext.value = false;
            }
        } catch (err) {
            error.value = err.message || 'Failed to load more events';
            console.error('Error loading more events:', err);
            hasNext.value = false;
        } finally {
            loading.value = false;
        }
    };

    const resetFilters = () => {
        searchQuery.value = '';
        selectedCategories.value = ['Все категории'];
        minPrice.value = null;
        maxPrice.value = null;
        sortingOption.value = 'newest';
    };

    const toggleCategory = (category) => {
        if (category === 'Все категории') {
            selectedCategories.value = ['Все категории'];
            return;
        }

        if (selectedCategories.value.includes('Все категории')) {
            selectedCategories.value = selectedCategories.value.filter(c => c !== 'Все категории');
        }

        if (selectedCategories.value.includes(category)) {
            selectedCategories.value = selectedCategories.value.filter(c => c !== category);
            if (selectedCategories.value.length === 0) {
                selectedCategories.value = ['Все категории'];
            }
        } else {
            selectedCategories.value.push(category);
        }
    };

    onMounted(() => {
        fetchEvents(); // Загружаем первую порцию событий
    });

    return {
        events,
        filteredEvents,
        loading,
        error,
        hasNext,
        searchQuery,
        selectedCategories,
        minPrice,
        maxPrice,
        sortingOption,
        fetchEvents,
        loadMoreEvents,
        resetFilters,
        toggleCategory,
    };
}