import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "error-container" }
const _hoisted_2 = { class: "error-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "error-code" }, "403", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "error-title" }, "Запрещено", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "error-message" }, " У вас нет прав для доступа к этой странице. ", -1)),
      _createVNode(_component_router_link, {
        to: "/",
        class: "error-button"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Вернуться на главную")
        ])),
        _: 1
      })
    ])
  ]))
}