<template>
    <v-card :color="cardColor" :class="{ 'white--text': mode === 'leading' }" variant="elevated" rounded="lg">
        <v-card-item>
            <v-chip v-if="event.sessionType" size="small"
                :color="mode === 'leading' ? 'rgba(255, 255, 255, 0.2)' : 'primary'"
                :text-color="mode === 'leading' ? 'white' : ''" variant="flat" class="mb-2">
                {{ event.sessionType }}
            </v-chip>
            <v-card-title>{{ event.title }}</v-card-title>
            <v-card-subtitle :class="{ 'white--text': mode === 'leading' }">
                {{ formattedDate }}
            </v-card-subtitle>
        </v-card-item>

        <v-card-text>
            <div v-if="event.status === 'live'" class="d-flex align-center mb-2">
                <v-icon :color="mode === 'leading' ? 'white' : 'success'" size="small" class="mr-2">
                    mdi-circle
                </v-icon>
                <span :class="{ 'white--text': mode === 'leading' }">
                    {{ getLiveStatusText }}
                </span>
            </div>
            <div v-if="event.description" class="mb-2" :class="{ 'white--text': mode === 'leading' }">
                {{ event.description }}
            </div>
        </v-card-text>

        <v-card-text>
            <!-- Покажем conferenceStatus, если нужно -->
            <div>
                conference_status: {{ event.conferenceStatus }}
            </div>
        </v-card-text>

        <v-card-actions>
            <!-- Main Action Button -->
            <v-btn v-if="event.status === 'live'" :color="mode === 'leading' ? 'white' : 'primary'"
                :text-color="mode === 'leading' ? 'primary' : ''" variant="text" @click="emitjoinEvent">
                Открыть
            </v-btn>
            <v-btn v-else-if="mode === 'participating' || (mode === 'all' && event.role === 'participating')"
                :color="mode === 'leading' ? 'white' : 'primary'" :text-color="mode === 'leading' ? 'primary' : ''"
                variant="text" @click="emitjoinEvent" v-show="event.status !== 'done'">
                Присоединиться
            </v-btn>
            <v-btn :color="mode === 'leading' ? 'white' : 'primary'" :text-color="mode === 'leading' ? 'primary' : ''"
                variant="text" @click="emitOpenEvent">
                Подробнее
            </v-btn>

            <v-spacer></v-spacer>

            <!-- Additional Action Buttons -->
            <template v-if="mode === 'leading' || (mode === 'all' && event.role === 'leading')">
                <!-- <v-btn :color="mode === 'leading' ? 'white' : 'secondary'"
                    :text-color="mode === 'leading' ? 'primary' : ''" variant="text" @click="emitEditEvent">
                    Редактировать
                </v-btn> -->
                <v-btn :color="mode === 'leading' ? 'white' : 'error'" :text-color="mode === 'leading' ? 'error' : ''"
                    variant="text" @click="emitCancelEvent">
                    Отменить
                </v-btn>
            </template>
            <template v-else-if="mode === 'participating' || (mode === 'all' && event.role === 'participating')">
                <v-btn :color="mode === 'leading' ? 'white' : 'error'" :text-color="mode === 'leading' ? 'error' : ''"
                    variant="text" @click="emitLeaveEvent">
                    Отказаться
                </v-btn>
            </template>
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
/* eslint-disable*/
import { computed } from 'vue';


interface EventData {
  id: number;
  title: string;
  date: string;
  description?: string;
  status?: 'live' | 'cancelled' | 'done' | 'pending';
  role: 'leading' | 'participating';
  sessionType?: string;
  bookingId?: number;
  mentorName?: string;
  mentorAvatar?: string;
  duration?: string;
  cost?: string;
  question?: string;
}

const props = defineProps<{
    event: EventData;
    mode: string;
}>();


const emit = defineEmits<{
  (e: 'openEvent', id: number): void;
  (e: 'joinEvent', id: number): void;
  (e: 'editEvent', id: number): void;
  (e: 'cancelEvent', id: number): void;
  (e: 'leaveEvent', id: number): void;
}>();

const cardColor = computed(() => {
  if (props.mode === 'leading') return '#7B65F7';
  if (props.mode === 'participating') return '#EAE8FD';
  return undefined;
});

const formattedDate = computed(() => {
    const d = new Date(props.event.date);
    return d.toLocaleString('ru-RU', {
        day: 'numeric',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit',
    });
});

const getLiveStatusText = computed(() => {
    if (props.event.status === 'live') {
        // Можно добавить логику для отображения времени в эфире
        return '20 минут в эфире';
    }
    return '';
});

function emitOpenEvent() {
    emit('openEvent', props.event.id);

}

function emitjoinEvent() {
    emit('joinEvent', props.event.id);

}

function emitEditEvent() {
    emit('editEvent', props.event.id);
}

function emitCancelEvent() {
    emit('cancelEvent', props.event.id);
}

function emitLeaveEvent() {
    emit('leaveEvent', props.event.id);
}
</script>
