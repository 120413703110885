<template>
    <div>
        <div class="review-content">
            <div class="review-brief-info" style="padding-bottom: 2rem;">
                <div class="review-title" style="margin-right: 10px;"> Отзывы </div>
                <span class="review-title" style="color:#434966; margin-right: 30px;"> {{ reviews.length }} </span>
                <div class="review-add-button" @click="openModal">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                        style="margin-right: 10px;">
                        <path
                            d="M9 6.5V9M9 9V11.5M9 9H11.5M9 9H6.5M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
                            stroke="#7B65F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Добавить</span>
                </div>
            </div>

            <Spacer32 />
            <Swiper :slidesPerView="slidesPerView" :spaceBetween="spaceBetween" :breakpoints="swiperBreakpoints"
                @swiper="setSwiper">
                <SwiperSlide v-for="review in reviews" :key="review.id">
                    <ReviewItem :review="review" />
                </SwiperSlide>
            </Swiper>
            <Spacer-16 />
            <div v-if="reviews.length > 3" class="ReviewButtons">
                <button class="ReviewLeftArrowButton" @click="swiper && swiper.slidePrev()">
                    <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.8889 22.8889L1 12M1 12L11.8889 1.11108M1 12L29 12" stroke="#434966"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button>
                <button class="ReviewRightArrowButton" @click="swiper && swiper.slideNext()">
                    <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.1111 1.11108L29 12M29 12L18.1111 22.8889M29 12L1 12" stroke="#434966"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button>
            </div>
        </div>

        <teleport to="body">
            <transition name="modal">
                <div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
                    <div class="modal-content" @click.stop>
                        <button @click="closeModal" class="close-btn">&times;</button>
                        <div class="modal-body">
                            <form @submit.prevent="submitReview">
                                <div class="form-group">
                                    <h3>Напишите свой отзыв здесь</h3>
                                    <textarea v-model="form.review_text" @input="checkMaxLength" id="review_text"
                                        class="form-control" maxlength="1000" required></textarea>
                                    <p>{{ remainingChars }} characters remaining</p>
                                </div>
                                <button type="submit" class="btn btn-primary">Submit Review</button>
                            </form>
                        </div>
                    </div>
                </div>
            </transition>
        </teleport>
    </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import Spacer32 from '../Spacers/SpacerModule32.vue';
import Spacer16 from '../Spacers/SpacerModule16.vue';
import ReviewItem from './ReviewItem.vue';
import axios from 'axios';

export default defineComponent({
    name: 'ReviewBlock',
    components: {
        ReviewItem,
        Spacer32,
        Spacer16,
        Swiper,
        SwiperSlide,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        instance: {
            type: String,
            required: true,
        },
        reviews: {
            type: Array,
            default: () => [],
        },
    },
    setup(props) {
        const swiper = ref(null);
        const isModalOpen = ref(false);
        const form = ref({
            review_text: localStorage.getItem('review_text') || '', // Load saved review from localStorage
            review_stars: 5,
            review_date: '20 августа 2024',
        });
        const maxChars = 1000;
        const slidesPerView = ref(3);
        const spaceBetween = ref('20px');
        const swiperBreakpoints = ref({
            1024: {
                slidesPerView: 3,
                spaceBetween: '20px',
            },
            0: {
                slidesPerView: 1,
                spaceBetween: 0,
            },
        });

        const remainingChars = computed(() => maxChars - form.value.review_text.length);

        function checkMaxLength() {
            if (form.value.review_text.length > maxChars) {
                form.value.review_text = form.value.review_text.slice(0, maxChars);
            }
        }

        function submitReview() {
            console.log("submit happend");
            console.log("it is form", form.value);
            localStorage.setItem('review_text', form.value.review_text);
            if (props.instance === "mentor") {
                form.value.to_user_id = String(props.id);
                axios.post('https://platforma34.tw1.ru/api/user_reviews/AddUserReview/', form.value)
                    .then(() => {
                        alert('Review submitted successfully!');
                    })
                    .catch(error => {
                        console.error(error);
                        alert('Failed to submit the review.');
                    });
            } else if (props.instance === "event") {
                form.value.event_id = String(props.id);
                axios.post('https://platforma34.tw1.ru/api/event_reviews/AddEventReview/', form.value)
                    .then(() => {
                        alert('Review submitted successfully!');
                    })
                    .catch(error => {
                        console.error(error);
                        alert('Failed to submit the review.');
                    });
            }
        }

        function openModal() {
            isModalOpen.value = true;
            document.body.style.overflow = 'hidden';
        }

        function closeModal() {
            isModalOpen.value = false;
            document.body.style.overflow = '';
        }

        function setSwiper(swiperInstance) {
            swiper.value = swiperInstance;
        }

        return {
            swiper,
            isModalOpen,
            form,
            slidesPerView,
            spaceBetween,
            swiperBreakpoints,
            remainingChars,
            checkMaxLength,
            submitReview,
            openModal,
            closeModal,
            setSwiper,
        };
    },
});
</script>

<style scoped>
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
}

.review-content {
    max-width: 1200px;
}

.ReviewList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px 8px;
    width: 100%;
}

.ReviewRating {
    margin-left: 0.2vw;
    margin-right: 0.5vw;
    color: #454152;
    font-size: 1.2vw;
    font-weight: 300;
}

.Title {
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1A1C26;
}

.ReviewButtonLink {
    border: 1px solid #454152;
    border-radius: 1vw;
    padding: 4px 8px 4px 8px;
    text-align: center;
    font-weight: 300;
}

.ReviewContainer1 {
    display: flex;
    justify-content: space-between;
}

.ReviewContainer2 {
    display: flex;
    flex-direction: column;
    /* width: 8vw; */
}

.ReviewContainer3 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.SmallInfoText {
    color: #454152;
    font-size: 1vw;
    font-weight: 300;
}

.BigInfoText {
    font-size: 2.4vw;
    font-weight: 500;
    /* height: 25px; */
    color: #454152;
    text-align: left;
}

.ReviewButtons {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.ReviewLeftArrowButton,
.ReviewRightArrowButton {
    padding: 14px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.ReviewLeftArrowButton svg,
.ReviewRightArrowButton svg {
    width: 33px;
    height: 33px;
}

.Button {
    width: 6rem;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    padding: 6px 24px 8px 24px;
    background: white;
    border-radius: 20px;
    border: 1px solid #DAD9DC;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    /* Длительность увеличена до 0.5s и плавный переход */

}

.Button:hover {
    background: #454152;
    color: white;
    cursor: pointer;
    transform: scale(1.02);
    /* Небольшое увеличение карточки при наведении */
    opacity: 0.95;
    /* Изменение прозрачности */
    transition-delay: 0.01s;
    /* Задержка перед началом анимации */
}

#overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9998;
}

#popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 9999;
    text-align: center;
    border-radius: 8px;
    max-width: 300px;
    width: 100%;
    height: 500px;
}

.form-control {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    height: 100%;
    text-align: start;
    vertical-align: top;
    resize: none;
}

.closePopup {
    display: flex;
    justify-content: end;
}

.btn {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
}

.btn:hover {
    background-color: #0056b3;
}

p {
    font-size: 12px;
    color: grey;
}

.review-brief-info {
    display: flex;
    align-items: center;
}

.review-title {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.01em;
    text-align: left;
}

.review-add-button {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #7B65F7;
}

.review-add-button:hover {
    transition: 0.3s;
    color: #5747AF;
    cursor: pointer;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #2a2037;
}

.modal-content {
    background-color: white;
    padding: 32px;
    border-radius: 12px;
    max-width: 600px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
}

.reviewField {
    display: flex;
    flex-direction: column;
}

.modal-enter-active,
.modal-leave-active {
    transition: all 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
    opacity: 0;
}

/* .modal-enter-from .modal-content,
.modal-leave-to .modal-content {
    transform: scale(0.9);
} */

@media (max-width: 767px) {
    .ReviewContainer1 {
        flex-direction: column;
        align-items: flex-start;
    }

    .ReviewButtons {
        justify-content: center;
        margin-top: 16px;
    }

    .ReviewButtonLink {
        margin-top: 8px;
    }

}
</style>
