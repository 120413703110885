<!-- bookingModal.vue -->
<template>
    <div class="modal">
        <div class="modal-content">
            <button class="close-btn" @click="$emit('close')">×</button>

            <!-- Step 1: Booking -->
            <div v-if="currentStep === 1">
                <h2 class="modal-title">Сессия наставничества</h2>
                <p class="modal-subtitle">Забронируйте личную сессию на удобный день</p>

                <!-- Calendar -->
                <div class="week-calendar">
                    <div class="navigation">
                        <button @click="prevWeek" class="nav-btn">←</button>
                        <h2>{{ formattedWeekRange }}</h2>
                        <button @click="nextWeek" class="nav-btn">→</button>
                    </div>

                    <!-- Swiper с 3 слайдами: предыдущая, текущая и следующая неделя -->
                    <!-- <Swiper :slidesPerView="1" :spaceBetween="0" :initialSlide="1" :allowTouchMove="true"
                        @swiper="setSwiper" @transitionEnd="onTransitionEnd">
                        <SwiperSlide v-for="(week, index) in weekSlides" :key="index">
                            <div class="week-days">
                                <div v-for="day in week.days" :key="day.format('YYYY-MM-DD')" class="day"
                                    :class="{ selected: isSelected(day) }" @click="selectDate(day)">
                                    <div class="day-name">{{ day.format('dd') }}</div>
                                    <div class="date">{{ day.format('DD MMM') }}</div>
                                    <div class="options">{{ getSlotCount(week, day) }} опции</div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper> -->
                    <Swiper :slidesPerView="1" :spaceBetween="0" :initialSlide="1" :allowTouchMove="true"
                        @swiper="setSwiper" @transitionEnd="onTransitionEnd">
                        <SwiperSlide v-for="(week, index) in weekSlides" :key="index">
                            <div class="week-days">
                                <div v-for="day in week.days" :key="day.format('YYYY-MM-DD')" class="day"
                                    :class="{ selected: isSelected(day) }" @click="selectDate(day)">
                                    <div class="day-name">{{ day.format('dd') }}</div>
                                    <div class="date">{{ day.format('DD MMM') }}</div>
                                    <div class="options" :class="{ 'no-slots': getSlotCount(week, day) === 0 }">
                                        <template v-if="week.slotsLoading">
                                            <JumpingDots :size="4" :gap="4" />
                                        </template>
                                        <template v-else>
                                            {{ getSlotCount(week, day) }} опции
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>

                <!-- Time Selection -->
                <div class="time-selection" v-if="selectedSlots.length">
                    <h3>Выберите удобное время</h3>
                    <div class="time-slots">
                        <div v-for="slot in selectedSlots" :key="slot.id" class="time-slot"
                            :class="{ selected: selectedTime === slot }" @click="selectTime(slot)">
                            <div class="time-range">
                                {{ formatTime(slot.start_time) }} - {{ formatTime(slot.end_time) }}
                            </div>
                            <div class="duration">{{ (new Date(slot.end_time) - new Date(slot.start_time)) / 1000 / 60 }} минут</div>
                        </div>
                    </div>
                </div>

                <!-- Footer -->
                <div class="modal-footer">
                    <div class="step-indicator">Шаг 1/2</div>
                    <button @click="goToConfirmation" class="next-btn" :disabled="!selectedTime">
                        Далее
                    </button>
                </div>
            </div>

            <!-- Step 2: Confirmation -->
            <div v-else class="confirmation-step">
                <!-- Если данные о менторе загружаются, показываем индикатор -->
                <div v-if="isMentorLoading" class="mentor-loading" style="max-width: 100%; max-height: 100%;">
                    <JumpingDots :size="10" :gap="10" :numbers="4" />
                </div>
                <div v-else>
                    <div style="display: flex; flex-direction: row; justify-content: space-around;">
                        <div>
                            <div class="mentor-info">
                                <img :src="mentorData.avatar" alt="Mentor" class="mentor-avatar">
                                <div class="mentor-details">
                                    <div class="mentor-name">{{ mentorData.name }}</div>
                                    <p>{{ mentorData.role }}</p>
                                </div>
                            </div>
                            <hr style="margin-bottom: 1rem; opacity: 0.5;" />
                            <div class="session-info">
                                <h3>Личная сессия</h3>
                                <div class="info-grid">
                                    <div class="info-item">
                                        <span class="label">Длительность</span>
                                        <span class="value">{{ mentorData.consultationType?.duration_minutes || 60 }}
                                            минут</span>
                                    </div>
                                    <div class="info-item">
                                        <span class="label">Тема</span>
                                        <span class="value">{{ mentorData.consultationType?.name ||
                                            'Сессия наставничества' }}</span>
                                    </div>
                                    <div class="info-item">
                                        <span class="label">Стоимость</span>
                                        <span class="value">1500 руб</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-section">
                            <h2 class="confirmation-title">Подтвердите запись</h2>
                            <div class="booking-details">
                                <div class="detail-item">
                                    <i class="calendar-icon"></i>
                                    <span>{{ formatConfirmationDate(selectedDate) }}</span>
                                </div>
                                <div class="detail-item">
                                    <i class="clock-icon"></i>
                                    <span>{{ formatTime(selectedTime?.start_time) }} - {{
                                        formatTime(selectedTime?.end_time) }}</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Задать вопросы ментору</label>
                                <textarea v-model="mentorQuestions" class="form-textarea" placeholder="Советы как задать вопросы:
  • Задавайте конкретные вопросы
  • Добавляйте ссылку на портфолио
  • Поделитесь своей целью на сессию"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <div class="step-indicator">Шаг 2/2</div>
                        <div class="button-group">
                            <button @click="currentStep = 1" class="back-btn">
                                Назад
                            </button>
                            <button @click="confirmBooking" class="confirm-btn">
                                Подтвердить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, computed, defineEmits } from 'vue';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ru';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import JumpingDots from '@/components/Animations/JumpingDots.vue';
import { useStore } from 'vuex'


moment.locale('ru');

const emit = defineEmits(['close']);

const store = useStore()

// State
const currentStep = ref(1);
const currentWeekStart = ref(moment().startOf('isoWeek'));

// Флаг загрузки данных о менторе для шага подтверждения
const isMentorLoading = ref(false);

// Массив с данными для 3 слайдов: предыдущая, текущая и следующая неделя
const weekSlides = reactive([
    { start: moment(currentWeekStart.value).subtract(1, 'week'), days: [], slots: [], slotsLoading: true },
    { start: moment(currentWeekStart.value), days: [], slots: [], slotsLoading: true },
    { start: moment(currentWeekStart.value).add(1, 'week'), days: [], slots: [], slotsLoading: true }
]);

// Заполняем дни для каждого слайда
const updateWeekSlides = () => {
    weekSlides.forEach(week => {
        week.days = Array.from({ length: 7 }, (_, i) =>
            moment(week.start).clone().add(i, 'days')
        );
    });
};
updateWeekSlides();

// Загружаем слоты для конкретной недели
const fetchWeekSlots = async (week) => {
    week.slotsLoading = true;
    const startTime = Date.now();
    const minLoadingTime = 500; // Минимальное время отображения анимации (1 секунда)
    const start = week.start.format('YYYY-MM-DD');
    const end = week.start.clone().endOf('isoWeek').format('YYYY-MM-DD');
    try {
//        const API_URL = 'https://platforma34.tw1.ru/api';
// 
// Create axios instance
        // const api = axios.create({
        //     baseURL: API_URL,
        //     headers: {
        //         'Content-Type': 'application/json',
        //     }
        // });
        // const response = await axios.get(
        //     `https://platforma34.tw1.ru/api/consultation-type-slots/available/?consultation_type_id=20&start_date=${start}&end_date=${end}`
        // );

        const response = await axios.get(`/consultation-type-slots/available/`, {
            params: { 
                start_date: start,
                end_date: end,
                consultation_type_id: 27
             }
        });
        week.slots = response.data;
        console.log(response.data)
        console.log(week.slots)
    } catch (err) {
        console.error('Ошибка загрузки слотов для недели', start, err);
        week.slots = [];
    } finally {
        const loadingTime = Date.now() - startTime;
        if (loadingTime < minLoadingTime) {
            await delay(minLoadingTime - loadingTime);
        }
        week.slotsLoading = false;
    }
};

// Обновляем слоты для всех недель
const updateAllWeeksSlots = async () => {
    for (const week of weekSlides) {
        await fetchWeekSlots(week);
    }
};

// При первичной загрузке обновляем все недели
updateAllWeeksSlots();

// Вычисляем отображаемый диапазон дат для центрального слайда
const formattedWeekRange = computed(() => {
    const days = weekSlides[1].days;
    if (!days || days.length === 0) return '';
    const start = days[0].format('DD MMM');
    const end = days[6].format('DD MMM');
    return `${start} - ${end}`;
});

// Выбранная дата и время
const selectedDate = ref(null);
const selectedTime = ref(null);
const mentorQuestions = ref('');

// Вычисляем слоты для выбранного дня из центрального слайда
const selectedSlots = computed(() => {
    if (!selectedDate.value) return [];
    return weekSlides[1].slots.filter(slot =>
        moment(slot.start_time).isSame(selectedDate.value, 'day')
    );
});

// Swiper instance
const swiper = ref(null);
const setSwiper = (swiperInstance) => {
    swiper.value = swiperInstance;
};

// Навигация — переключение слайдов
const prevWeek = () => {
    weekSlides[0].slotsLoading = true;
    weekSlides[1].slotsLoading = true;
    weekSlides[2].slotsLoading = true;
    if (swiper.value) {
        swiper.value.slidePrev();
    }
};

const nextWeek = () => {
    weekSlides[0].slotsLoading = true;
    weekSlides[1].slotsLoading = true;
    weekSlides[2].slotsLoading = true;
    if (swiper.value) {
        swiper.value.slideNext();
    }
};

// По завершении анимации переключения слайдов обновляем даты и данные
const onTransitionEnd = () => {
    const swiperInstance = swiper.value;
    if (!swiperInstance) return;
    // weekSlides[0].slotsLoading = true;
    // weekSlides[1].slotsLoading = true;
    // weekSlides[2].slotsLoading = true;
    let newWeekStart;
    if (swiperInstance.activeIndex === 2) {
        newWeekStart = moment(weekSlides[2].start);
    } else if (swiperInstance.activeIndex === 0) {
        newWeekStart = moment(weekSlides[0].start);
    } else {
        return;
    }

    // Обновляем текущую неделю и пересчитываем даты для всех слайдов
    currentWeekStart.value = newWeekStart;
    weekSlides[1].start = moment(currentWeekStart.value);
    weekSlides[0].start = moment(currentWeekStart.value).subtract(1, 'week');
    weekSlides[2].start = moment(currentWeekStart.value).add(1, 'week');
    updateWeekSlides();

    // Сбрасываем слайдер в центральный слайд сразу, не дожидаясь загрузки данных
    setTimeout(() => {
        swiperInstance.slideTo(1, 0);
    }, 0);

    // Обновляем слоты в фоновом режиме (не ждём завершения)
    updateAllWeeksSlots();
};

// Менторские данные – изначально null
const mentorData = ref(null);

// Вспомогательная функция для создания задержки
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// Функция загрузки данных о менторе для шага подтверждения
const fetchMentorData = async () => {
    if (!selectedTime.value || !selectedTime.value.id) return;

    isMentorLoading.value = true;
    const startTime = Date.now();
    const minLoadingTime = 1000; // Минимальное время отображения анимации (1 секунда)

    try {
        const consultationSlotId = selectedTime.value.id;
        const [response] = await Promise.all([
            // axios.get(`https://platforma34.tw1.ru/api/consultation-type-slots/${consultationSlotId}/detailed_info/`),
            axios.get(`/consultation-type-slots/${consultationSlotId}/detailed_info/`),
            // delay(minLoadingTime) // Гарантируем минимальное время отображения анимации
        ]);

        // Формируем данные ментора
        mentorData.value = {
            name: `${response.data.mentor.name} ${response.data.mentor.surname}`,
            role: response.data.mentor.tg_title,
            avatar: response.data.mentor.avatar_url,
            consultationType: response.data.consultation_type,
            slot: response.data.slot
        };
    } catch (error) {
        console.error("Ошибка загрузки данных о менторе", error);
        mentorData.value = {
            name: 'Не удалось загрузить',
            role: '',
            avatar: ''
        };
    } finally {
        const loadingTime = Date.now() - startTime;
        if (loadingTime < minLoadingTime) {
            await delay(minLoadingTime - loadingTime);
        }
        isMentorLoading.value = false;
    }
};

// Переход на шаг подтверждения: загружаем данные о менторе, затем переключаем шаг
const goToConfirmation = async () => {
    if (selectedTime.value) {
        currentStep.value = 2;
        await fetchMentorData();
    }
};

const isSelected = (date) =>
    selectedDate.value && date.isSame(selectedDate.value, 'day');

const selectDate = (date) => {
    selectedDate.value = date;
    selectedTime.value = null;
};

const getSlotCount = (week, day) => {
    if (!week.slots) return 0;
    return week.slots.filter(slot =>
        moment(slot.start_time).isSame(day, 'day')
    ).length;
};

const selectTime = (slot) => {
    selectedTime.value = slot;
};

const formatTime = (time) => (time ? moment(time).format('HH:mm') : '');

const capitalizeFirstLetter = (val) =>
    String(val).charAt(0).toUpperCase() + String(val).slice(1);

const formatConfirmationDate = (date) => {
    if (!date) return '';
    return capitalizeFirstLetter(date.format('dddd, DD.MM'));
};

const confirmBooking = async () => {
    try {
        const payload = {
            consultation_type_slot: selectedTime.value.id,
            user_id: store.getters['userId'],
            questions: mentorQuestions.value
        };

        const response = await axios.post(
            // 'https://platforma34.tw1.ru/api/consultation-bookings/create/',
            '/consultation-bookings/create/',
            payload,
            { headers: { 'Content-Type': 'application/json' } }
        );

        console.log('Успешное бронирование:', response.data);
        emit('close');
    } catch (error) {
        console.error('Ошибка бронирования:', error.response?.data || error.message);
    }
};
</script>

<style scoped>
/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    border-radius: 12px;
    padding: 32px;
    width: 90%;
    max-width: 900px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
}

.modal-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #1a1a1a;
}

.modal-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 24px;
}

/* Calendar Styles */
.week-calendar {
    margin: 24px 0;
}

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.nav-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 8px;
    color: #666;
}

.week-days {
    display: flex;
    gap: 12px;
    overflow-x: auto;
    padding: 4px;
    margin: 0 -4px;
}

.day {
    flex: 0 0 auto;
    width: calc((100% - 310px) / 7);
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.day:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.day.selected {
    border-color: #6b4ee6;
    background-color: #f8f7ff;
}

.day-name {
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
    text-transform: uppercase;
}

.date {
    font-size: 16px;
    font-weight: bold;
    color: #1a1a1a;
    margin-bottom: 4px;
}

.options {
    font-size: 14px;
    color: #4caf50;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    /* Set a fixed height to prevent layout shifts */
}

.options.no-slots {
    color: #ff0000;
}

/* Time Selection Styles */
.time-selection {
    margin-top: 32px;
}

.time-selection h3 {
    font-size: 20px;
    margin-bottom: 16px;
    color: #1a1a1a;
}

.time-slots {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 12px;
}

.time-slot {
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.time-slot:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.time-slot.selected {
    border-color: #6b4ee6;
    background-color: #6b4ee6;
    color: white;
}

.time-range {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 4px;
}

.duration {
    font-size: 14px;
    color: #666;
}

.time-slot.selected .duration {
    color: rgba(255, 255, 255, 0.8);
}

/* Confirmation Step Styles */
.confirmation-step {
    padding: 24px 0;
}

.mentor-info {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
}

.mentor-avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
}

.mentor-details h2 {
    font-size: 24px;
    margin: 0;
    color: #1a1a1a;
}

.mentor-details p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1%;
    color: #2A2037;
}

.confirmation-title {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #2A2037;
}

.booking-details {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
}

.detail-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #525A7D;
}

.calendar-icon,
.clock-icon {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
}

.calendar-icon {
    background-image: url('../../../assets/images/icons/calendar-icon.svg');
}

.clock-icon {
    background-image: url('../../../assets/images/icons/clock-icon.svg');
}

.session-info {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    height: 17rem;
    margin-bottom: 24px;
}

.session-info h3 {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #2A2037;
}

.info-grid {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.info-item {
    display: flex;
    flex-direction: column;
}

.label {
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #525A7D;
}

.value {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #2A2037;
}

.form-section {
    margin-bottom: 24px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #525A7D;
}

.form-textarea {
    width: 100%;
    height: 14rem;
    margin-top: 1rem;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    resize: none;
    outline: none;
    font-weight: 500;
    font-size: 18px;
    color: #A2A5B3;
    transition: border-color 0.3s ease-in-out;
}

.form-textarea:focus {
    border-color: #7B65F7;
    box-shadow: 0 0 5px rgba(123, 101, 247, 0.5);
}

/* Footer Styles */
.modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    padding-top: 16px;
    border-top: 1px solid #e0e0e0;
}

.step-indicator {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #434966;
}

.button-group {
    display: flex;
    gap: 12px;
}

.back-btn {
    padding: 12px 24px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background: white;
    color: #666;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.back-btn:hover {
    background: #f5f5f5;
}

.next-btn,
.confirm-btn {
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    background: #6b4ee6;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.next-btn:hover,
.confirm-btn:hover {
    background: #5b3ed6;
}

.next-btn:disabled {
    background: #e0e0e0;
    cursor: not-allowed;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .modal-content {
        padding: 24px;
        width: 95%;
    }

    .info-grid {
        grid-template-columns: 1fr;
    }

    .booking-details {
        flex-direction: column;
        gap: 12px;
    }

    .button-group {
        width: 100%;
    }

    .date {
        text-align: center;
    }

    .options {
        text-align: center;
    }

    .week-days {
        text-align: center;
    }
}

@media (max-width: 480px) {
    .modal-content {
        padding: 16px;
    }

    .week-days {
        gap: 8px;
        justify-content: center;
        flex-wrap: wrap;
    }

    .day {
        width: calc(100% / 7);
        padding: 12px;
    }

    .time-slots {
        grid-template-columns: 1fr;
    }
}

.mentor-name {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #2A2037;
}

/* Стили для индикатора загрузки на странице подтверждения */
.mentor-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 18px;
    color: #666;
}
</style>