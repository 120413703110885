import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, mergeProps as _mergeProps, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { style: {"display":"flex","flex-direction":"column"} }
const _hoisted_3 = { class: "content-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "sidebar-left"
}
const _hoisted_5 = { class: "nav-block" }
const _hoisted_6 = { class: "nav-menu" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "nav-text" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 1,
  class: "mobile-footer"
}
const _hoisted_12 = { class: "nav-menu" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileMenu = _resolveComponent("ProfileMenu")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent($setup.headerComponent))),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!$setup.isMobile)
          ? (_openBlock(), _createElementBlock("aside", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("nav", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.navItems, (item) => {
                    return (_openBlock(), _createElementBlock("button", {
                      key: item.id,
                      class: _normalizeClass(['nav-item', { active: $setup.activeNavItem === item.id }]),
                      onClick: ($event: any) => ($setup.handleNavItemClick(item.id))
                    }, [
                      _createElementVNode("div", {
                        class: "icon-container",
                        innerHTML: $setup.iconItems[item.id]
                      }, null, 8, _hoisted_8),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(item.text), 1)
                    ], 10, _hoisted_7))
                  }), 128))
                ]),
                ($setup.isAuthenticated)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_v_menu, {
                        modelValue: $setup.isProfileMenuOpen,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.isProfileMenuOpen) = $event)),
                        "close-on-content-click": false,
                        transition: "fade-transition",
                        "transition-duration": 300
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("img", _mergeProps({
                            src: "https://storage.yandexcloud.net/testbucketplatforma34/woman_blue_background.jpeg",
                            class: "profile-nav"
                          }, props), null, 16)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_ProfileMenu)
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        ($setup.isMobile)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("nav", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.navItems, (item) => {
                  return (_openBlock(), _createElementBlock("button", {
                    key: item.id,
                    class: _normalizeClass(['nav-item', { active: $setup.activeNavItem === item.id }]),
                    onClick: ($event: any) => ($setup.handleNavItemClick(item.id))
                  }, [
                    _createElementVNode("div", {
                      class: "icon-container",
                      innerHTML: $setup.iconItems[item.id]
                    }, null, 8, _hoisted_14)
                  ], 10, _hoisted_13))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("main", _hoisted_15, [
          _createVNode(_component_router_view)
        ]),
        (_openBlock(), _createBlock(_resolveDynamicComponent($setup.footerComponent)))
      ])
    ])
  ]))
}