import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "personal-consultations-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PersonalConsultation = _resolveComponent("PersonalConsultation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.consultations, (consultation, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createVNode(_component_PersonalConsultation, {
          consultation: consultation,
          onBook: _ctx.onBookConsultation
        }, null, 8, ["consultation", "onBook"])
      ]))
    }), 128))
  ]))
}