<template>
    <div class="event-preview">
        <div class="content">
            <main class="main">
                <div class="main-content">
                    <!-- Теги -->
                    <div class="tags">
                        <span class="tag" style="background: var(--orange-orange200, #FDE2D3);">{{
                            currentEvent.event_ageRestriction || '18+' }}</span>
                    </div>

                    <!-- Название -->
                    <h1 class="title">{{ currentEvent.event_title || 'Без названия' }}</h1>

                    <!-- Дата и время -->
                    <p class="date">{{ formatDate(currentEvent.event_date) }}</p>

                    <!-- Изображение -->
                    <img :src="currentEvent.event_imageUrl || 'https://via.placeholder.com/1920x1080'" alt="Event image"
                        class="event-image" />

                    <!-- Мобильный блок -->
                    <div class="mobile-only">
                        <div
                            style="font-size: 18px; font-weight: 500; line-height: 24px; color: var(--neutral-neutral700, #525A7D); margin-bottom: 10px;">
                            Отправь ссылку на мероприятие другу
                        </div>
                        <div class="share-link-box">
                            <div class="link-text">{{ "https://platforma34.tw1.ru/event_info/" + (currentEvent.id ||
                                'preview') }}</div>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.66683 6.014V12.6807C6.66683 13.6011 7.41302 14.3473 8.3335 14.3473H13.3335M6.66683 6.014V4.34733C6.66683 3.42686 7.41302 2.68066 8.3335 2.68066H12.155C12.376 2.68066 12.588 2.76846 12.7442 2.92474L16.4228 6.60325C16.579 6.75953 16.6668 6.9715 16.6668 7.19251V12.6807C16.6668 13.6011 15.9206 14.3473 15.0002 14.3473H13.3335M6.66683 6.014H5.3335C4.22893 6.014 3.3335 6.90943 3.3335 8.014V16.014C3.3335 16.9345 4.07969 17.6807 5.00016 17.6807H11.3335C12.4381 17.6807 13.3335 16.7852 13.3335 15.6807V14.3473"
                                    stroke="#A2A5B3" stroke-width="2.04" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div
                            style="display: flex; flex-direction: row; gap: 1rem; align-items: center; margin-bottom: 1rem;">
                            <svg width="161" height="57" viewBox="0 0 161 57" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_56165_4056)">
                                    <rect y="0.674805" width="56" height="56" rx="28" fill="white" />
                                    <rect y="0.674805" width="56" height="56" />
                                </g>
                                <rect x="0.5" y="1.1748" width="55" height="55" rx="27.5" stroke="#A2A5B3" />
                                <g clip-path="url(#clip1_56165_4056)">
                                    <rect x="35" y="0.674805" width="56" height="56" rx="28" fill="white" />
                                    <rect x="35" y="0.674805" width="56" height="56" />
                                </g>
                                <rect x="35.5" y="1.1748" width="55" height="55" rx="27.5" stroke="#A2A5B3" />
                                <g clip-path="url(#clip2_56165_4056)">
                                    <rect x="70" y="0.674805" width="56" height="56" rx="28" fill="#F3F4F5" />
                                </g>
                                <rect x="70.5" y="1.1748" width="55" height="55" rx="27.5" stroke="#A2A5B3" />
                                <g clip-path="url(#clip3_56165_4056)">
                                    <rect x="105" y="0.674805" width="56" height="56" rx="28" fill="white" />
                                    <rect x="105" y="0.674805" width="56" height="56" />
                                </g>
                                <rect x="105.5" y="1.1748" width="55" height="55" rx="27.5" stroke="#A2A5B3" />
                                <defs>
                                    <clipPath id="clip0_56165_4056">
                                        <rect y="0.674805" width="56" height="56" rx="28" fill="white" />
                                    </clipPath>
                                    <clipPath id="clip1_56165_4056">
                                        <rect x="35" y="0.674805" width="56" height="56" rx="28" fill="white" />
                                    </clipPath>
                                    <clipPath id="clip2_56165_4056">
                                        <rect x="70" y="0.674805" width="56" height="56" rx="28" fill="white" />
                                    </clipPath>
                                    <clipPath id="clip3_56165_4056">
                                        <rect x="105" y="0.674805" width="56" height="56" rx="28" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <p class="participants mobile-only">{{ `${currentEvent.event_availiableSpots ||
                                0}/${currentEvent.event_totalSpots || 0} уже участвуют` }}</p>
                        </div>
                    </div>

                    <!-- Описание -->
                    <div style="padding: 1rem; background-color: white; border-radius: 20px; margin-bottom: 1rem;">
                        <h2 class="section-title">Описание</h2>
                        <p class="description">{{ currentEvent.event_description || 'Описание отсутствует' }}</p>
                    </div>

                    <!-- Информация о менторе (мобильная версия) -->
                    <div class="mobile-only">
                        <div class="trainer-info">
                            <div style="display: flex; gap: 10px; align-items: center">
                                <div class="trainer-avatar">
                                    <img :src="mentor.mentor_avatar_url || 'https://via.placeholder.com/150'"
                                        class="mentor_avatar">
                                </div>
                                <div>
                                    <h3 class="trainer-name">{{ (mentor.mentor_name || 'Имя') + ' ' +
                                        (mentor.mentor_surname || 'Фамилия') }}</h3>
                                    <p class="trainer-title">{{ mentor.mentor_tg_title || 'Титул ментора' }}</p>
                                </div>
                            </div>
                        </div>
                        <p class="trainer-description">{{ mentor.mentor_description || 'Описание ментора отсутствует' }}
                        </p>
                    </div>
                </div>

                <!-- Боковая панель (десктопная версия) -->
                <div class="sidebar-right desktop-only">
                    <div class="trainer-card">
                        <div class="trainer-info">
                            <div class="trainer-avatar">
                                <img :src="mentor.mentor_avatar_url || 'https://via.placeholder.com/150'"
                                    class="mentor_avatar">
                            </div>
                            <div>
                                <h3 class="trainer-name">{{ (mentor.mentor_name || 'Имя') + ' ' + (mentor.mentor_surname
                                    || 'Фамилия') }}</h3>
                                <p class="trainer-title">{{ mentor.mentor_tg_title || 'Титул ментора' }}</p>
                            </div>
                        </div>
                        <p class="trainer-description">{{ mentor.mentor_description || 'Описание ментора отсутствует' }}
                        </p>
                        <div class="coffee-image-placeholder">
                            <div style="display: flex; flex-direction: row; justify-content: space-between">
                                <p class="event-price">{{ currentEvent.event_price === 0 ? 'Бесплатно' :
                                    `${currentEvent.event_price} руб.` }}</p>
                                <p class="available-seats">{{ `Свободно ${currentEvent.event_availiableSpots || 0} мест`
                                    }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>

        <!-- Нижний блок для мобильных устройств -->
        <div class="mobile-bottom-block">
            <div class="mobile-bottom-content">
                <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
                    <div class="event-price">{{ currentEvent.event_price === 0 ? 'Бесплатно' :
                        `${currentEvent.event_price} руб.` }}</div>
                    <p class="available-seats">{{ `${currentEvent.event_availiableSpots || 0} мест` }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
    name: 'EventPreview',
    setup() {
        const currentEvent = ref({
            id: 'preview',
            event_title: 'Без названия',
            event_imageUrl: 'https://via.placeholder.com/1920x1080',
            event_date: new Date().toISOString(),
            event_price: 0,
            event_ageRestriction: '18+',
            event_availiableSpots: 20,
            event_totalSpots: 20,
            event_description: 'Описание отсутствует',
            event_id_mentor: '167'
        });

        const mentor = ref({
            mentor_name: 'Имя',
            mentor_surname: 'Фамилия',
            mentor_avatar_url: 'https://via.placeholder.com/150',
            mentor_description: 'Описание ментора для предпросмотра',
            mentor_tg_title: 'Титул ментора'
        });

        function formatDate(dateString: string) {
            if (!dateString) return 'Дата не указана';
            const date = new Date(dateString);
            return date.toLocaleString('ru-RU', { day: 'numeric', month: 'long', hour: '2-digit', minute: '2-digit' });
        }

        onMounted(() => {
            const previewData = localStorage.getItem('previewEvent');
            if (previewData) {
                const data = JSON.parse(previewData);
                currentEvent.value = {
                    id: data.id || 'preview',
                    event_title: data.event_title || 'Без названия',
                    event_imageUrl: data.event_imageUrl || 'https://via.placeholder.com/1920x1080',
                    event_date: data.event_date || new Date().toISOString(),
                    event_price: data.event_price || 0,
                    event_ageRestriction: data.event_ageRestriction || '18+',
                    event_availiableSpots: data.event_availiableSpots || 20,
                    event_totalSpots: data.event_totalSpots || 20,
                    event_description: data.event_description || 'Описание отсутствует',
                    event_id_mentor: data.event_id_mentor || '167'
                };
                mentor.value = data.mentor || {
                    mentor_name: 'Имя',
                    mentor_surname: 'Фамилия',
                    mentor_avatar_url: 'https://via.placeholder.com/150',
                    mentor_description: 'Описание ментора для предпросмотра',
                    mentor_tg_title: 'Титул ментора'
                };
                localStorage.removeItem('previewEvent');
            }
        });

        return {
            currentEvent,
            mentor,
            formatDate
        };
    }
});
</script>

<style scoped>
.event-preview {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.main-content {
    padding: 1rem;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.tag {
    background-color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    font-size: 0.875rem;
    border: 1px solid var(--neutral-neutral500, #A2A5B3);
}

.title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.date {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    color: var(--accent-accent800, rgba(87, 71, 175, 1));
    margin-bottom: 1rem;
}

.event-image {
    width: 60%;
    border-radius: 10px;
    margin-bottom: 1rem;
    aspect-ratio: 3/2;
    object-fit: cover;
}

.event-price {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: var(--neutral-neutral900, #434966);
}

.available-seats {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: #666;
    padding: 4px 12px;
    border: 1px solid var(--neutral-neutral500, #A2A5B3);
    background: var(--neutral-neutral100, #F3F4F5);
    border-radius: 20px;
}

.share-link-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-radius: 10px;
    border: 1px solid var(--neutral-neutral500, #A2A5B3);
    padding: 14px;
    margin-bottom: 1rem;
}

.link-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: var(--neutral-neutral500, #A2A5B3);
}

.participants {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: var(--neutral-neutral900, #434966);
}

.section-title {
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    color: var(--Base-Base-Black, #2A2037);
}

.description {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: #1A1C26;
}

.trainer-info {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    align-items: center;
}

.trainer-avatar {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
}

.mentor_avatar {
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50%;
}

.trainer-name {
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
}

.trainer-title {
    font-size: 0.875rem;
    color: #666;
}

.trainer-description {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
}

.coffee-image-placeholder {
    background: white;
    border-radius: 8px;
    padding: 16px;
    padding-top: 8px;
}

.mobile-only {
    display: block;
}

.desktop-only {
    display: none;
}

@media (min-width: 1024px) {
    .mobile-only {
        display: none;
    }

    .desktop-only {
        display: block;
    }

    .main {
        display: flex;
        gap: 2rem;
    }

    .main-content {
        flex: 2.1;
        padding: 0;
    }

    .title {
        font-size: 2rem;
    }

    .sidebar-right {
        flex: 1;
        position: sticky;
        top: 90px;
        align-self: flex-start;
    }

    .trainer-card {
        padding: 1rem;
        background: #F3F4F5;
        border-radius: 8px;
    }
}

.mobile-bottom-block {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.mobile-bottom-content {
    padding: 0 1rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

@media (max-width: 1023px) {
    .mobile-bottom-block {
        display: block;
    }

    .main-content {
        padding-bottom: 100px;
    }

    .desktop-only {
        display: none;
    }
}
</style>