import { createRouter, createWebHistory } from 'vue-router'

// import AllEvents from '../views/AllEvents.vue';
import AllEvents from '@/components/pages/events/AllEvents.vue'
import AllEventsDiffShow from '../views/AllEventsDiffShow.vue';
import AllMentors from '../views/AllMentors.vue';
import MentorPage from '../views/MentorPage.vue';
import MentorPage2 from '../views/MentorPage2.vue';
import HomePage from '../views/HomePage.vue';
import LandingMenteePage from '../views/LandingMenteePage.vue';
import EventInfo from '../views/EventInfoPage.vue';
import EventPreview from '@/views/EventPreview.vue';
import RegisterPage from '../views/RegisterPage.vue';
import LoginPage from '../views/LoginPage.vue';
import EducationBlockDesktop from '../components/pages/mentor/EducationBlockDesktop.vue';
import TestPage from '../views/TestPage.vue';
import NewTest from '../views/NewTestPage.vue';
import SaluteJazzTest from '../views/SaluteJazzTest.vue';
import CobferencePage from '../views/ConferencePage.vue';
import ConferencePage from '../views/ConferencePage.vue';
import PersonalPage from '../views/PersonalPage.vue';
import PersonalPage2 from '../views/PersonalPage2.vue';
import MeetingPage from '@/views/MeetingPage.vue';
import UploadPage from '../views/UploadPage.vue';
import CalendarPage from '@/views/CalendarPage.vue';
import NewEventsPage from '@/views/NewEventsPage.vue';
import ProfileSettings from '@/views/ProfileSettings.vue';
import CreateEventPage from '@/views/CreateEventPage.vue';
import ChatsPage from '@/views/ChatsPage.vue';
import JumpingDotLoaderWithShadows from '@/components/Animations/JumpingDotLoaderWithShadows.vue';
import LandingLoadingAnimation from '@/components/Animations/LandingLoadingAnimation.vue';
import NotFoundError from '@/pages/errors/NotFoundError.vue';
import ForbiddenError from '@/pages/errors/ForbiddenError.vue';
import UnauthorizedError from '@/pages/errors/UnauthorizedError.vue';
import InternalServerError from '@/pages/errors/InternalServerError.vue';
import CreateEntityPage from '@/views/CreateEntityPage.vue';
import axios from 'axios';
import store from '../../store';

// import TestPage2 from '../views/TestPageTest2.vue';
// import HomeView from './HomeView.vue'
// import AboutView from './AboutView.vue'

const routes = [
    {
        path: '/Home',
        component: HomePage,
        meta: { header: 'Landing' }
    },
    // { path: '/about', component: AboutView },
    {
        path: '/AllEvents', // all allowed 
        component: AllEvents,
        meta: { header: 'Search' }
    },
    {
        path: '/AllEventsDiffShow', // all allowed 
        component: AllEventsDiffShow,
        meta: { header: 'Search' }
    },
    {
        path: '/AllMentors', // all allowed 
        component: AllMentors,
        meta: { header: 'Search' }
    },
    {
        path: '/', // all allowed (убрал requiresAuth, так как комментарий говорит "all allowed")
        component: HomePage,
        meta: { header: 'Landing' }
    },
    {
        path: '/event_info/:id/', // all allowed 
        name: 'EventInfo',
        component: EventInfo,
        meta: { header: 'Search' }
    },
    {
        path: '/event_info/preview',
        name: 'EventPreview',
        component: EventPreview,
        meta: { header: 'Search' }
    },
    {
        path: '/mentor_info/:mentorId', // all allowed 
        name: 'MentorInfo',
        component: MentorPage,
        meta: { header: 'Search' }
    },

    {
        path: '/event-conference/:event_id',
        name: 'EventConference',
        component: MeetingPage,
        meta: {
          requiresAuth: true // Если требуется авторизация
        }
      },
      
      // Маршрут для бронирований
      {
        path: '/booking-conference/:booking_id',
        name: 'BookingConference',
        component: MeetingPage, // Используем тот же компонент
        meta: {
          requiresAuth: true // Если требуется авторизация
        }
      },
      
      // Альтернативный вариант с query параметрами
      {
        path: '/conference',
        name: 'Conference',
        component: MeetingPage,
        meta: {
          requiresAuth: true
        }
        // Здесь параметры передаются через query: /conference?event_id=123 или /conference?booking_id=456
      },
    // {
    //     path: '/mentor_info2/:mentorId', // only admin
    //     name: 'MentorInfo2',
    //     component: MentorPage2,
    //     meta: { requiresAuth: true, requiredRole: 'admin' }
    // },
    {
        path: '/register', // all allowed 
        component: RegisterPage,
        meta: { header: 'Search' }
    },
    {
        path: '/login', // all allowed 
        component: LoginPage,
        meta: { header: 'Search' }
    },
    // {
    //     path: '/TestPage',
    //     component: TestPage,
    // },
    // {
    //     path: '/NewTest',
    //     component: NewTest
    // },
    {
        path: '/ProfileSettings', // only auth allowed 
        component: ProfileSettings,
        meta: { header: 'Search' }
    },

    // TODO:
    {
        path: '/CreateEntityPage', // only auth mentor allowed 
        component: CreateEntityPage,
        meta: { header: 'Search', requiresAuth: true, requiredRole: 'mentor' }
    },
    // {
    //     path: '/NewEventsPage', // only auth allowed 
    //     component: NewEventsPage,
    //     meta: { requiresAuth: true }
    // },
    // {
    //     path: '/SaluteJazzTest',
    //     component: SaluteJazzTest
    // },
    // {
    //     path: '/ConferencePage',
    //     component: ConferencePage,
    //     meta: { requiresAuth: false }
    // },
    // {
    //     path: '/MeetingPage/:id/', // only auth allowed and only if they registered on this meeting
    //     component: MeetingPage,
    //     meta: { requiresAuth: true, requiresRegistration: true }
    // },
    
    // {
    //     path: '/PersonalPage', 
    //     component: PersonalPage,
    //     meta: { header: 'Search' }
    // },
    // {
    //     path: '/PersonalPage2',
    //     component: PersonalPage2
    // },
    {
        path: '/CalendarPage', // all auth allowed but only to part of functionality
        component: CalendarPage,
        meta: { header: 'Search', requiresAuth: true }
    },
    // {
    //     path: '/UpdatePage',
    //     component: UploadPage
    // },
    // {
    //     path: '/CreateEventPage',
    //     component: CreateEventPage,
    //     meta: { header: 'Search' }
    // },
    {
        path: '/ChatsPage', // all auth allowed
        component: ChatsPage,
        meta: { header: 'Search', requiresAuth: true }
    },
    {
        path: '/LandingMenteePage', // all allowed
        component: LandingMenteePage,
        meta: { header: 'Landing' }
        // meta: { header: 'Search', requiresAuth: true }
    },
    {
        path: '/loads', // all allowed
        component: LandingLoadingAnimation
    },
    {
        path: '/:pathMatch(.*)*', // all allowed
        name: 'not-found',
        component: NotFoundError
    },
    {
        path: '/forbidden', // all allowed
        name: 'forbidden',
        component: ForbiddenError
    },
    {
        path: '/unauthorized', // all allowed
        name: 'unauthorized',
        component: UnauthorizedError
    },
    {
        path: '/internal-server-error', // all allowed
        name: 'internal-server-error',
        component: InternalServerError
    },
    {
        path: '/dashboard', // requires auth (исправил комментарий "all allowed" на основе meta)
        component: () => import('../views/DashboardPage.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/login', // all allowed
        component: () => import('../views/LoginPage.vue'),
        meta: { header: 'Search' }
    },
    {
        path: '/meet-page', // all allowed
        component: () => import('../views/MeetPage.vue'),
        meta: { header: 'Search' }
    },
    {
        path: '/meet-page', // all allowed
        component: () => import('../views/MeetPage.vue'),
        meta: { header: 'Search' }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
})

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const requiresRole = to.meta.requiredRole;
    const requiresRegistration = to.meta.requiresRegistration;
    const isAuthenticated = store.getters.isAuthenticated;
    const userRole = store.getters.userRole;

    if (requiresAuth && !isAuthenticated) {
        if (!store.state.userChecked) { // Проверяем, была ли уже попытка загрузки пользователя
            try {
                await store.dispatch('fetchUser');
                store.commit('setUserChecked', true); // Устанавливаем флаг после первой проверки
                if (store.getters.isAuthenticated) {
                    next();
                } else {
                    next('/login');
                }
            } catch (error) {
                next('/login');
            }
        } else {
            next('/login'); // Если проверка уже была и пользователь не авторизован
        }
    } else if (requiresRole && userRole !== requiresRole) {
        next('/forbidden');
    }
    // else if (requiresRegistration) {
    //     const isRegistered = await checkRegistration(to.params.id);
    //     if (isRegistered) {
    //         next();
    //     } else {
    //         next('/forbidden');
    //     }
    // } 
    else if (isAuthenticated && to.path === '/login') {
        next('/');
    } else {
        next();
    }
});

// TODO
// Примерная функция для проверки регистрации на мероприятие
async function checkRegistration(meetingId: string) {
    try {
        const response = await axios.get(`/api/meetings/${meetingId}/is_registered/`);
        return response.data.isRegistered;
    } catch (error) {
        console.error('Ошибка проверки регистрации:', error);
        return false;
    }
}

export default router