<template>
    <div class="events-content">
        <div class="events-list">
            <EventCard v-for="event in events" :key="event.id" :event="event" @toggle-like="toggleLike" />
        </div>
        <aside class="filters-sidebar">
            <div class="search-container">
                <input type="text" placeholder="Поиск" class="search-input" v-model="searchQuery" />
            </div>

            <div class="categories">
                <button v-for="category in categories" :key="category" class="category-tag"
                    :class="{ active: selectedCategories.includes(category) }" @click="toggleCategory(category)">
                    {{ category }}
                </button>
            </div>

            <div class="price-filter">
                <h3>Стоимость</h3>
                <div class="price-inputs">
                    <select v-model="minPrice" class="price-select">
                        <option value="300">300 руб</option>
                        <option value="500">500 руб</option>
                        <option value="1000">1000 руб</option>
                    </select>
                    <select v-model="maxPrice" class="price-select">
                        <option value="500">500 руб</option>
                        <option value="1000">1000 руб</option>
                        <option value="2000">2000 руб</option>
                    </select>
                </div>
            </div>

            <div class="sorting">
                <select v-model="sortingOption" class="sorting-select">
                    <option value="">Без сортировки</option>
                    <option value="price-asc">По возрастанию цены</option>
                    <option value="price-desc">По убыванию цены</option>
                    <option value="popular">По популярности</option>
                </select>
            </div>
        </aside>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed } from 'vue';
// import EventCard from '../components/pages/events/EventCard.vue';
import EventCard from '../components/pages/events/EventDiffCard.vue';

// Инициализируем реф для мероприятий как пустой массив
const events = ref([]);

// Категории фильтрации
const categories = ref([
    'Здоровье',
    'Карьера',
    'Красота',
    'Образование',
    'Просвещение',
    'Хобби'
]);

// Данные для фильтров
const searchQuery = ref('');
const selectedCategories = ref([]);
const minPrice = ref('300');
const maxPrice = ref('500');
const sortingOption = ref('');

// Функция для переключения категории
const toggleCategory = (category) => {
    const index = selectedCategories.value.indexOf(category);
    if (index === -1) {
        selectedCategories.value.push(category);
    } else {
        selectedCategories.value.splice(index, 1);
    }
};

// Функция для переключения лайка
const toggleLike = (eventId) => {
    const event = events.value.find(e => e.id === eventId);
    if (event) {
        event.isLiked = !event.isLiked;
    }
};

// Функция для получения данных мероприятий из API
const fetchEvents = async () => {
    try {
        const response = await fetch('https://platforma34.tw1.ru/api/events/GetEvents/');
        if (!response.ok) {
            throw new Error('Ошибка сети при получении данных мероприятий');
        }
        const data = await response.json();
        
        // Преобразуем данные из API в нужный формат
        events.value = data.map(apiEvent => ({
            id: apiEvent.id,
            event_name: apiEvent.event_title || 'Новое мероприятие',
            event_date: formatDate(apiEvent.event_date),
            mentor_name: 'Новый интересный наставник', // Поскольку API не предоставляет информацию о менторе
            mentor_position: 'Позиция наставника',
            price: `${apiEvent.event_price} ₽`, // Форматируем цену
            likes: 0, // Инициализируем количество лайков
            tags: apiEvent.event_category ? [apiEvent.event_category] : [], // Используем категорию как теги
            image: apiEvent.event_imageUrl || '',
            mentor_image: 'https://via.placeholder.com/150', // Можно заменить на URL аватара наставника, если доступен
            isLiked: false // Инициализируем состояние лайка
        }));
    } catch (error) {
        console.error('Ошибка при загрузке мероприятий:', error);
    }
};

// Вспомогательная функция для форматирования даты
const formatDate = (isoDate) => {
    if (!isoDate) return 'Дата не указана';
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${day}.${month}`;
};

// Вызываем функцию загрузки мероприятий при монтировании компонента
onMounted(() => {
    fetchEvents();
});

// Вычисляемое свойство для фильтрации и сортировки мероприятий
const filteredEvents = computed(() => {
    return events.value
        .filter(event => {
            const matchesSearchQuery = event.event_name.toLowerCase().includes(searchQuery.value.toLowerCase());
            const matchesCategory = selectedCategories.value.length === 0 || event.tags.some(tag => selectedCategories.value.includes(tag));
            const eventPriceNumber = parseInt(event.price.replace(/\s/g, '').replace('₽', '')) || 0;
            const matchesPrice = eventPriceNumber >= parseInt(minPrice.value) && eventPriceNumber <= parseInt(maxPrice.value);
            return matchesSearchQuery && matchesCategory && matchesPrice;
        })
        .sort((a, b) => {
            switch (sortingOption.value) {
                case 'price-asc':
                    return parseInt(a.price) - parseInt(b.price);
                case 'price-desc':
                    return parseInt(b.price) - parseInt(a.price);
                case 'popular':
                    return b.likes - a.likes;
                default:
                    return 0;
            }
        });
});
</script>

<style scoped>
/* .events-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
} */

/* .events-content {
    display: flex;
    gap: 40px;
} */
.events-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px
}

.events-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 20px;
}

.filters-sidebar {
    width: 300px;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 80px;
    height: 350px;
    overflow-y: auto;
}

.search-container {
    margin-bottom: 30px;
}

.search-input {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #D5D7DC;
    border-radius: 8px;
    font-size: 16px;
}

.categories {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 30px;
}

.category-tag {
    padding: 6px 12px;
    border: 1px solid #D5D7DC;
    border-radius: 20px;
    background: none;
    cursor: pointer;
    font-size: 14px;
    color: #434966;
}

.category-tag.active {
    background: #7B65F7;
    color: white;
    border-color: #7B65F7;
}

.price-filter {
    margin-bottom: 30px;
}

.price-filter h3 {
    font-size: 18px;
    color: #2A2037;
    margin-bottom: 15px;
}

.price-inputs {
    display: flex;
    gap: 10px;
}

.price-select,
.sorting-select {
    width: 100%;
    padding: 8px;
    border: 1px solid #D5D7DC;
    border-radius: 8px;
    background: white;
    color: #434966;
}

.sorting {
    margin-top: 20px;
}
</style>
