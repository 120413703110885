<template>
    <v-card class="calendar-card" elevation="1" rounded="lg">
        <div class="calendar-header d-flex align-center justify-space-between px-4 py-3">
            <v-btn icon variant="text" @click="prevMonth" density="comfortable">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="text-h6 font-weight-medium">{{ formattedMonth }}</div>
            <v-btn icon variant="text" @click="nextMonth" density="comfortable">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </div>

        <div class="calendar-days px-4 pb-2">
            <!-- Дни недели -->
            <div class="d-flex justify-space-between mb-2">
                <div v-for="(day, index) in dayNames" :key="'header-' + index" class="day-column text-center">
                    <div class="day-name text-caption text-medium-emphasis">{{ day }}</div>
                </div>
            </div>

            <!-- Сетка календаря -->
            <div class="calendar-grid">
                <div v-for="(week, weekIndex) in calendarDays" :key="'week-' + weekIndex" class="calendar-week">
                    <div v-for="(day, dayIndex) in week" :key="'day-' + weekIndex + '-' + dayIndex" class="calendar-day">
                        <div v-if="day" class="day-number-wrapper" :class="{ 'other-month': !day.currentMonth }">
                            <div class="day-number d-flex align-center justify-center" :class="{
                                'selected-day': isSelectedDay(day.date),
                                'current-day': isCurrentDay(day.date),
                                'other-month': !day.currentMonth
                            }" @click="selectDay(day.date)">
                                {{ day.number }}
                                <div v-if="hasEvent(day.date)" class="event-indicator"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <v-divider></v-divider>

        <div class="events-list px-4 py-3">
            <div>
                <h3 class="text-h6 mb-4">
                    События на {{ formatSelectedDate }}
                </h3>

                <div v-if="selectedDayEvents.length === 0" class="text-body-2 text-medium-emphasis">
                    Нет мероприятий на выбранную дату
                </div>

                <v-list v-else density="compact" class="pa-0 custom-list">
                    <v-list-item v-for="event in selectedDayEvents" :key="event.id" class="px-0 mb-2 custom-list-item"
                        @click="$emit('open-event', event.id)">
                        <template v-slot:prepend>
                            <div class="date-circle">
                                <div class="date-number">
                                    {{ dayOfMonth(event.date) }}
                                </div>
                                <div class="date-weekday">
                                    {{ shortWeekday(event.date) }}
                                </div>
                            </div>
                        </template>

                        <v-list-item-title class="text-subtitle-2">
                            {{ truncatedTitle(event.title) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ formatTime(event.date) }} · {{ event.duration || '1 час' }}
                        </v-list-item-subtitle>
                    </v-list-item>
                </v-list>
            </div>
        </div>
    </v-card>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted, watch, defineProps, defineEmits } from 'vue';
import {
    format,
    addMonths,
    subMonths,
    startOfMonth,
    endOfMonth,
    eachDayOfInterval,
    startOfWeek,
    endOfWeek,
    isSameDay,
    isSameMonth,
    parseISO,
    isToday,
    getDay
} from 'date-fns';
import { ru } from 'date-fns/locale';

const props = defineProps({
    events: {
        type: Array,
        default: () => []
    }
});

const emit = defineEmits(['day-selected', 'open-event']);

// Текущий месяц и выбранный день
const currentMonth = ref(new Date());
const selectedDate = ref(new Date());

// Названия дней недели
const dayNames = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];

// Форматированный месяц для заголовка
const formattedMonth = computed(() => {
    return format(currentMonth.value, 'LLLL yyyy', { locale: ru });
});

// Форматированная выбранная дата
const formatSelectedDate = computed(() => {
    return format(selectedDate.value, 'd MMMM yyyy', { locale: ru });
});

// Генерация сетки календаря
const calendarDays = computed(() => {
    const monthStart = startOfMonth(currentMonth.value);
    const monthEnd = endOfMonth(currentMonth.value);
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

    const days = eachDayOfInterval({ start: startDate, end: endDate });

    // Разбиваем на недели
    const weeks = [];
    let week = [];

    days.forEach((day, index) => {
        week.push({
            date: day,
            number: format(day, 'd'),
            currentMonth: isSameMonth(day, currentMonth.value)
        });

        if ((index + 1) % 7 === 0 || index === days.length - 1) {
            weeks.push(week);
            week = [];
        }
    });

    return weeks;
});

// События на выбранный день
const selectedDayEvents = computed(() => {
    return props.events.filter(event => {
        const eventDate = typeof event.date === 'string' ? parseISO(event.date) : event.date;
        return isSameDay(eventDate, selectedDate.value);
    });
});

// Проверка наличия события на дату
function hasEvent(date) {
    return props.events.some(event => {
        const eventDate = typeof event.date === 'string' ? parseISO(event.date) : event.date;
        return isSameDay(eventDate, date);
    });
}

// Проверка, является ли день выбранным
function isSelectedDay(date) {
    return isSameDay(date, selectedDate.value);
}

// Проверка, является ли день текущим
function isCurrentDay(date) {
    return isToday(date);
}

// Выбор дня
function selectDay(date) {
    selectedDate.value = date;
    emit('day-selected', date);
}

// Навигация по месяцам
function prevMonth() {
    currentMonth.value = subMonths(currentMonth.value, 1);
}

function nextMonth() {
    currentMonth.value = addMonths(currentMonth.value, 1);
}

// Форматирование для отображения событий
function dayOfMonth(dateStr) {
    const date = typeof dateStr === 'string' ? parseISO(dateStr) : dateStr;
    return format(date, 'd');
}

function shortWeekday(dateStr) {
    const date = typeof dateStr === 'string' ? parseISO(dateStr) : dateStr;
    return format(date, 'EE', { locale: ru }).toUpperCase();
}

function formatTime(dateStr) {
    const date = typeof dateStr === 'string' ? parseISO(dateStr) : dateStr;
    return format(date, 'HH:mm');
}

function truncatedTitle(title) {
    return title.length > 40 ? title.slice(0, 40) + '...' : title;
}

// При монтировании компонента, если есть события, выбираем первый день с событием
onMounted(() => {
    if (props.events.length > 0) {
        const firstEventDate = typeof props.events[0].date === 'string'
            ? parseISO(props.events[0].date)
            : props.events[0].date;

        selectedDate.value = firstEventDate;
        currentMonth.value = firstEventDate; // Устанавливаем текущий месяц на месяц первого события
    }
});

// Следим за изменением выбранной даты
watch(selectedDate, (newDate) => {
    // Если выбранная дата не в текущем месяце, обновляем месяц
    if (!isSameMonth(newDate, currentMonth.value)) {
        currentMonth.value = newDate;
    }
});
</script>

<style scoped>
.calendar-card {
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
}

.calendar-days {
    width: 100%;
}

.day-column {
    flex: 1;
    padding: 4px 0;
    min-width: 0;
}

.day-name {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
}

.calendar-grid {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.calendar-week {
    display: flex;
    justify-content: space-between;
}

.calendar-day {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
}

.day-number-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
}

.day-number {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 auto;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.day-number:hover {
    background-color: rgba(112, 92, 226, 0.1);
}

.event-indicator {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 4px;
    background-color: #705CE2;
    border-radius: 50%;
}

.selected-day {
    background-color: #E0DFFF;
    color: #3F2E85;
    font-weight: 600;
}

.current-day {
    font-weight: 600;
}

.other-month {
    opacity: 0.5;
}

.date-circle {
    width: 52px;
    height: 52px;
    background-color: #E0DFFF;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.date-number {
    font-weight: 600;
    color: #3F2E85;
    font-size: 16px;
}

.date-weekday {
    font-size: 12px;
    color: #3F2E85;
}

.events-list {
    max-height: 300px;
    overflow-y: auto;
}

/* Переопределение стилей Vuetify для списка */
:deep(.custom-list .v-list-item) {
    border-radius: 12px !important;
    transition: all 0.3s ease;
    cursor: pointer;
}

:deep(.custom-list .v-list-item:hover) {
    background-color: rgba(0, 0, 0, 0.04);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px !important;
}

/* Принудительно устанавливаем border-radius для элементов списка */
.custom-list-item {
    border-radius: 12px !important;
    overflow: hidden;
}

.custom-list-item::before {
    border-radius: 12px !important;
}

@media (max-width: 600px) {
    .day-number {
        width: 36px;
        height: 36px;
        font-size: 14px;
    }

    .date-circle {
        width: 46px;
        height: 46px;
    }

    .date-number {
        font-size: 14px;
    }

    .date-weekday {
        font-size: 10px;
    }

    .calendar-day {
        min-height: 36px;
    }
}
</style>