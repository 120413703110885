<template>
    <div class="weekdays-grid">
        <v-btn v-for="(dayLabel, dayKey) in weekdays" :key="dayKey" :variant="isSelected(dayKey) ? 'flat' : 'outlined'"
            :color="isSelected(dayKey) ? 'primary' : undefined" size="small" @click="toggleDay(dayKey)"
            class="week-btn">
            {{ dayLabel }}
        </v-btn>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
    modelValue: {
        type: Array,
        default: () => []
    }
});

const emit = defineEmits(['update:modelValue']);

// Ключи для хранения, значения для отображения
const weekdays = {
    mon: 'ПН',
    tue: 'ВТ',
    wed: 'СР',
    thu: 'ЧТ',
    fri: 'ПТ',
    sat: 'СБ',
    sun: 'ВС'
};

const isSelected = (dayKey) => {
    return props.modelValue.includes(dayKey);
};

const toggleDay = (dayKey) => {
    const newValue = [...props.modelValue];
    const index = newValue.indexOf(dayKey);

    if (index === -1) {
        newValue.push(dayKey);
    } else {
        newValue.splice(index, 1);
    }

    emit('update:modelValue', newValue);
};
</script>

<style scoped>
.weekdays-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 8px;
    width: 100%;
    padding-bottom: 1rem;
}

.week-btn {
    width: 100%;
}
</style>