<template>
    <div class="filters-container" :class="{ 'mobile': isMobile }">
      <!-- Desktop filters -->
      <div v-if="!isMobile" class="desktop-filters">
        <div class="search-container">
          <input 
            type="text" 
            class="search-input" 
            placeholder="Поиск мероприятий..." 
            v-model="localSearchQuery"
            @input="updateSearchQuery"
          />
        </div>
        
        <div class="categories-container">
          <button 
            v-for="category in categories" 
            :key="category"
            class="category-button" 
            :class="{ 'active': selectedCategories.includes(category) }"
            @click="$emit('toggle-category', category)"
          >
            {{ category }}
          </button>
        </div>
        
        <div class="price-filters">
          <div class="price-input">
            <label>От</label>
            <input 
              type="number" 
              v-model.number="localMinPrice" 
              @change="updateMinPrice"
              placeholder="0"
            />
          </div>
          
          <div class="price-input">
            <label>До</label>
            <input 
              type="number" 
              v-model.number="localMaxPrice" 
              @change="updateMaxPrice"
              placeholder="∞"
            />
          </div>
        </div>
        
        <div class="sorting-container">
          <select v-model="localSortingOption" @change="updateSortingOption" class="sorting-select">
            <option value="newest">Сначала новые</option>
            <option value="oldest">Сначала старые</option>
            <option value="price-asc">По возрастанию цены</option>
            <option value="price-desc">По убыванию цены</option>
          </select>
        </div>
        
        <button class="reset-button" @click="$emit('reset')">
          Сбросить
        </button>
      </div>
      
      <!-- Mobile filters -->
      <div v-else class="mobile-filters">
        <button class="filter-toggle" @click="isExpanded = !isExpanded">
          Фильтры
        </button>
        
        <div v-if="isExpanded" class="mobile-filters-content">
          <div class="search-container">
            <input 
              type="text" 
              class="search-input" 
              placeholder="Поиск мероприятий..." 
              v-model="localSearchQuery"
              @input="updateSearchQuery"
            />
          </div>
          
          <div class="categories-container">
            <button 
              v-for="category in categories" 
              :key="category"
              class="category-button" 
              :class="{ 'active': selectedCategories.includes(category) }"
              @click="$emit('toggle-category', category)"
            >
              {{ category }}
            </button>
          </div>
          
          <div class="price-filters">
            <div class="price-input">
              <label>От</label>
              <input 
                type="number" 
                v-model.number="localMinPrice" 
                @change="updateMinPrice"
                placeholder="0"
              />
            </div>
            
            <div class="price-input">
              <label>До</label>
              <input 
                type="number" 
                v-model.number="localMaxPrice" 
                @change="updateMaxPrice"
                placeholder="∞"
              />
            </div>
          </div>
          
          <div class="sorting-container">
            <select v-model="localSortingOption" @change="updateSortingOption" class="sorting-select">
              <option value="newest">Сначала новые</option>
              <option value="oldest">Сначала старые</option>
              <option value="price-asc">По возрастанию цены</option>
              <option value="price-desc">По убыванию цены</option>
            </select>
          </div>
          
          <button class="reset-button" @click="$emit('reset')">
            Сбросить
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, defineProps, defineEmits, watch } from 'vue';
  
  const props = defineProps({
    searchQuery: {
      type: String,
      default: ''
    },
    categories: {
      type: Array,
      default: () => []
    },
    selectedCategories: {
      type: Array,
      default: () => ['Все категории']
    },
    minPrice: {
      type: Number,
      default: null
    },
    maxPrice: {
      type: Number,
      default: null
    },
    sortingOption: {
      type: String,
      default: 'newest'
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  });
  
  const emit = defineEmits([
    'update:searchQuery', 
    'toggle-category', 
    'update:minPrice', 
    'update:maxPrice', 
    'update:sortingOption',
    'reset'
  ]);
  
  // Local state
  const localSearchQuery = ref(props.searchQuery);
  const localMinPrice = ref(props.minPrice);
  const localMaxPrice = ref(props.maxPrice);
  const localSortingOption = ref(props.sortingOption);
  const isExpanded = ref(false);
  
  // Update methods with debounce for search
  let searchTimeout;
  const updateSearchQuery = () => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      emit('update:searchQuery', localSearchQuery.value);
    }, 300);
  };
  
  const updateMinPrice = () => {
    emit('update:minPrice', localMinPrice.value);
  };
  
  const updateMaxPrice = () => {
    emit('update:maxPrice', localMaxPrice.value);
  };
  
  const updateSortingOption = () => {
    emit('update:sortingOption', localSortingOption.value);
  };
  
  // Watch for prop changes to update local state
  watch(() => props.searchQuery, (newVal) => {
    localSearchQuery.value = newVal;
  });
  
  watch(() => props.minPrice, (newVal) => {
    localMinPrice.value = newVal;
  });
  
  watch(() => props.maxPrice, (newVal) => {
    localMaxPrice.value = newVal;
  });
  
  watch(() => props.sortingOption, (newVal) => {
    localSortingOption.value = newVal;
  });
  </script>
  
  <style scoped>
  .filters-container {
    margin-bottom: 2rem;
    padding: 0 2vw;
  }
  
  .desktop-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
  }
  
  .search-container {
    flex: 1;
    min-width: 200px;
  }
  
  .search-input {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #D5D7DC;
    border-radius: 8px;
    font-size: 16px;
    background: white;
  }
  
  .categories-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .category-button {
    padding: 8px 16px;
    border: 1px solid #D5D7DC;
    border-radius: 8px;
    background: white;
    color: #434966;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .category-button.active {
    background: #434966;
    color: white;
    border-color: #434966;
  }
  
  .price-filters {
    display: flex;
    gap: 0.5rem;
  }
  
  .price-input {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .price-input input {
    width: 80px;
    padding: 8px;
    border: 1px solid #D5D7DC;
    border-radius: 8px;
    background: white;
  }
  
  .sorting-select {
    padding: 8px;
    height: 100%;
    border: 1px solid #D5D7DC;
    border-radius: 8px;
    background: white;
    color: #434966;
  }
  
  .reset-button {
    padding: 8px 16px;
    border: 1px solid #D5D7DC;
    border-radius: 8px;
    background: white;
    color: #434966;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .reset-button:hover {
    background: #f5f5f5;
  }
  
  /* Mobile styles */
  .mobile-filters {
    width: 100%;
  }
  
  .filter-toggle {
    width: 100%;
    padding: 10px;
    background: #434966;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 1rem;
  }
  
  .mobile-filters-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  </style>