<template>
    <v-card class="event-card" elevation="2">
        <v-img v-if="event.coverImage" :src="event.coverImage" height="160" cover class="event-image">
            <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
            </template>
        </v-img>

        <v-card-title class="text-h6 text-truncate">{{ event.title }}</v-card-title>

        <v-card-text>
            <p class="description">{{ truncatedDescription || 'Описание отсутствует' }}</p>

            <div v-if="event.categories && event.categories.length > 0" class="mb-4">
                <v-chip v-for="category in event.categories" :key="category.value" size="small" color="primary"
                    variant="tonal" class="mr-1">
                    {{ category.title }}
                </v-chip>

                <v-chip v-if="event.isPrivate || !event.isPublic" size="small" color="grey" variant="tonal"
                    class="mr-1">
                    Приватное
                </v-chip>
            </div>

            <div class="event-details">
                <div class="d-flex flex-wrap gap-2">
                    <v-chip size="small" class="info-tag">
                        <template v-slot:prepend>
                            <v-icon size="x-small" class="mr-1">mdi-calendar</v-icon>
                        </template>
                        {{ formattedDate }}
                    </v-chip>

                    <v-chip size="small" class="info-tag">
                        <template v-slot:prepend>
                            <v-icon size="x-small" class="mr-1">mdi-clock-outline</v-icon>
                        </template>
                        {{ formattedTime }}
                    </v-chip>

                    <v-chip size="small" class="info-tag">
                        <template v-slot:prepend>
                            <v-icon size="x-small" class="mr-1">mdi-currency-rub</v-icon>
                        </template>
                        {{ formattedPrice }}
                    </v-chip>

                    <v-chip size="small" class="info-tag">
                        <template v-slot:prepend>
                            <v-icon size="x-small" class="mr-1">mdi-account-group</v-icon>
                        </template>
                        {{ formattedParticipants }}
                    </v-chip>
                </div>
            </div>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" variant="text" @click="confirmDelete">
                Удалить
            </v-btn>
            <v-btn color="primary" variant="outlined" @click="openEditModal">
                Редактировать
            </v-btn>
        </v-card-actions>
        
        <!-- Диалог подтверждения удаления -->
        <v-dialog v-model="deleteDialog" max-width="400px">
            <v-card>
                <v-card-title class="text-h5">Подтверждение удаления</v-card-title>
                <v-card-text>
                    Вы действительно хотите удалить мероприятие "{{ event.title }}"?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey-darken-1" variant="text" @click="deleteDialog = false">Отмена</v-btn>
                    <v-btn color="error" variant="text" @click="deleteEvent">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue';

const props = defineProps({
    event: {
        type: Object,
        required: true,
        default: () => ({
            id: '',
            title: '',
            description: '',
            price: 0,
            discount: 0,
            date: '',
            time: '',
            participantLimit: 0,
            availableSpots: 0,
            categories: [],
            isPublic: true,
            isPrivate: false,
            coverImage: null,
            mentor: null
        })
    }
});

const emit = defineEmits(['edit', 'delete']);

// Добавляем состояние для диалога подтверждения удаления
const deleteDialog = ref(false);

// Форматирование и вычисляемые свойства
const truncatedDescription = computed(() => {
    if (!props.event.description) return '';
    return props.event.description.length > 100
        ? props.event.description.substring(0, 100) + '...'
        : props.event.description;
});

const formattedTime = computed(() => {
    if (!props.event.time) return 'Время не указано';

    // Берем дату и время из props и преобразуем в местное время
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date(`${props.event.date}T${props.event.time}:00`);
    return date.toLocaleTimeString('ru-RU', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: localTimeZone
    });
});

const formattedDate = computed(() => {
    if (!props.event.date) return 'Дата не указана';

    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date(`${props.event.date}T${props.event.time}:00`);
    return date.toLocaleDateString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: localTimeZone
    });
});

const formattedPrice = computed(() => {
    if (props.event.price === 0) return 'Бесплатно';

    // Форматирование цены с учетом скидки
    if (props.event.discount && props.event.discount > 0) {
        const discountedPrice = props.event.price * (1 - props.event.discount / 100);
        return `${discountedPrice} ₽`;
    }

    return `${props.event.price} ₽`;
});

const formattedParticipants = computed(() => {
    const total = props.event.participantLimit;
    const available = props.event.availableSpots !== undefined ? props.event.availableSpots : total;

    if (total === 0) return 'Без ограничений';

    return `${total - available}/${total}`;
});

// Методы
const openEditModal = () => {
    emit('edit', props.event);
};

// Новые методы для удаления
const confirmDelete = () => {
    deleteDialog.value = true;
};

const deleteEvent = () => {
    emit('delete', props.event.id);
    deleteDialog.value = false;
};
</script>

<style scoped>
.event-card {
    transition: transform 0.2s, box-shadow 0.2s;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
}

.rounded-lg {
    border-radius: 20px !important;
}

.event-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.event-image {
    position: relative;
}

.description {
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 16px;
    min-height: 60px;
}

.event-details {
    margin-top: 8px;
    color: #5747af;
}

.info-tag {
    font-size: 12px;
    font-weight: 500;
    background: #DCD8FC;
}

.gap-2 {
    gap: 8px;
}

/* Исправление для предотвращения ошибки ResizeObserver */
.v-card-text {
    overflow: hidden;
}
</style>