import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

import { ref, computed, onMounted } from 'vue';
import { useWindowSize } from '@vueuse/core';
import { format, subMonths, addMonths } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useDate } from 'vuetify';
import TemplateBlock from '@/components/pages/calendar/TemplateBlock.vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import CalendarComponent from '@/components/pages/calendar/CalendarComponent.vue';
import ConsultationModal from '@/components/pages/calendar/ConsultationModal.vue';



interface EventData {
    id: number;
    title: string;
    date: string;
    description?: string;
    status?: 'live' | 'cancelled' | 'done' | 'pending';
    role: 'leading' | 'participating';
    sessionType?: string;
    bookingId?: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CalendarPage',
  setup(__props) {

/* eslint-disable */
const dateAdapter = useDate();
const { width } = useWindowSize();
const isMobile = computed(() => width.value < 960);
const store = useStore();
const router = useRouter();
const isMentor = computed(() => store.getters.userRole === 'mentor');

// const calendarOptions = ref({
//     plugins: [dayGridPlugin],
//     initialView: 'dayGridMonth',
//     weekends: false,
//     events: [
//         { title: 'Meeting', start: new Date() }
//     ]
// })

// Динамические вкладки в зависимости от роли
function getTabs(isMobile: boolean, isMentor: boolean) {
    // Мобильная версия
    if (isMobile) {
        return isMentor
            ? [
                { id: 0, label: 'Веду' },
                { id: 1, label: 'Участвую' },
                { id: 2, label: 'История' },
                { id: 3, label: 'Календарь' },
            ]
            : [
                { id: 0, label: 'Участвую' },
                { id: 1, label: 'История' },
                { id: 2, label: 'Календарь' },
            ];
    }

    // Десктопная версия
    return isMentor
        ? [
            { id: 0, label: 'Веду' },
            { id: 1, label: 'Участвую' },
            { id: 2, label: 'История' },
        ]
        : [
            { id: 0, label: 'Участвую' },
            { id: 1, label: 'История' },
        ];
}

const tabs = computed(() => {
    return getTabs(isMobile.value, isMentor.value);
});


const activeTab = ref(0);

// Фильтры для "Истории"
const filters = [
    { id: 0, label: 'Все' },
    { id: 1, label: 'Проведённые' },
    { id: 2, label: 'Посещённые' },
    { id: 3, label: 'Отменённые' },
];
const selectedFilter = ref(0);

// Интерфейс события
const events = ref<EventData[]>([]);

// Статус и ошибка
const status = ref<'idle' | 'loading' | 'success' | 'error'>('idle');
const errorMessage = ref('');

const selectedDate = ref<Date | null>(new Date());

// Форматированная выбранная дата
const formatSelectedDate = computed(() => {
    if (!selectedDate.value) return '';
    return format(new Date(selectedDate.value), 'd MMMM yyyy', { locale: ru });
});



// Modal state
const consultationModal = ref(false);
const selectedConsultation = ref<EventData | null>(null);

function findEventById(id: number): EventData | undefined {
    return events.value.find((e) => e.id === id);
}

// Даты с событиями для календаря
const eventDates = computed(() => {
    const dates = new Set<string>();
    events.value.forEach(event => {
        const date = new Date(event.date).toISOString().substr(0, 10);
        dates.add(date);
    });
    return Array.from(dates);
});

// Проверка наличия события на дату
function hasEventOnDate(dateVal: string | Date): boolean {
    const dateStr = typeof dateVal === 'string' ? dateVal : dateAdapter.toISO(dateVal).substr(0, 10);
    return eventDates.value.includes(dateStr);
}

async function openEvent(id: number) {
    console.log('open event was requested')
    const event = findEventById(id);
    if (!event) {
        console.error('Event not found:', id);
        return;
    }
    console.log('open event was requested with id ', id)

    if (event.sessionType === 'Event') {
        console.log('EVENT : open event was requested with id ', id)
        router.push(`/event_info/${id}/`);
    } else if (event.sessionType === 'Consultation') {
        console.log('CONSULTATION : open event was requested with id ', id)
        selectedConsultation.value = event;
        consultationModal.value = true;
    }
}

async function joinEvent(id: number) {
    const event = findEventById(id);
    if (!event) {
        console.error('Event not found:', id);
        return;
    }

    if (event.sessionType === 'Event') {
        if (event.status === 'live') {
            router.push(`/event-conference/${id}/`);
        }
    } else if (event.sessionType === 'Consultation') {
        if (event.status === 'live') {
            const bookingId = event.bookingId || event.id; // bookingId for leading, id for participating
            router.push(`/booking-conference/${bookingId}/`);
        }
    }
}

async function handleConsultationConfirmed(event: EventData) {
    console.log('Consultation confirmed:', event);
    // Здесь можно добавить API-вызов для подтверждения, например:
    // await axios.post(`/consultation-bookings/${event.bookingId}/confirm/`);
    await fetchEvents(); // Обновляем события после подтверждения
}


async function leaveEvent(id: number) {
    const event = findEventById(id);
    if (!event) {
        console.error('Event not found:', id);
        return;
    }

    const userId = store.getters.userId;
    if (event.sessionType === 'Event') {
        try {
            await axios.post('/events/unregister/', {
                event_id: event.id,
                user_id: userId,
            });
            fetchEvents(); // Refresh events
        } catch (error) {
            console.error('Failed to unregister from event:', error.response?.data || error.message);
        }
    } else if (event.sessionType === 'Consultation') {
        try {
            await axios.post(`/consultation-bookings/${event.id}/cancel/`, {
                user_id: userId,
            });
            fetchEvents();
        } catch (error) {
            console.error('Failed to cancel consultation booking:', error.response?.data || error.message);
        }
    }
}

function editEvent(id: number) {
    const event = findEventById(id);
    if (event && event.sessionType === 'Event' && event.role === 'leading') {
        router.push(`/edit_event/${id}/`); // Assuming an edit route exists
    }
}

async function cancelEvent(id: number) {
    const event = findEventById(id);
    if (event && event.sessionType === 'Event' && event.role === 'leading') {
        try {
            await axios.post('/events/cancel_event/', { event_id: id });
            fetchEvents();
        } catch (error) {
            console.error('Failed to cancel event:', error);
        }
    }
}

// Загрузка событий с бэкенда
async function fetchEvents() {
    status.value = 'loading';
    errorMessage.value = '';

    try {
        const startDate = format(subMonths(new Date(), 1), 'yyyy-MM-dd');
        const endDate = format(addMonths(new Date(), 1), 'yyyy-MM-dd');

        const response = await axios.get('/calendars/get_user_schedule/', {
            params: { start_date: startDate, end_date: endDate },
        });

        const data = response.data;

        const mentorEvents = (data.mentor_events || []).map(event => ({
            id: event.id,
            title: event.title,
            date: event.date,
            description: event.description,
            status: event.is_canceled ? 'cancelled' : (new Date(event.date) < new Date() ? 'done' : 'live'),
            conferenceStatus: event.conference_status,
            role: 'leading',
            sessionType: 'Event',
        }));

        const registeredEvents = (data.registered_events || []).map(event => ({
            id: event.id,
            title: event.title,
            date: event.date,
            description: event.description,
            status: event.is_canceled ? 'cancelled' : (new Date(event.date) < new Date() ? 'done' : 'live'),
            conferenceStatus: event.conference_status,
            role: 'participating',
            sessionType: 'Event',
        }));

        // const consultationSlots = (data.consultation_slots || []).map(slot => ({
        //     id: slot.id,
        //     title: slot.consultation_type?.title || 'Консультация',
        //     date: slot.start_time,
        //     description: slot.consultation_type?.description,
        //     status: slot.is_booked ? (new Date(slot.start_time) < new Date() ? 'done' : 'live') : 'pending',
        //     conferenceStatus: slot.conference_status,
        //     role: 'leading' as const,
        //     sessionType: 'Consultation',
        //     bookingId: slot.is_booked ? slot.booking_id : undefined,
        //     mentorName: slot.consultation_type?.user?.mentor_name,
        //     mentorSurname: slot.consultation_type?.user?.mentor_surname,
        //     mentorAvatar: slot.consultation_type?.user?.avatar,
        //     duration: slot.duration ? `${slot.duration} минут` : '60 минут',
        //     cost: slot.consultation_type?.cost ? `${slot.consultation_type.cost} руб` : '1500',
        // }));

        const bookedConsultationsByUser = (data.booked_by_user || []).map(booking => {
            const slot = booking.slot || {};
            const cType = slot.consultation_type || {};
            const mentorUser = cType.user || {};
            return {
                id: booking.id,
                title: cType.name || 'Консультация',
                date: slot.start_time,
                description: cType.description || '',
                status: new Date(slot.start_time) < new Date() ? 'done' : 'live',
                conferenceStatus: booking.conference_status,
                role: 'participating' as const,
                sessionType: 'Consultation',
                bookingId: booking.id,
                mentorName: mentorUser.mentor_name || 'Имя',
                mentorSurname: mentorUser.mentor_surname || 'Фамилия',
                mentorAvatar: mentorUser.mentor_avatar_url || '/placeholder.svg?height=56&width=56',
                duration: slot.duration ? `${slot.duration} минут` : '60 минут',
                cost: cType.cost ? `${cType.cost} руб` : '1500 руб',
                question: booking.question || '',
            };
        });

        const bookedConsultationsForMentor = (data.booked_for_mentor || []).map(booking => {
            const slot = booking.slot || {};
            const cType = slot.consultation_type || {};
            const mentorUser = cType.user || {};
            return {
                id: booking.id,
                title: cType.name || 'Консультация',
                date: slot.start_time,
                description: cType.description || '',
                status: new Date(slot.start_time) < new Date() ? 'done' : 'live',
                conferenceStatus: booking.conference_status,
                role: 'leading' as const,
                sessionType: 'Consultation',
                bookingId: booking.id,
                mentorName: mentorUser.mentor_name || 'Имя',
                mentorSurname: mentorUser.mentor_surname || 'Фамилия',
                mentorAvatar: mentorUser.mentor_avatar_url || '/placeholder.svg?height=56&width=56',
                duration: slot.duration ? `${slot.duration} минут` : '60 минут',
                cost: cType.cost ? `${cType.cost} руб` : '1500 руб',
                question: booking.question || '',
            };
        });

        events.value = [...mentorEvents, ...registeredEvents, ...bookedConsultationsByUser, ...bookedConsultationsForMentor];
        console.log(events)
        console.log(events.value)
        status.value = 'success';

        if (!hasEventOnDate(selectedDate.value) && eventDates.value.length > 0) {
            selectedDate.value = eventDates.value.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0];
        }
    } catch (err) {
        status.value = 'error';
        errorMessage.value = err.response?.data?.detail || 'Ошибка загрузки расписания';
        console.error('Fetch events error:', err);
    }
}

// Фильтрация событий для вкладок
function isPast(dateStr: string): boolean {
    return new Date(dateStr).getTime() < Date.now();
}

const leadingEvents = computed(() => {
    return events.value.filter(e => e.role === 'leading' && !isPast(e.date));
});

const participatingEvents = computed(() => {
    return events.value.filter(e => e.role === 'participating' && !isPast(e.date));
});

const historyEvents = computed(() => {
    let filtered = events.value.filter(e => isPast(e.date));
    if (selectedFilter.value === 1) {
        filtered = filtered.filter(e => e.role === 'leading');
    } else if (selectedFilter.value === 2) {
        filtered = filtered.filter(e => e.role === 'participating');
    } else if (selectedFilter.value === 3) {
        filtered = filtered.filter(e => e.status === 'cancelled');
    }
    return filtered;
});

// Жизненный цикл
onMounted(() => {
    fetchEvents();
});

return (_ctx: any,_cache: any) => {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_chip_group = _resolveComponent("v-chip-group")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    fluid: "",
    class: "py-6",
    style: {"padding":"0px"}
  }, {
    default: _withCtx(() => [
      _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "text-h4 mb-6" }, "Календарь", -1)),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "8"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_tabs, {
                modelValue: activeTab.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((activeTab).value = $event)),
                "background-color": "primary",
                dark: "",
                "slider-color": "#7B65F7",
                class: "mb-4"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs.value, (tab) => {
                    return (_openBlock(), _createBlock(_component_v_tab, {
                      key: tab.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(tab.label), 1)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"]),
              (activeTab.value === (isMentor.value ? 2 : 1))
                ? (_openBlock(), _createBlock(_component_v_chip_group, {
                    key: 0,
                    modelValue: selectedFilter.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedFilter).value = $event)),
                    mandatory: "",
                    class: "mb-4"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(filters, (filter) => {
                        return _createVNode(_component_v_chip, {
                          key: filter.id,
                          value: filter.id,
                          variant: "outlined",
                          color: "primary",
                          class: "mr-2"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(filter.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"])
                      }), 64))
                    ]),
                    _: 1
                  }, 8, ["modelValue"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_window, {
                modelValue: activeTab.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((activeTab).value = $event))
              }, {
                default: _withCtx(() => [
                  (isMentor.value)
                    ? (_openBlock(), _createBlock(_component_v_window_item, {
                        key: 0,
                        value: 0
                      }, {
                        default: _withCtx(() => [
                          _createVNode(TemplateBlock, {
                            status: status.value,
                            "error-message": errorMessage.value,
                            "filtered-events": leadingEvents.value,
                            "empty-text": "Нет мероприятий, которые вы ведёте",
                            mode: "leading",
                            onFetchAgain: fetchEvents,
                            onOpenEvent: openEvent,
                            onJoinEvent: joinEvent,
                            onEditEvent: editEvent,
                            onCancelEvent: cancelEvent
                          }, null, 8, ["status", "error-message", "filtered-events"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_window_item, {
                    value: isMentor.value ? 1 : 0
                  }, {
                    default: _withCtx(() => [
                      _createVNode(TemplateBlock, {
                        status: status.value,
                        "error-message": errorMessage.value,
                        "filtered-events": participatingEvents.value,
                        "empty-text": "Нет мероприятий, в которых вы участвуете",
                        mode: "participating",
                        onFetchAgain: fetchEvents,
                        onOpenEvent: openEvent,
                        onJoinEvent: joinEvent,
                        onLeaveEvent: leaveEvent
                      }, null, 8, ["status", "error-message", "filtered-events"])
                    ]),
                    _: 1
                  }, 8, ["value"]),
                  _createVNode(_component_v_window_item, {
                    value: isMentor.value ? 2 : 1
                  }, {
                    default: _withCtx(() => [
                      _createVNode(TemplateBlock, {
                        status: status.value,
                        "error-message": errorMessage.value,
                        "filtered-events": historyEvents.value,
                        "empty-text": "Ваша история пока пуста",
                        mode: "history",
                        onFetchAgain: fetchEvents,
                        onOpenEvent: openEvent,
                        onJoinEvent: joinEvent
                      }, null, 8, ["status", "error-message", "filtered-events"])
                    ]),
                    _: 1
                  }, 8, ["value"]),
                  (isMobile.value)
                    ? (_openBlock(), _createBlock(_component_v_window_item, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(CalendarComponent, {
                            events: events.value,
                            onDaySelected: _ctx.handleDaySelected,
                            onOpenEvent: openEvent
                          }, null, 8, ["events", "onDaySelected"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          (!isMobile.value)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 0,
                cols: "12",
                md: "4"
              }, {
                default: _withCtx(() => [
                  _createVNode(CalendarComponent, {
                    events: events.value,
                    onDaySelected: _ctx.handleDaySelected,
                    onOpenEvent: openEvent
                  }, null, 8, ["events", "onDaySelected"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(ConsultationModal, {
        modelValue: consultationModal.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((consultationModal).value = $event)),
        event: selectedConsultation.value,
        onConfirmed: handleConsultationConfirmed
      }, null, 8, ["modelValue", "event"])
    ]),
    _: 1
  }))
}
}

})