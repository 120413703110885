import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_2 = {
  key: 1,
  class: "live-badge"
}

import { computed } from 'vue';
  
  interface EventData {
    id: number;
    title: string;
    date: string;
    description?: string;
    status?: string;
  }
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'EventDiffCard',
  props: {
    event: {},
    mode: {}
  },
  emits: [
    'openEvent',
    'editEvent',
    'cancelEvent',
    'leaveEvent',
  ],
  setup(__props: any, { emit: __emit }) {

  const props = __props;
  
  const emits = __emit;
  
  // Форматированная дата
  const formattedDate = computed(() => {
    const d = new Date(props.event.date);
    return d.toLocaleString('ru-RU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  });
  
  // Методы для проброса событий наверх
  function emitOpenEvent() {
    emits('openEvent', props.event.id);
  }
  function emitEditEvent() {
    emits('editEvent', props.event.id);
  }
  function emitCancelEvent() {
    emits('cancelEvent', props.event.id);
  }
  function emitLeaveEvent() {
    emits('leaveEvent', props.event.id);
  }
  
return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.event.title), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_subtitle, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(formattedDate.value), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          (_ctx.event.description)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.event.description), 1))
            : _createCommentVNode("", true),
          (_ctx.event.status === 'live')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, " В эфире (прямо сейчас или скоро начнётся) "))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            color: "primary",
            onClick: emitOpenEvent
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Подробнее ")
            ])),
            _: 1
          }),
          (_ctx.mode === 'leading')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_v_btn, {
                  text: "",
                  color: "secondary",
                  onClick: emitEditEvent
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" Редактировать ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  text: "",
                  color: "error",
                  onClick: emitCancelEvent
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Отменить ")
                  ])),
                  _: 1
                })
              ], 64))
            : (_ctx.mode === 'participating')
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 1,
                  text: "",
                  color: "error",
                  onClick: emitLeaveEvent
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Отказаться ")
                  ])),
                  _: 1
                }))
              : (_ctx.mode === 'history')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [], 64))
                : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})