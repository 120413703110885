<template>
    <div class="my-tiptap-editor">
        <!-- Панель инструментов -->
        <transition name="fade-slide" appear>
            <div class="toolbar" v-show="isFocused || isToolbarLocked">
                <!-- Bold -->
                <button type="button" @mousedown.prevent="lockToolbar" @click="toggle('bold')"
                    :class="{ 'is-active': editor?.isActive('bold') }" title="Жирный (Ctrl/Cmd + B)">Ж <span
                        class="hotkey">⌘B</span></button>

                <!-- Italic -->
                <button type="button" @mousedown.prevent="lockToolbar" @click="toggle('italic')"
                    :class="{ 'is-active': editor?.isActive('italic') }" title="Курсив (Ctrl/Cmd + I)">К <span
                        class="hotkey">⌘I</span></button>

                <!-- Underline -->
                <button type="button" @mousedown.prevent="lockToolbar" @click="toggle('underline')"
                    :class="{ 'is-active': editor?.isActive('underline') }" title="Подчеркнутый">П</button>

                <!-- Заголовки -->
                <button type="button" @mousedown.prevent="lockToolbar" @click="toggleHeading(1)"
                    :class="{ 'is-active': editor?.isActive('heading', { level: 1 }) }" title="Заголовок H1">H1</button>
                <button type="button" @mousedown.prevent="lockToolbar" @click="toggleHeading(2)"
                    :class="{ 'is-active': editor?.isActive('heading', { level: 2 }) }" title="Заголовок H2">H2</button>

                <!-- Списки -->
                <button type="button" @mousedown.prevent="lockToolbar" @click="toggle('bulletList')"
                    :class="{ 'is-active': editor?.isActive('bulletList') }" title="Маркированный список">• ●</button>
                <button type="button" @mousedown.prevent="lockToolbar" @click="toggle('orderedList')"
                    :class="{ 'is-active': editor?.isActive('orderedList') }" title="Нумерованный список">1.</button>

                <!-- Highlight -->
                <div class="highlight-toolbar">
                    <span title="Цвет подсветки" aria-label="Цвет маркера">🔍</span>
                    <button v-for="color in highlightColors" :key="color"
                        :style="{ backgroundColor: highlightColorMap[color] }" class="highlight-color-btn"
                        @mousedown.prevent="lockToolbar" @click="applyHighlight(color)"
                        :class="{ active: editor?.isActive('highlight', { class: 'highlight-' + color }) }"
                        :title="'Цвет: ' + colorNameMap[color]" type="button" />
                    <button class="highlight-reset-btn" @mousedown.prevent="lockToolbar" @click="removeHighlight"
                        type="button" title="Убрать выделение">&times;</button>
                </div>
            </div>
        </transition>

        <!-- Редактор + ошибка -->
        <!-- <editor-content :editor="editor" @keydown="handleKeydown" /> -->
        <editor-content :editor="editor" />

        <p v-if="hasTooMuchText" class="error-msg">Превышен лимит в 500 символов</p>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, watch, onMounted, onBeforeUnmount, defineProps, defineEmits } from 'vue'
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Highlight from '@tiptap/extension-highlight'

const props = defineProps({ modelValue: String })
const emit = defineEmits(['update:modelValue'])

const internalContent = ref(props.modelValue || '')
const isFocused = ref(false)
const isToolbarLocked = ref(false)
const hasTooMuchText = ref(false)

const lockToolbar = () => {
    isToolbarLocked.value = true
    setTimeout(() => (isToolbarLocked.value = false), 200)
}

const CustomHighlight = Highlight.extend({
    addAttributes() {
        return {
            class: {
                default: 'highlight-yellow',
                parseHTML: el => el.getAttribute('class'),
                renderHTML: attrs => ({ class: attrs.class }),
            },
        }
    },
})

const highlightColors = ['yellow', 'pink', 'green', 'blue']
const highlightColorMap = {
    yellow: '#fff59d',
    pink: '#f8bbd0',
    green: '#c8e6c9',
    blue: '#bbdefb',
}
const colorNameMap = {
    yellow: 'Жёлтый',
    pink: 'Розовый',
    green: 'Зелёный',
    blue: 'Голубой',
}

const editor = ref(null)

onMounted(() => {
    editor.value = new Editor({
        content: internalContent.value,
        extensions: [
            StarterKit.configure({ heading: { levels: [1, 2] } }),
            Underline,
            CustomHighlight,
        ],
        editorProps: {
            handleKeyDown(view, event) {
                const isCmd = event.metaKey || event.ctrlKey
                if (isCmd && event.key.toLowerCase() === 'b') {
                    event.preventDefault()
                    editor.value.chain().focus().toggleBold().run()
                    return true
                }
                if (isCmd && event.key.toLowerCase() === 'i') {
                    event.preventDefault()
                    editor.value.chain().focus().toggleItalic().run()
                    return true
                }
                if (isCmd && event.key.toLowerCase() === 'u') {
                    event.preventDefault()
                    editor.value.chain().focus().toggleUnderline().run()
                    return true
                }
                return false
            }
        },
        onFocus: () => (isFocused.value = true),
        onBlur: () => setTimeout(() => (isFocused.value = false), 150),
        onUpdate: ({ editor }) => {
            const text = editor.getText()
            hasTooMuchText.value = text.length > 500
            if (!hasTooMuchText.value) {
                internalContent.value = editor.getHTML()
                emit('update:modelValue', internalContent.value)
            }
        },
    })
})

watch(
    () => props.modelValue,
    (newVal) => {
        if (editor.value && newVal !== internalContent.value) {
            internalContent.value = newVal
            editor.value.commands.setContent(newVal || '')
        }
    }
)

onBeforeUnmount(() => {
    editor.value?.destroy()
})

const toggle = (action) => {
    editor.value?.chain().focus()[`toggle${capitalize(action)}`]().run()
}
const toggleHeading = (level) => {
    editor.value?.chain().focus().toggleHeading({ level }).run()
}
const applyHighlight = (color) => {
    editor.value?.chain().focus().setMark('highlight', { class: 'highlight-' + color }).run()
}
const removeHighlight = () => {
    editor.value?.chain().focus().unsetHighlight().run()
}
const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

const handleKeydown = (event) => {
    if (!editor.value) return
    const isCmd = event.metaKey || event.ctrlKey
    if (isCmd && event.key.toLowerCase() === 'b') {
        event.preventDefault()
        toggle('bold')
    }
    if (isCmd && event.key.toLowerCase() === 'i') {
        event.preventDefault()
        toggle('italic')
    }
    if (isCmd && event.key.toLowerCase() === 'u') {
        event.preventDefault()
        toggle('underline')
    }
}
</script>

<style scoped>
.my-tiptap-editor {
    border: 1px solid #ccc;
    border-radius: 6px;
    overflow: hidden;
    background-color: white;
}

.toolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    padding: 8px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #ddd;
    align-items: center;
}

.toolbar button {
    background: none;
    border: 1px solid #bbb;
    border-radius: 4px;
    padding: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s;
}

.toolbar button:hover {
    background-color: #eee;
}

.toolbar button.is-active,
.toolbar button.active {
    background-color: #c8c8c8;
}

.highlight-toolbar {
    display: flex;
    gap: 4px;
    align-items: center;
    margin-left: 10px;
}

.highlight-color-btn {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid #aaa;
    cursor: pointer;
}

.highlight-reset-btn {
    padding: 2px 6px;
    font-size: 14px;
    border: 1px solid #aaa;
    border-radius: 4px;
    background: none;
    cursor: pointer;
}

.my-tiptap-editor :deep(.ProseMirror) {
    padding: 12px;
    min-height: 150px;
    outline: none;
    font-size: 15px;
    line-height: 1.6;
}

.my-tiptap-editor :deep(ol),
.my-tiptap-editor :deep(ul) {
    padding-left: 2em;
    margin: 0 0 1em 0;
    list-style-position: outside;
}

.my-tiptap-editor :deep(ol) {
    list-style-type: decimal;
}

.my-tiptap-editor :deep(ul) {
    list-style-type: disc;
}

.my-tiptap-editor :deep(mark.highlight-yellow) {
    background-color: #fff59d;
}

.my-tiptap-editor :deep(mark.highlight-pink) {
    background-color: #f8bbd0;
}

.my-tiptap-editor :deep(mark.highlight-green) {
    background-color: #c8e6c9;
}

.my-tiptap-editor :deep(mark.highlight-blue) {
    background-color: #bbdefb;
}

.error-msg {
    color: red;
    font-size: 13px;
    padding: 4px 12px;
    margin: 0;
}

.fade-slide-enter-active,
.fade-slide-leave-active {
    transition: all 0.25s ease;
}

.fade-slide-enter-from {
    opacity: 0;
    transform: translateY(-8px);
}

.fade-slide-leave-to {
    opacity: 0;
    transform: translateY(-8px);
}

.hotkey {
    font-size: 10px;
    color: #888;
    margin-left: 4px;
    user-select: none;
}
</style>