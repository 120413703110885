<template>
    <v-container fluid class="fill-height pa-0">
        <!-- Экран загрузки - отображается во время подготовки к конференции -->
        <v-row v-if="currentState !== 'conference'" class="fill-height" justify="center" align="center">
            <v-col cols="12" sm="8" md="6" lg="4">
                <v-card class="mx-auto" elevation="4">
                    <v-card-title class="text-center">
                        {{ contentInfo?.title || 'Подключение к мероприятию' }}
                    </v-card-title>
                    <v-card-text>
                        <div v-if="loading">
                            <v-row align="center" class="mb-4">
                                <v-col cols="auto">
                                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                </v-col>
                                <v-col>
                                    <div>
                                        <div class="text-body-1 font-weight-medium">{{ getStepMessage }}</div>
                                        <v-progress-linear v-model="progressPercentage" height="8" rounded
                                            color="primary" class="mt-2"></v-progress-linear>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                        <div v-else-if="error" class="text-center py-4">
                            <v-icon color="error" size="64" class="mb-4">mdi-alert-circle</v-icon>
                            <div class="text-h6 mb-2">Ошибка</div>
                            <div class="text-body-2 text-medium-emphasis">{{ error }}</div>
                        </div>
                        <div v-else class="text-center py-4">
                            <v-icon color="success" size="64" class="mb-4">mdi-check-circle</v-icon>
                            <div class="text-h6 mb-2">Готово!</div>
                            <div class="text-body-2 text-medium-emphasis">Перенаправление на страницу конференции...
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-4">
                        <v-btn v-if="error" color="primary" @click="startJoinProcess">
                            Попробовать снова
                        </v-btn>
                        <v-btn v-else-if="!loading && currentState === 'completed'" color="primary"
                            @click="enterConference">
                            Перейти к конференции
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <!-- Экран конференции -->
        <template v-else>
            <div class="conference-container d-flex flex-column fill-height">
                <div class="conference-content flex-grow-1 bg-grey-darken-3">
                    <div class="d-flex justify-center align-center fill-height">
                        <v-card v-if="!conferenceInitialized" width="100%" max-width="500"
                            class="bg-grey-darken-4 text-center pa-4">
                            <v-card-title class="text-white">Инициализация конференции...</v-card-title>
                            <v-card-text class="text-white">
                                <v-progress-circular indeterminate color="primary" class="mb-4"></v-progress-circular>
                                <p>Подключение к комнате: {{ conferenceInfo?.login }}</p>
                            </v-card-text>
                        </v-card>
                        <div v-else id="conference-video-container" class="fill-height w-100"></div>
                    </div>
                </div>
                <div class="conference-controls bg-grey-darken-4 pa-4">
                    <v-row justify="center" align="center">
                        <v-col cols="auto">
                            <v-btn :color="micEnabled ? 'primary' : 'error'" icon @click="toggleMic">
                                <v-icon>{{ micEnabled ? 'mdi-microphone' : 'mdi-microphone-off' }}</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn :color="videoEnabled ? 'primary' : 'error'" icon @click="toggleVideo">
                                <v-icon>{{ videoEnabled ? 'mdi-video' : 'mdi-video-off' }}</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn color="error" icon @click="leaveConference">
                                <v-icon>mdi-phone-hangup</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </template>
        <!-- Диалог проверки оборудования -->
        <v-dialog v-model="showEquipmentDialog" persistent max-width="500">
            <v-card>
                <v-card-title>Проверка оборудования</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex align-center mb-4">
                                <v-icon :color="micPermission ? 'success' : 'warning'" class="mr-2">
                                    {{ micPermission ? 'mdi-microphone' : 'mdi-microphone-off' }}
                                </v-icon>
                                <div>
                                    <div class="text-body-1">Микрофон</div>
                                    <div class="text-caption text-medium-emphasis">
                                        {{ micPermission ? 'Доступ разрешен' : 'Доступ не предоставлен' }}
                                    </div>
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn v-if="!micPermission" color="primary" variant="text"
                                    @click="requestMicPermission">
                                    Разрешить
                                </v-btn>
                            </div>
                            <div class="d-flex align-center mb-4">
                                <v-icon :color="cameraPermission ? 'success' : 'warning'" class="mr-2">
                                    {{ cameraPermission ? 'mdi-video' : 'mdi-video-off' }}
                                </v-icon>
                                <div>
                                    <div class="text-body-1">Камера</div>
                                    <div class="text-caption text-medium-emphasis">
                                        {{ cameraPermission ? 'Доступ разрешен' : 'Доступ не предоставлен' }}
                                    </div>
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn v-if="!cameraPermission" color="primary" variant="text"
                                    @click="requestCameraPermission">
                                    Разрешить
                                </v-btn>
                            </div>
                            <div v-if="cameraPermission" class="text-center">
                                <video ref="previewVideo" autoplay muted class="preview-video mb-2"></video>
                                <div class="text-caption text-medium-emphasis">Предпросмотр камеры</div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="completeEquipmentCheck">
                        Продолжить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
/* eslint-disable */
import { ref, computed, onMounted, onBeforeUnmount, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from 'axios'
import { createJazzClient, createJazzWebSdk, JazzClient, createSdkToken } from '@salutejs/jazz-sdk-web'

export default {
    name: 'MeetingPage',
    setup() {
        const route = useRoute()
        const router = useRouter()
        const store = useStore()

        // Состояния и данные
        const currentState = ref('init')
        const loading = ref(true)
        const error = ref(null)
        const contentInfo = ref(null)
        const conferenceInfo = ref(null)
        const userRole = ref(null)
        const progressPercentage = ref(10)
        const showEquipmentDialog = ref(false)
        const micPermission = ref(false)
        const cameraPermission = ref(false)
        const previewVideo = ref(null)
        const micEnabled = ref(true)
        const videoEnabled = ref(true)
        const conferenceInitialized = ref(false)
        const jazzClient = ref(null)

        // Вычисляемые свойства
        const contentType = computed(() => {
            if (route.params.event_id || route.query.event_id) return 'events'
            else if (route.params.booking_id || route.query.booking_id) return 'consultationbooking'
            return null
        })
        const objectId = computed(() => route.params.event_id || route.query.event_id || route.params.booking_id || route.query.booking_id)
        const currentUser = computed(() => store.getters['userId'])
        const getStepMessage = computed(() => {
            switch (currentState.value) {
                case 'init': return 'Инициализация...'
                case 'authorization': return 'Проверка авторизации...'
                case 'content-info': return `Получение информации о ${contentType.value === 'events' ? 'мероприятии' : 'бронировании'}...`
                case 'role-check': return 'Проверка роли пользователя...'
                case 'registration-check': return 'Проверка регистрации...'
                case 'conference-creation': return 'Создание конференции...'
                case 'conference-polling': return 'Ожидание информации о конференции...'
                case 'equipment-check': return 'Проверка оборудования...'
                case 'room-connection': return 'Подключение к комнате...'
                case 'completed': return 'Готово! Перенаправление...'
                default: return 'Загрузка...'
            }
        })

        // Основной процесс подключения
        const startJoinProcess = async () => {
            loading.value = true
            currentState.value = 'init'
            error.value = null
            progressPercentage.value = 10
            try {
                if (!contentType.value || !objectId.value) throw new Error('Не указан ID мероприятия или бронирования')
                await checkAuthorization()
                progressPercentage.value = 20
                await getContentInfo()
                progressPercentage.value = 30
                await checkUserRole()
                progressPercentage.value = 40
                if (userRole.value?.isMentor) {
                    await createConference()
                } else if (userRole.value?.isRegistered) {
                    await checkRegistration()
                    await pollConferenceInfo()
                } else {
                    throw new Error(`Вы не зарегистрированы на это ${contentType.value === 'events' ? 'мероприятие' : 'бронирование'}`)
                }
                progressPercentage.value = 70
                await checkEquipment()
                progressPercentage.value = 90
                await connectToRoom()
                progressPercentage.value = 100
                currentState.value = 'completed'
                loading.value = false
                setTimeout(() => enterConference(), 1000)
            } catch (err) {
                error.value = err.message || 'Произошла ошибка при подключении к мероприятию'
                loading.value = false
                console.error('Ошибка в процессе подключения:', err)
            }
        }

        const checkAuthorization = async () => {
            currentState.value = 'authorization'
            if (!currentUser.value) throw new Error('Необходима авторизация для доступа')
            return true
        }

        const getContentInfo = async () => {
            currentState.value = 'content-info'
            const endpoint = contentType.value === 'events'
                ? `/events/GetEvent/?id=${objectId.value}`
                : `/consultation-bookings/${objectId.value}/detail/?user_id=${currentUser.value}`
            const response = await axios.get(endpoint)
            if (!response.data || !response.data.id) throw new Error(`${contentType.value === 'events' ? 'Мероприятие' : 'Бронирование'} не найдено`)
            contentInfo.value = response.data
            return response.data
        }

        const checkUserRole = async () => {
            currentState.value = 'role-check'
            if (contentType.value === 'events' && contentInfo.value) {
                const isMentor = contentInfo.value.mentor === currentUser.value
                const response = await axios.get(`/events/check_registration/?event_id=${objectId.value}&user_id=${currentUser.value}`)
                const isRegistered = response.data.is_registered
                userRole.value = { isMentor, isRegistered }
                if (!isMentor && !isRegistered) throw new Error('У вас нет доступа к этому мероприятию')
                return userRole.value
            } else if (contentType.value === 'consultationbooking' && contentInfo.value) {
                const isMentor = contentInfo.value.consultation_type_slot?.consultation_type?.user?.id === currentUser.value
                const isRegistered = contentInfo.value.booked_by?.id === currentUser.value
                userRole.value = { isMentor, isRegistered }
                if (!isMentor && !isRegistered) throw new Error('У вас нет доступа к этому бронированию')
                return userRole.value
            }
            throw new Error('Не удалось проверить роль пользователя')
        }

        const checkRegistration = async () => {
            currentState.value = 'registration-check'
            if (userRole.value?.isRegistered) return true
            throw new Error('Ошибка при проверке регистрации')
        }

        const createConference = async () => {
            currentState.value = 'conference-creation'
            try {

                const jazzSdk = await createJazzWebSdk()
                jazzClient.value = await createJazzClient(await jazzSdk, {
                    serverUrl: 'https://salutejazz.ru',
                    // domainUrl: 'https://salutejazz.ru',
                    withCredentials: false
                }).then(
                    console.log("jazzSdk", jazzSdk)
                )
                const sdkToken = await createSdkToken('eyJwcm9qZWN0SWQiOiI3NjUyNjE4Zi1lYzhiLTRkY2EtOWI0Ny1hYzZjNTk3ZGI5NDgiLCJrZXkiOnsia3R5IjoiRUMiLCJkIjoiMVFPUnFWRVd6anJYSEs0bk5XS1VHZDgwRDJSOWMycnBmemhJNlBERENJd2IzSzhpM1hXMC1pTkRWTE95cXBrQiIsInVzZSI6ImVuYyIsImNydiI6IlAtMzg0Iiwia2lkIjoiMTAwNGRmYjktZjYxMS00MmExLWJkM2MtNjIzNGQzMWMwZWVlIiwieCI6InB1VmVncDVMMUN6UlV4U3N2aVFuSDVZLWdJdy0zSW9fY0tQXzljcUNfbDVSM2doWnlJNjdKQ3Q5STQ1UjNlZUQiLCJ5IjoianVrWlVhZ2loU282TmtoMWdvbEJPV0JUNXJIZkFxWTdJaVItaDBZU0hRRUpHSEwxekNHN1lRenl1dFNBeVI0ciJ9fQ==', {
                    iss: 'Logora',
                    userName: "Maxim",
                    // sub: "251",
                });

                console.log(sdkToken);
                console.log("jazzClient", jazzClient);
                console.log("jazzClient.value", jazzClient.value);

                await jazzClient.value.auth.loginBySdkToken(sdkToken.sdkToken);

                console.log("jazzClient auth", jazzClient.authStatus);


                const conference = await jazzClient.value.conferences.createConference({
                    title: 'New one',
                    isGuestEnabled: true,
                });
                
                conferenceInfo.value = {
                    login: conference.roomId,
                    password: conference.password,
                    access_token: transportToken
                }

                await axios.post('/conferences/update_from_salute_jazz/', {
                    content_type: contentType.value,
                    object_id: objectId.value,
                    login: conferenceInfo.value.login,
                    password: conferenceInfo.value.password,
                    status: 'started'
                })

                console.log('Конференция создана:', conferenceInfo.value)
                return conferenceInfo.value
            } catch (err) {
                console.error('Ошибка при создании конференции:', err)
                throw new Error('Ошибка при создании конференции: ' + (err.message || 'неизвестная ошибка'))
            }
        }

        const pollConferenceInfo = async () => {
            currentState.value = 'conference-polling'
            let attempts = 0
            const maxAttempts = 30
            while (attempts < maxAttempts) {
                try {
                    const response = await axios.get('/conferences/get/', {
                        params: { content_type: contentType.value, object_id: objectId.value }
                    })
                    if (response.data && response.data.login && response.data.password) {
                        const tokenResponse = await axios.post('/salute-api/get-access-token/', { userId: currentUser.value })
                        conferenceInfo.value = { ...response.data, access_token: tokenResponse.data.access_token }
                        return conferenceInfo.value
                    }
                } catch (err) {
                    attempts++
                    if (attempts >= maxAttempts) throw new Error('Тайм-аут ожидания информации о конференции')
                    await new Promise(resolve => setTimeout(resolve, 2000))
                }
            }
        }

        const checkEquipment = async () => {
            currentState.value = 'equipment-check'
            showEquipmentDialog.value = true
            return new Promise(resolve => {
                window.completeEquipmentCheck = () => {
                    showEquipmentDialog.value = false
                    resolve(true)
                }
            })
        }

        const requestMicPermission = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
                micPermission.value = true
                stream.getAudioTracks().forEach(track => track.stop())
            } catch (err) {
                console.error('Ошибка при запросе доступа к микрофону:', err)
            }
        }

        const requestCameraPermission = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true })
                cameraPermission.value = true
                await nextTick()
                if (previewVideo.value) previewVideo.value.srcObject = stream
            } catch (err) {
                console.error('Ошибка при запросе доступа к камере:', err)
            }
        }

        const completeEquipmentCheck = () => {
            showEquipmentDialog.value = false
            if (previewVideo.value?.srcObject) {
                previewVideo.value.srcObject.getTracks().forEach(track => track.stop())
                previewVideo.value.srcObject = null
            }
            window.completeEquipmentCheck()
        }

        const connectToRoom = async () => {
            currentState.value = 'room-connection'
            return new Promise(resolve => setTimeout(resolve, 1500))
        }

        const enterConference = () => {
            currentState.value = 'conference'
            setTimeout(initializeConference, 500)
        }

        const initializeConference = async () => {
            try {
                const videoContainer = document.getElementById('conference-video-container')
                if (!jazzClient.value) {
                    const jazzSdk = await createJazzWebSdk()
                    jazzClient.value = await createJazzClient(jazzSdk, {
                        //   serverUrl: 'https://jazz.sber.ru',
                        // serverUrl: '/salute-jazz-api',
                        serverUrl: 'https://salutejazz.ru',
                        //   transportToken: conferenceInfo.value.access_token
                    })
                }

                //   sdkToken = await createSdkToken(conferenceInfo.value.access_token, {
                //     iss: 'Lagora',
                //     userName: currentUser.value,
                //   });
                // sdkToken = await createSdkToken(transportToken);
                console.log("JazzClient auth status", jazzClient.auth.authStatus.get());

                await jazzClient.auth.loginBySdkToken(transportToken);
                console.log("JazzClient auth status", jazzClient.auth.authStatus.get());

                await jazzClient.value.conferences.joinConference({
                    roomId: conferenceInfo.value.login,
                    password: conferenceInfo.value.password,
                    container: videoContainer,
                    audio: { enabled: micEnabled.value },
                    video: { enabled: videoEnabled.value }
                })
                conferenceInitialized.value = true
                console.log('Успешно подключено к конференции')
            } catch (err) {
                error.value = 'Ошибка подключения к конференции: ' + err.message
                console.error('Ошибка инициализации конференции:', err)
            }
        }

        const toggleMic = async () => {
            micEnabled.value = !micEnabled.value
            try {
                if (jazzClient.value) await jazzClient.value.conferences.setLocalAudioEnabled(micEnabled.value)
            } catch (err) {
                console.error('Ошибка переключения микрофона:', err)
            }
        }

        const toggleVideo = async () => {
            videoEnabled.value = !videoEnabled.value
            try {
                if (jazzClient.value) await jazzClient.value.conferences.setLocalVideoEnabled(videoEnabled.value)
            } catch (err) {
                console.error('Ошибка переключения видео:', err)
            }
        }

        const leaveConference = async () => {
            try {
                if (jazzClient.value) await jazzClient.value.conferences.leaveConference()
                router.push('/')
            } catch (err) {
                console.error('Ошибка выхода из конференции:', err)
            }
        }

        onMounted(() => startJoinProcess())
        onBeforeUnmount(() => {
            if (previewVideo.value?.srcObject) {
                previewVideo.value.srcObject.getTracks().forEach(track => track.stop())
            }
            if (jazzClient.value) jazzClient.value.disconnect()
        })

        return {
            currentState,
            loading,
            error,
            contentInfo,
            conferenceInfo,
            userRole,
            progressPercentage,
            getStepMessage,
            startJoinProcess,
            enterConference,
            showEquipmentDialog,
            micPermission,
            cameraPermission,
            previewVideo,
            requestMicPermission,
            requestCameraPermission,
            completeEquipmentCheck,
            micEnabled,
            videoEnabled,
            toggleMic,
            toggleVideo,
            leaveConference,
            conferenceInitialized
        }
    }
}
</script>

<style scoped>
.conference-container {
    height: 100vh;
}

.conference-content {
    flex: 1;
}

.preview-video {
    width: 100%;
    max-height: 200px;
    border-radius: 8px;
    background-color: #000;
}
</style>