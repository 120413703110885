<template>
    <div class="auth-wrapper">
        <v-card class="auth-card" elevation="0">
            <v-fade-transition mode="out-in">
                <!-- Страница регистрации -->
                <div v-if="!showVerification" key="registration" class="auth-content">
                    <h1 class="auth-title">Станьте частью сообщества!</h1>
                    <p class="auth-description">
                        Получите доступ к нашему экспертному сообществу наставников по дизайну, продукту, ИИ, технологий
                        и многим другим во всем мире.
                    </p>

                    <v-tabs v-model="activeTab" color="#7b65f7" grow class="mb-6">
                        <v-tab value="sms">По СМС</v-tab>
                        <v-tab value="email">По почте</v-tab>
                    </v-tabs>

                    <v-window v-model="activeTab" style="padding:10px">
                        <v-window-item value="sms">
                            <v-form @submit.prevent="handleSubmit" ref="smsForm">
                                <v-text-field v-model="phone" :rules="phoneRules" label="Телефон"
                                    prepend-inner-icon="mdi-phone" variant="outlined" class="mb-4"
                                    hide-details="auto"></v-text-field>

                                <v-checkbox v-model="privacyAccepted"
                                    :rules="[(v) => !!v || 'Необходимо принять политику']" color="#7b65f7" class="mb-2"
                                    hide-details>
                                    <template v-slot:label>
                                        <span>Я принимаю </span>
                                        <a href="#" class="privacy-link">политику конфиденциальности</a>
                                    </template>
                                </v-checkbox>

                                <v-checkbox v-model="rememberMe" label="Запомнить меня на этом устройстве"
                                    color="#7b65f7" class="mb-6" hide-details></v-checkbox>

                                <v-btn block color="#7b65f7" size="large" type="submit" class="white--text mb-8"
                                    :loading="loading" height="56">
                                    Далее
                                </v-btn>
                            </v-form>
                        </v-window-item>

                        <v-window-item value="email">
                            <v-form @submit.prevent="handleSubmit" ref="emailForm">
                                <v-text-field v-model="email" :rules="emailRules" label="Почта"
                                    prepend-inner-icon="mdi-email" variant="outlined" class="mb-4" hide-details="auto"
                                    placeholder="sergey@mail.ru"></v-text-field>

                                <v-checkbox v-model="privacyAccepted"
                                    :rules="[(v) => !!v || 'Необходимо принять политику']" color="#7b65f7" class="mb-2"
                                    hide-details>
                                    <template v-slot:label>
                                        <span>Я принимаю </span>
                                        <a href="#" class="privacy-link">политику конфиденциальности</a>
                                    </template>
                                </v-checkbox>

                                <v-checkbox v-model="rememberMe" label="Запомнить меня на этом устройстве"
                                    color="#7b65f7" class="mb-6" hide-details></v-checkbox>

                                <v-btn block color="#7b65f7" size="large" type="submit" class="white--text mb-8"
                                    :loading="loading" height="56">
                                    Далее
                                </v-btn>
                            </v-form>
                        </v-window-item>
                    </v-window>

                    <div class="social-login">
                        <p class="social-login-text">Продолжить с помощью соцсетей</p>
                        <div class="social-buttons">
                            <v-btn v-for="(icon, index) in socialIcons" :key="index" icon variant="outlined"
                                class="social-button">
                                <v-icon>{{ icon.icon }}</v-icon>
                            </v-btn>
                        </div>
                    </div>

                    <div class="login-link">
                        <span>Уже есть аккаунт? </span>
                        <a href="/login" class="login-link-text">Войти</a>
                    </div>
                </div>

                <div v-else key="verification" class="auth-content">
                    <h1 class="auth-title">Введите код из смс</h1>
                    <p class="auth-description">Мы отправили его на {{ phone }}</p>

                    <div class="otp-input-wrapper">
                        <v-otp-input v-model="otpCode" :length="4" variant="underlined"
                            @finish="handleOtpComplete"></v-otp-input>
                    </div>

                    <v-btn block variant="text" :disabled="resendTimer > 0" class="mb-4" @click="resendCode">
                        Отправить код через {{ resendTimer }} сек.
                    </v-btn>

                    <v-btn block variant="text" color="#7b65f7" class="mb-8" @click="showVerification = false">
                        Войти с другим номером
                    </v-btn>

                    <div class="login-link">
                        <span>Уже есть аккаунт? </span>
                        <a href="#" class="login-link-text">Войти</a>
                    </div>
                </div>
            </v-fade-transition>
        </v-card>
    </div>
</template>

<script setup lang="ts">
/* eslint-disable */
import { ref, onMounted, onUnmounted } from 'vue'
import axios from 'axios'
import type { VForm } from 'vuetify/components'
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();

const activeTab = ref('sms')
const phone = ref('')
const email = ref('')
const otpCode = ref('')
const privacyAccepted = ref(false)
const rememberMe = ref(false)
const showVerification = ref(false)
const loading = ref(false)
const resendTimer = ref(30)
const emailForm = ref()
const smsForm = ref()
const router = useRouter();
const phoneRules = [
    (v: string) => !!v || 'Телефон обязателен',
    (v: string) => /^\+7\d{10}$/.test(v) || 'Неверный формат телефона'
]
const emailRules = [
    (v: string) => !!v || 'Email обязателен',
    (v: string) => /.+@.+\..+/.test(v) || 'Неверный формат email'
]

const socialIcons = [
    { icon: 'mdi-vk', color: '#4C75A3' },
    { icon: 'mdi-telegram', color: '#0088cc' },
    { icon: 'mdi-google', color: '#DB4437' }
]

let timerInterval: number | null = null

const handleSubmit = async () => {
    const { valid } = await (activeTab.value === 'sms' ? smsForm.value : emailForm.value)?.validate()

    if (valid) {
        loading.value = true
        try {
            // const response = await axios.post('https://platforma34.tw1.ru/api/users/register/', {
            //     username: activeTab.value === 'sms' ? phone.value : email.value,
            //     password: 'password143',
            //     // email: email.value,
            // })
            await store.dispatch('register', { username: email.value, password: 'password143', email: email.value });

            showVerification.value = true
            startResendTimer()
        } catch (error) {
            console.error(error)
        } finally {
            loading.value = false
        }
    }
}

const handleOtpComplete = async (code: string) => {
    try {
        // await axios.post('/verify-passcode/VerifyMail/', { email: email.value, passcode: code });
        // const response = await axios.post('https://platforma34.tw1.ru/api/verify-passcode/VerifyMail/', {
        //     passcode: code,
        //     email: email.value
        // })
        // console.log('Verification successful:', response.data)
        // console.log("Next Step")
        try {
            await store.dispatch('confirmRegistration', { email: email.value, passcode: code, password: 'password143', username: email.value });
            router.push('/AllMentors');
        } catch (error) {
            console.error('Some other error: ', error)
        }
    } catch (error) {
        console.error('Verification failed:', error)
    }
}

const startResendTimer = () => {
    resendTimer.value = 30
    if (timerInterval) clearInterval(timerInterval)
    timerInterval = setInterval(() => {
        if (resendTimer.value > 0) {
            resendTimer.value--
        } else {
            clearInterval(timerInterval)
        }
    }, 1000)
}

const resendCode = async () => {
    await handleSubmit();
};

</script>


<style scoped>
.auth-wrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background-color: #f5f5f5;
}

.auth-card {
    width: 100%;
    max-width: 480px;
    border-radius: 10px;
    background-color: white;
}

.auth-content {
    padding: 32px;
}

.auth-title {
    font-size: 32px;
    font-weight: bold;
    color: #525a7d;
    text-align: center;
    margin-bottom: 16px;
}

.auth-description {
    font-size: 16px;
    line-height: 1.5;
    color: #525a7d;
    text-align: center;
    margin-bottom: 32px;
}

.privacy-label {
    display: flex;
    gap: 4px;
    align-items: center;
    flex-wrap: wrap;
}

.privacy-link {
    color: #7b65f7;
    text-decoration: none;
    white-space: nowrap;
}

.social-login {
    text-align: center;
    margin-bottom: 32px;
}

.social-login-text {
    font-size: 16px;
    color: #525a7d;
    margin-bottom: 16px;
}

.social-buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
}

.social-button {
    border: 1px solid #e0e0e0 !important;
}

.login-link {
    text-align: center;
    font-size: 16px;
    color: #525a7d;
}

.login-link-text {
    color: #7b65f7;
    text-decoration: none;
}

:deep(.v-field__input) {
    font-size: 16px !important;
    padding: 16px 16px 16px 48px !important;
}

:deep(.v-field__prepend-inner) {
    padding-left: 16px !important;
}

:deep(.v-tabs) {
    margin-bottom: 24px;
}

:deep(.v-tab) {
    text-transform: none;
    font-weight: 500;
    letter-spacing: normal;
}

:deep(.v-btn) {
    text-transform: none;
    font-weight: 500;
    letter-spacing: normal;
}

:deep(.v-checkbox) {
    margin-top: 0;
}

:deep(.v-checkbox .v-selection-control) {
    opacity: 1;
    min-height: unset;
}

:deep(.v-checkbox label) {
    font-size: 14px;
}
</style>
