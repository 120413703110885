<template>
    <div style="max-width: 1200px; margin-right: auto; margin-left: auto;">
        <transition name="fade" @before-leave="beforeLeave" @leave="leave">
            <div v-if="loading" class="loading">
                <JumpingDotLoaderWithShadows />
            </div>
        </transition>

        <div v-if="!loading">
            <div class="event-page">
                <div class="content">
                    <main class="main">
                        <div class="main-content">
                            <div class="tags">
                                <span class="tag" style="background: var(--orange-orange200, #FDE2D3);">18+</span>
                                <span class="tag">Еда</span>
                                <span class="tag">Здоровье</span>
                                <span class="tag">ЗОЖ</span>
                            </div>
                            <h1 class="title">{{ currentEvent.title }}</h1>
                            <p class="date">{{ formattedEventDate }}</p>

                            <img :src="currentEvent.image_url" alt="Event image" class="event-image" />

                            <div class="mobile-only">
                                <div
                                    style="font-size: 18px; font-weight: 500; line-height: 24px; letter-spacing: 0.01em; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: var(--neutral-neutral700, #525A7D); margin-bottom: 10px;">
                                    Отправь ссылку на мероприятие другу
                                </div>
                                <div class="mobile-only">
                                    <div class="share-link-box">
                                        <div class="link-text">
                                            {{ eventShareLink }}
                                        </div>
                                        <svg @click="copyShareLink" width="20" height="21" viewBox="0 0 20 21"
                                            fill="none" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;">
                                            <path
                                                d="M6.66683 6.014V12.6807C6.66683 13.6011 7.41302 14.3473 8.3335 14.3473H13.3335M6.66683 6.014V4.34733C6.66683 3.42686 7.41302 2.68066 8.3335 2.68066H12.155C12.376 2.68066 12.588 2.76846 12.7442 2.92474L16.4228 6.60325C16.579 6.75953 16.6668 6.9715 16.6668 7.19251V12.6807C16.6668 13.6011 15.9206 14.3473 15.0002 14.3473H13.3335M6.66683 6.014H5.3335C4.22893 6.014 3.3335 6.90943 3.3335 8.014V16.014C3.3335 16.9345 4.07969 17.6807 5.00016 17.6807H11.3335C12.4381 17.6807 13.3335 16.7852 13.3335 15.6807V14.3473"
                                                stroke="#A2A5B3" stroke-width="2.04" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    style="display: flex; flex-direction: row; gap: 1rem; align-items: center; margin-bottom: 1rem;">
                                    <svg width="161" height="57" viewBox="0 0 161 57" fill="none"
                                        xmlns="http://www.w3.org/2000/svg" data-v-55449466>
                                        <g clip-path="url(#clip0_56165_4056)" data-v-55449466>
                                            <rect y="0.674805" width="56" height="56" rx="28" fill="white"
                                                data-v-55449466 />
                                            <rect y="0.674805" width="56" height="56" data-v-55449466 />
                                        </g>
                                        <rect x="0.5" y="1.1748" width="55" height="55" rx="27.5" stroke="#A2A5B3"
                                            data-v-55449466 />
                                        <g clip-path="url(#clip1_56165_4056)" data-v-55449466>
                                            <rect x="35" y="0.674805" width="56" height="56" rx="28" fill="white"
                                                data-v-55449466 />
                                            <rect x="35" y="0.674805" width="56" height="56" data-v-55449466 />
                                        </g>
                                        <rect x="35.5" y="1.1748" width="55" height="55" rx="27.5" stroke="#A2A5B3"
                                            data-v-55449466 />
                                        <g clip-path="url(#clip2_56165_4056)" data-v-55449466>
                                            <rect x="70" y="0.674805" width="56" height="56" rx="28" fill="#F3F4F5"
                                                data-v-55449466 />
                                        </g>
                                        <rect x="70.5" y="1.1748" width="55" height="55" rx="27.5" stroke="#A2A5B3"
                                            data-v-55449466 />
                                        <g clip-path="url(#clip3_56165_4056)" data-v-55449466>
                                            <rect x="105" y="0.674805" width="56" height="56" rx="28" fill="white"
                                                data-v-55449466 />
                                            <rect x="105" y="0.674805" width="56" height="56" data-v-55449466 />
                                        </g>
                                        <rect x="105.5" y="1.1748" width="55" height="55" rx="27.5" stroke="#A2A5B3"
                                            data-v-55449466 />
                                        <defs data-v-55449466>
                                            <clipPath id="clip0_56165_4056" data-v-55449466>
                                                <rect y="0.674805" width="56" height="56" rx="28" fill="white"
                                                    data-v-55449466 />
                                            </clipPath>
                                            <clipPath id="clip1_56165_4056" data-v-55449466>
                                                <rect x="35" y="0.674805" width="56" height="56" rx="28" fill="white"
                                                    data-v-55449466 />
                                            </clipPath>
                                            <clipPath id="clip2_56165_4056" data-v-55449466>
                                                <rect x="70" y="0.674805" width="56" height="56" rx="28" fill="white"
                                                    data-v-55449466 />
                                            </clipPath>
                                            <clipPath id="clip3_56165_4056" data-v-55449466>
                                                <rect x="105" y="0.674805" width="56" height="56" rx="28" fill="white"
                                                    data-v-55449466 />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <p class="participants mobile-only">{{ spotsInfo.registeredText }}</p>
                                </div>
                            </div>

                            <div v-if="currentEvent.description && currentEvent.description.trim()"
                                style="padding: 1rem; background-color: white; border-radius: 20px; margin-bottom: 1rem;">
                                <div class="event-description" v-html="sanitizedDescription"></div>
                            </div>

                            <div class="cancellation-policy">
                                <h3 class="policy-title">Правила отмены</h3>
                                <p class="policy-text">
                                    Полный возврат доступен при отмене не позднее чем за 7 дней до начала мастер-класса
                                    или в течение 24 часов с момента бронирования
                                </p>
                            </div>

                            <LinkToMentor :mentorId="mentor.id">
                                <div class="mobile-only">
                                    <div class="trainer-info">
                                        <div style="display: flex; gap: 10px; align-items: center">
                                            <div class="trainer-avatar">
                                                <img :src="mentor.mentor_avatar_url" class="mentor_avatar" />
                                            </div>
                                            <div>
                                                <h3 class="trainer-name">
                                                    {{ mentor.mentor_name + ' ' + mentor.mentor_surname }}
                                                </h3>
                                                <p @click="openLink(mentor.mentor_title)" class="trainer-title">
                                                    {{ mentor.mentor_tg_title }}
                                                </p>
                                            </div>
                                        </div>
                                        <div style="
                          padding: 9px;
                          background: var(--neutral-neutral200, #EBECEE);
                          border-radius: 10px;
                        ">
                                            <svg width="38" height="38" viewBox="0 0 38 38" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8.1111 8.11111C8.1111 6.39289 9.50399 5 11.2222 5H26.7778C28.496 5 29.8889 6.39289 29.8889 8.11111V33L19 27.5556L8.1111 33V8.11111Z"
                                                    stroke="#434966" stroke-width="1.92857" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </LinkToMentor>
                            <p class="trainer-description mobile-only">
                                {{ mentor.mentor_description }}
                            </p>
                        </div>

                        <div class="sidebar-right desktop-only">
                            <div class="trainer-card">
                                <LinkToMentor :mentorId="mentor.id">
                                    <div class="trainer-info" style="justify-content: left">
                                        <div class="trainer-avatar">
                                            <img :src="mentor.mentor_avatar_url" class="mentor_avatar" />
                                        </div>
                                        <div>
                                            <h3 class="trainer-name">
                                                {{ mentor.mentor_name + ' ' + mentor.mentor_surname }}
                                            </h3>
                                            <p @click="openLink(mentor.mentor_title)" class="trainer-title">
                                                {{ mentor.mentor_tg_title }}
                                            </p>
                                        </div>
                                    </div>
                                </LinkToMentor>
                                <p class="trainer-description">
                                    {{ mentor.mentor_description }}
                                </p>
                                <div class="coffee-image-placeholder">
                                    <div style="display: flex; flex-direction: row; justify-content: space-between">
                                        <p class="event-price">{{ priceDisplay }}</p>
                                        <p class="available-seats">{{ spotsInfo.availableText }}</p>
                                    </div>
                                    <div :class="buttonState.class" style="margin-top: 1rem; margin-bottom: 1rem;"
                                        @click="!buttonState.disabled && registerMemberToEvent()">
                                        {{ buttonState.text }}
                                    </div>

                                    <div class="share-link-title">Отправить ссылку на мероприятия другу</div>
                                    <div class="share-link-box">
                                        <div class="link-text">
                                            {{ eventShareLink }}
                                        </div>
                                        <svg @click="copyShareLink" width="20" height="21" viewBox="0 0 20 21"
                                            fill="none" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;">
                                            <path
                                                d="M6.66683 6.014V12.6807C6.66683 13.6011 7.41302 14.3473 8.3335 14.3473H13.3335M6.66683 6.014V4.34733C6.66683 3.42686 7.41302 2.68066 8.3335 2.68066H12.155C12.376 2.68066 12.588 2.76846 12.7442 2.92474L16.4228 6.60325C16.579 6.75953 16.6668 6.9715 16.6668 7.19251V12.6807C16.6668 13.6011 15.9206 14.3473 15.0002 14.3473H13.3335M6.66683 6.014H5.3335C4.22893 6.014 3.3335 6.90943 3.3335 8.014V16.014C3.3335 16.9345 4.07969 17.6807 5.00016 17.6807H11.3335C12.4381 17.6807 13.3335 16.7852 13.3335 15.6807V14.3473"
                                                stroke="#A2A5B3" stroke-width="2.04" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                    <div style="display: flex; flex-direction: row; gap: 10px; align-items: center;">
                                        <svg width="161" height="57" viewBox="0 0 161 57" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_56165_4056)">
                                                <rect y="0.674805" width="56" height="56" rx="28" fill="white" />
                                                <rect y="0.674805" width="56" height="56" />
                                            </g>
                                            <rect x="0.5" y="1.1748" width="55" height="55" rx="27.5"
                                                stroke="#A2A5B3" />
                                            <g clip-path="url(#clip1_56165_4056)">
                                                <rect x="35" y="0.674805" width="56" height="56" rx="28" fill="white" />
                                                <rect x="35" y="0.674805" width="56" height="56" />
                                            </g>
                                            <rect x="35.5" y="1.1748" width="55" height="55" rx="27.5"
                                                stroke="#A2A5B3" />
                                            <g clip-path="url(#clip2_56165_4056)">
                                                <rect x="70" y="0.674805" width="56" height="56" rx="28"
                                                    fill="#F3F4F5" />
                                            </g>
                                            <rect x="70.5" y="1.1748" width="55" height="55" rx="27.5"
                                                stroke="#A2A5B3" />
                                            <g clip-path="url(#clip3_56165_4056)">
                                                <rect x="105" y="0.674805" width="56" height="56" rx="28"
                                                    fill="white" />
                                                <rect x="105" y="0.674805" width="56" height="56" />
                                            </g>
                                            <rect x="105.5" y="1.1748" width="55" height="55" rx="27.5"
                                                stroke="#A2A5B3" />
                                            <defs>
                                                <clipPath id="clip0_56165_4056">
                                                    <rect y="0.674805" width="56" height="56" rx="28" fill="white" />
                                                </clipPath>
                                                <clipPath id="clip1_56165_4056">
                                                    <rect x="35" y="0.674805" width="56" height="56" rx="28"
                                                        fill="white" />
                                                </clipPath>
                                                <clipPath id="clip2_56165_4056">
                                                    <rect x="70" y="0.674805" width="56" height="56" rx="28"
                                                        fill="white" />
                                                </clipPath>
                                                <clipPath id="clip3_56165_4056">
                                                    <rect x="105" y="0.674805" width="56" height="56" rx="28"
                                                        fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <p class="participants">{{ spotsInfo.registeredText }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>

            <div v-if="reviews.length > 0" class="reviews disable">
                <ReviewBlock instance="event" :id="currentEvent.id" :reviews="reviews" />
            </div>

            <div class="disable">
                <div v-if="events.length > 0" class="events-section-title disable">Мероприятия</div>
                <div class="masonry-container">
                    <div class="masonry-column" v-for="n in colNum" :key="n">
                        <div v-for="(event, index) in events.filter((_, i) => i % colNum === n - 1)" :key="index"
                            style="margin-bottom: 2rem;">
                            <link-to-event :id="event.id">
                                <EventCardVue :event_title="event.event_title" :event_date="event.event_date"
                                    :event_price="event.event_price" :event_imageUrl="event.event_imageUrl"
                                    :event_ageRestriction="event.event_ageRestriction"
                                    :event_availiableSpots="event.event_availiableSpots"
                                    :event_totalSpots="event.event_totalSpots" :event_category="event.event_category"
                                    :id="event.id" :event_id_mentor="event.event_id_mentor" />
                            </link-to-event>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mobile-bottom-block">
                <div class="mobile-bottom-content">
                    <div
                        style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
                        <div class="event-price">{{ priceDisplay }}</div>
                        <div :class="buttonState.class" style="margin-top: 1rem; margin-bottom: 1rem;"
                            @click="!buttonState.disabled && registerMemberToEvent()">
                            {{ buttonState.text }}
                        </div>
                        <p class="available-seats">{{ spotsInfo.availableText }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent, onMounted, ref, watch, onBeforeUnmount, Ref, computed } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import moment from 'moment';
import DOMPurify from 'dompurify';

import ReviewBlock from '../components/Review/ReviewBlock.vue';
import EventCardVue from '../components/EventCard.vue';
import LinkToMentor from '../components/Links/LinkToMentor.vue';
import JumpingDotLoaderWithShadows from '@/components/Animations/JumpingDotLoaderWithShadows.vue';
import { getEventRegistrationsApi, checkRegistrationApi, registerForEventApi, unregisterFromEventApi } from '@/services/api.js';

// Интерфейс для события
interface Event {
    id: number;
    title: string;
    description: string;
    mentor: number;
    date: string;
    price: number;
    total_spots: number;
    image_url: string;
    is_whitelisted: boolean;
    is_public: boolean;
    is_canceled: boolean;
    created_at: string;
    updated_at: string;
    event_id_mentor?: number; // Совместимость с вашим кодом
    registered_count?: number;
}

// Интерфейс для ментора (на основе модели Users)
interface Mentor {
    id: number;
    mentor_name: string;
    mentor_surname: string;
    mentor_description: string;
    mentor_avatar_url: string;
    mentor_title: string;
    mentor_tg_title: string;
}

// Интерфейс для регистрации на мероприятие
interface EventRegistration {
    id: number;
    event: number;
    user: number;
    created_at: string;
}

export default defineComponent({
    name: 'EventPage',
    components: {
        ReviewBlock,
        EventCardVue,
        LinkToMentor,
        JumpingDotLoaderWithShadows
    },
    setup() {
        const route = useRoute();
        const store = useStore();

        const mentor: Ref<Mentor> = ref({} as Mentor);
        const loading = ref(true);
        const colNum = ref(3);
        const events = ref<Event[]>([]);
        const currentEvent: Ref<Event> = ref({} as Event);
        const reviews = ref<any[]>([]); // Если есть структура для отзывов, добавьте интерфейс
        const memberId = ref<number | null>(null);
        const canParticipate = ref(false);
        const isRegistered = ref(false);
        const registrations = ref<EventRegistration[]>([]);
        const copySuccess = ref(false);

        // Очищенный HTML
        const sanitizedDescription = computed(() => {
            return DOMPurify.sanitize(currentEvent.value.description || '');
        });

        // Форматированная дата мероприятия
        const formattedEventDate = computed(() => {
            if (!currentEvent.value.date) return '';

            // Настройка локализации для русского языка
            moment.locale('ru');

            // Форматирование даты в нужный формат (12 июля 2024 16:30)
            return moment(currentEvent.value.date).format('D MMMM YYYY HH:mm');
        });

        // Отображение цены
        const priceDisplay = computed(() => {
            if (!currentEvent.value.price || currentEvent.value.price === 0) {
                return 'Бесплатно';
            }
            return `${currentEvent.value.price} ₽`;
        });

        // Ссылка для шаринга
        const eventShareLink = computed(() => {
            return `https://platforma34.tw1.ru/event_info/${currentEvent.value.id}`;
        });

        // Add this after the sanitizedDescription computed property
        const buttonState = computed(() => {
            const userId = store.getters.userId;
            const eventCreatorId = currentEvent.value.mentor || currentEvent.value.event_id_mentor;
            const totalSpots = currentEvent.value.total_spots || 0;
            const registeredCount = registrations.value.length;
            const spotsLeft = totalSpots - registeredCount;

            // Check if event was created by current user
            if (userId && eventCreatorId && userId === eventCreatorId) {
                return { text: 'Ваше мероприятие', disabled: true, class: 'participate-button-creator' };
            }

            // Check if user is not authorized
            if (!userId) {
                return { text: 'Войдите для участия', disabled: false, class: 'participate-button-login' };
            }

            // Check if no spots left
            if (spotsLeft <= 0) {
                return { text: 'Мест нет', disabled: true, class: 'participate-button-no-spots' };
            }

            // Check if user is already registered
            if (isRegistered.value) {
                return { text: 'Вы зарегистрированы', disabled: true, class: 'participate-button-registered' };
            }

            // Default state - can participate
            return { text: 'Участвовать', disabled: false, class: 'participate-button' };
        });

        // Add computed property for displaying available spots info
        const spotsInfo = computed(() => {
            const totalSpots = currentEvent.value.total_spots || 0;
            const registeredCount = registrations.value.length;
            const spotsLeft = totalSpots - registeredCount;

            return {
                registeredText: `${registeredCount}/${totalSpots} уже участвуют`,
                availableText: `Свободно ${spotsLeft} мест`
            };
        });

        // Копирование ссылки в буфер обмена
        const copyShareLink = () => {
            if (navigator && navigator.clipboard) {
                navigator.clipboard.writeText(eventShareLink.value)
                    .then(() => {
                        copySuccess.value = true;
                        // Можно добавить уведомление о успешном копировании
                        setTimeout(() => {
                            copySuccess.value = false;
                        }, 2000);
                    })
                    .catch(err => {
                        console.error('Ошибка при копировании ссылки:', err);
                        // Запасной вариант для старых браузеров
                        fallbackCopyTextToClipboard(eventShareLink.value);
                    });
            } else {
                // Запасной вариант для браузеров без поддержки clipboard API
                fallbackCopyTextToClipboard(eventShareLink.value);
            }
        };

        // Запасной метод копирования для старых браузеров
        const fallbackCopyTextToClipboard = (text: string) => {
            const textArea = document.createElement("textarea");
            textArea.value = text;

            // Делаем элемент невидимым
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    copySuccess.value = true;
                    setTimeout(() => {
                        copySuccess.value = false;
                    }, 2000);
                }
            } catch (err) {
                console.error('Ошибка при копировании текста:', err);
            }

            document.body.removeChild(textArea);
        };

        // Регистрация
        async function registerMemberToEvent() {
            const userId = store.getters.userId as number | undefined;
            const eventId = Number(route.params.id);

            // Если пользователь не авторизован, перенаправляем на страницу логина
            if (!userId) {
                console.log('Перенаправление на страницу логина');
                window.location.href = '/login';
                return;
            }

            try {
                await registerForEventApi(eventId, userId);
                console.log('Регистрация успешна');
                await updateRegistrationStatus();
                await fetchEventRegistrations(eventId);
            } catch (error) {
                console.error('Ошибка при регистрации:', error);
            }
        }

        // Отмена регистрации
        async function unregisterFromEvent() {
            const userId = store.getters.userId as number | undefined;
            const eventId = Number(route.params.id);

            if (!userId) {
                console.error('Пользователь не авторизован');
                return;
            }

            try {
                await unregisterFromEventApi(eventId, userId);
                console.log('Отмена регистрации успешна');
                await updateRegistrationStatus();
                await fetchEventRegistrations(eventId);
            } catch (error) {
                console.error('Ошибка при отмене регистрации:', error);
            }
        }

        // Загрузка данных о мероприятии
        async function fetchEvent() {
            try {
                const response = await axios.get(`https://platforma34.tw1.ru/api/events/GetEvent/?id=${route.params.id}`);
                currentEvent.value = response.data;
            } catch (error) {
                console.error('Ошибка при загрузке события:', error);
            } finally {
                await fetchEventRegistrations(Number(route.params.id));
                await fetchMentor();
            }
        }

        // Загрузка данных о менторе
        async function fetchMentor() {
            loading.value = true;
            try {
                const mentorId = currentEvent.value.event_id_mentor || currentEvent.value.mentor;
                const response = await axios.get(`https://platforma34.tw1.ru/api/users/GetUser/?id=${mentorId}`);
                mentor.value = response.data;
            } catch (error) {
                console.error('Ошибка при загрузке данных ментора:', error);
            } finally {
                setTimeout(() => {
                    loading.value = false;
                }, 500);
            }
        }

        // Загрузка отзывов
        async function fetchReviews() {
            try {
                const response = await axios.get(`https://platforma34.tw1.ru/api/event_reviews/GetEventReviews/?event_id=${route.params.id}`);
                reviews.value = response.data;
            } catch (error) {
                console.error('Ошибка при загрузке отзывов:', error);
            }
        }

        // Получение ID пользователя из Vuex
        async function getIdFromToken() {
            try {
                memberId.value = store.getters.userId;
            } catch (error) {
                console.error('Ошибка при получении ID из токена:', error);
            }
        }

        // Обновление статуса регистрации
        async function updateRegistrationStatus() {
            const userId = store.getters.userId as number | undefined;
            const eventId = Number(route.params.id);

            if (!userId || !eventId) {
                canParticipate.value = false;
                isRegistered.value = false;
                return;
            }

            try {
                const registered = await checkRegistrationApi(eventId, userId);
                const isMentor = currentEvent.value.event_id_mentor === userId || currentEvent.value.mentor === userId;
                isRegistered.value = registered;
                canParticipate.value = !registered && !isMentor;
            } catch (error) {
                console.error('Ошибка при проверке регистрации:', error);
                isRegistered.value = false;
                canParticipate.value = false;
            }
        }

        // Получение регистраций для мероприятия
        async function fetchEventRegistrations(eventId : number) {
            try {
                const response = await getEventRegistrationsApi(eventId);
                registrations.value = response;
            } catch (error) {
                console.error('Ошибка при получении регистраций:', error);
                registrations.value = [];
            }
        }

        // При изменении размера окна
        const handleResize = () => {
            colNum.value = window.innerWidth < 1024 ? 2 : 3;
        };

        // При монтировании
        onMounted(async () => {
            handleResize();
            await fetchEvent();
            await fetchReviews();
            await getIdFromToken();
            await updateRegistrationStatus();
            window.addEventListener('resize', handleResize);

            // Настройка локализации для русского языка
            moment.locale('ru');
        });

        // При размонтировании
        onBeforeUnmount(() => {
            window.removeEventListener('resize', handleResize);
        });

        // Следим за изменением
        watch([currentEvent, memberId], async () => {
            await updateRegistrationStatus();
        });

        // Add buttonState and spotsInfo to the return statement
        return {
            registerMemberToEvent,
            unregisterFromEvent,
            currentEvent,
            events,
            memberId,
            canParticipate,
            isRegistered,
            reviews,
            mentor,
            loading,
            colNum,
            sanitizedDescription,
            buttonState,
            spotsInfo,
            formattedEventDate,
            priceDisplay,
            eventShareLink,
            copyShareLink,
        };
    }
});
</script>

<style scoped>
.event-page {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 0.5s;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

/* Списки и заголовки в описании */
.description h1,
.description h2 {
    font-weight: 400;
    line-height: normal;
    /* уберите вовсе, если не нужно */
    margin: 1em 0;
}

.description ol,
.description ul {
    margin: 1em 0;
    padding-left: 1.5em;
    list-style-position: outside;
}

.main-content {
    padding: 1rem;
}

/* Остальные стили остаются без изменений ниже... */

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.tag {
    background-color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    font-size: 0.875rem;
    border: 1px solid var(--neutral-neutral500, #A2A5B3)
}

.title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.date {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--accent-accent800, rgba(87, 71, 175, 1));
    margin-bottom: 1rem;
}

.event-image {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 1rem;
    aspect-ratio: 16/9;
    object-fit: cover;
}

.participation-info {
    margin-bottom: 1rem;
}

.event-price {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--neutral-neutral900, #434966);
}

.available-seats {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #666;
    padding: 4px 12px;
    border: 1px solid var(--neutral-neutral500, #A2A5B3);
    background: var(--neutral-neutral100, #F3F4F5);
    border-radius: 20px;
}

.participate-button {
    background-color: #6553ca;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 8px;
    cursor: pointer;
    min-width: 30%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.participate-button-registered {
    background-color: #7c7a84;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    min-width: 30%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.participate-button:hover {
    background-color: #454152;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    cursor: pointer;
}

.share-link-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--neutral-neutral700, #525A7D);
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
}

.share-link-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-radius: 10px;
    border: 1px solid var(--neutral-neutral500, #A2A5B3);
    padding: 14px;
    margin-bottom: 1rem;
}

.link-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--neutral-neutral500, #A2A5B3);
}

.participants {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 1rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--neutral-neutral900, #434966);
}

.section-title {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--Base-Base-Black, #2A2037);
}

.topic-list {
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1A1C26;
}

.description {
    margin-bottom: 1.5rem;
    font-size: 20px;
    font-weight: 400;
    /* text-align: left; */
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1A1C26;
}

.event-description {
    font-size: 20px;
    font-weight: 400;
    color: #1A1C26;
    padding-left: 1rem;
}

::v-deep(.event-description h1) {
    font-weight: 400;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-size: 32px;
    line-height: 0.8;
    color: #2A2037;
}

::v-deep(.event-description h2) {
    font-weight: 400;
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
    font-size: 28px;
    line-height: 1;
    color: #2A2037;
}

::v-deep(.event-description p) {
    margin-bottom: 1rem;
    font-size: 20px;
    font-weight: 400;
    line-height: 0.8;
    color: #1A1C26;
}

::v-deep(.event-description ol),
::v-deep(.event-description ul) {
    margin: 1rem 0 1.5rem 1.5rem;
    padding-left: 1.8rem;
    font-size: 20px;
    font-weight: 400;
    line-height: 0.8;
    color: #1A1C26;
}

::v-deep(.event-description ol li),
::v-deep(.event-description ul li) {
    margin-bottom: 0.5rem;
}

::v-deep(.event-description mark.highlight-yellow) {
    background-color: #fff59d;
}

::v-deep(.event-description mark.highlight-pink) {
    background-color: #f8bbd0;
}

::v-deep(.event-description mark.highlight-green) {
    background-color: #c8e6c9;
}

::v-deep(.event-description mark.highlight-blue) {
    background-color: #bbdefb;
}


/* можно добавить для остальных цветов, если нужно */


.cancellation-policy {
    background-color: white;
    padding: 1rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    background: var(--accent-accent50, rgba(250, 247, 253, 1));
}

.policy-title {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--accent-accent800, rgba(87, 71, 175, 1));
}

.policy-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--accent-accent800, rgba(87, 71, 175, 1));
}

.trainer-info {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: space-between;
}

.trainer-avatar {
    width: 4rem;
    height: 4rem;
    background-color: #ccc;
    border-radius: 50%;
}

.mentor_avatar {
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50%;
}

.trainer-name {
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
}

.trainer-title {
    font-size: 0.875rem;
    color: #666;
}

.trainer-description {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 1rem;
}

.coffee-image-placeholder {
    background: var(--neutral-neutral100, white);
    border-radius: 8px;
    margin-bottom: 1rem;
    padding: 16px;
    padding-top: 8px;
}

.mobile-only {
    display: block;
}

.desktop-only {
    display: none;
}

@media (min-width: 1024px) {
    .mobile-only {
        display: none;
    }

    .desktop-only {
        display: block;
    }

    .content {
        display: flex;
    }

    .main {
        display: flex;
        gap: 2rem;
    }

    .main-content {
        flex: 2.1;
        padding: 0;
        margin-left: 0px;
    }

    .title {
        font-size: 2rem;
    }

    .sidebar-right {
        flex: 1;
        position: sticky;
        top: 90px;
        align-self: flex-start;
        overflow-y: auto;
    }

    .trainer-card {
        padding: 1rem;
        border-radius: 8px;
        background: #F3F4F5;
    }
}

.events-section-title {
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1A1C26;
    padding-bottom: 40px;
}

.masonry-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2vw;
    margin-left: 2vw;
    margin-right: 2vw;
}

.masonry-column {
    flex: 0 0 calc(33.33% - 2vw);
    max-width: calc(33.33% - 2vw);
}

@media (max-width: 1024px) {
    .masonry-container {
        gap: 4vw;
        margin-left: 1vw;
        margin-right: 1vw;
    }

    .masonry-column {
        flex: 0 0 calc(50% - 2vw);
        max-width: calc(50% - 2vw);
    }

    .masonry-container>.masonry-column:nth-child(3) {
        display: none;
    }
}

.mobile-bottom-block {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.mobile-bottom-content {
    padding: 0 1rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

@media (max-width: 1023px) {
    .mobile-bottom-block {
        display: block;
    }

    .main-content {
        padding-bottom: 100px;
    }

    .desktop-only {
        display: none;
    }
}

.participate-button-creator {
    background-color: #8a8a8a;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 8px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    cursor: not-allowed;
}

.participate-button-login {
    background-color: #6553ca;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 8px;
    cursor: pointer;
    min-width: 30%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.participate-button-no-spots {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 8px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    cursor: not-allowed;
}

.participate-button-login:hover {
    background-color: #454152;
}
</style>
