<template>
    <!-- <div v-if="isLoading == true" style="weight: bold;">New Message</div> -->

    <div class="personal-consultations-container">
        <div v-for="(consultation, index) in consultations" :key="index">
            <PersonalConsultation :consultation="consultation" @book="onBookConsultation" />
        </div>
    </div>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent, ref, onMounted, PropType } from 'vue';
import PersonalConsultation from './PersonalConsultation.vue'; // Путь к компоненте


interface Consultation {
    name: string;
    duration_minutes: number;
  }

export default defineComponent({
    name: 'PersonalConsultationsList',
    props: {
        consultations: {
            type: Array as PropType<Consultation[]>,
            required: true,
        }
  
        // consultations: {
        //     type: [Object] as [PropType<Consultation>],
        //     required: true,
        // }
    },
    components: {
        PersonalConsultation
    },
    setup(props, { emit }: { emit: (event: string, ...args: any[]) => void }) {
        const isLoading = ref(true)

        // Используем emit для передачи данных родителю для открытия модалки
        const onBookConsultation = (consultation: Consultation) => {
            console.log('Консультация выбрана:', consultation);
            emit('openModal', consultation); // передаем консультацию родителю
        };

        onMounted(async () => {
            isLoading.value = true
            // console.log("Loading status: ", isLoading.value)
            // try {
            //     const responseConsultations = await axios.get('https://platforma34.tw1.ru/api/consultation-types/all?user_id=167');
            //     consultations.value = await responseConsultations.data;
            // } catch (e) {
            //     console.error(e);
            // } finally {
            //     setTimeout(() => {
            //         isLoading.value = false
            //         console.log("Loading status: ", isLoading.value)
            //     }, 500);
            // }


        })

        return {
            // consultations,
            onBookConsultation
        };
    },


});
</script>

<style scoped>
.personal-consultations-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    max-width: 1200px;
}

@media (max-width: 1024px) {
    .personal-consultations-container {
        display: grid;
        width: 100%;
        gap: 20px;
        grid-template-columns: 1fr;
    }
}
</style>
