<template>
    <v-card>
      <v-card-title>{{ event.title }}</v-card-title>
      <v-card-subtitle>
        {{ formattedDate }}
      </v-card-subtitle>
  
      <v-card-text>
        <!-- Краткое описание события, если есть -->
        <div v-if="event.description" class="mb-2">
          {{ event.description }}
        </div>
  
        <!-- Пример: если статус === 'live', можно показывать бейдж "В эфире" -->
        <div v-if="event.status === 'live'" class="live-badge">
          В эфире (прямо сейчас или скоро начнётся)
        </div>
      </v-card-text>
  
      <v-card-actions>
        <!-- Кнопка "Подробнее" универсальна -->
        <v-btn color="primary" @click="emitOpenEvent">
          Подробнее
        </v-btn>
  
        <!-- Если пользователь — ведущий (mode = "leading"), показываем кнопки "Редактировать" и "Отменить" -->
        <template v-if="mode === 'leading'">
          <v-btn text color="secondary" @click="emitEditEvent">
            Редактировать
          </v-btn>
          <v-btn text color="error" @click="emitCancelEvent">
            Отменить
          </v-btn>
        </template>
  
        <!-- Если пользователь — участник (mode = "participating"), 
             показываем кнопку "Отказаться" (покинуть) -->
        <template v-else-if="mode === 'participating'">
          <v-btn text color="error" @click="emitLeaveEvent">
            Отказаться
          </v-btn>
        </template>
  
        <!-- Если событие в истории (mode = "history"), можем вообще не показывать кнопки 
             или показать что-то вроде "Повторить" -->
        <template v-else-if="mode === 'history'">
          <!-- Пример: только кнопка просмотра. Или что-то ещё -->
        </template>
      </v-card-actions>
    </v-card>
  </template>
  
  <script lang="ts" setup>
  import { computed, defineProps, defineEmits } from 'vue';
  
  interface EventData {
    id: number;
    title: string;
    date: string;
    description?: string;
    status?: string;
  }
  
  const props = defineProps<{
    event: EventData;
    // leading / participating / history
    mode: string;
  }>();
  
  const emits = defineEmits([
    'openEvent',
    'editEvent',
    'cancelEvent',
    'leaveEvent',
  ]);
  
  // Форматированная дата
  const formattedDate = computed(() => {
    const d = new Date(props.event.date);
    return d.toLocaleString('ru-RU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  });
  
  // Методы для проброса событий наверх
  function emitOpenEvent() {
    emits('openEvent', props.event.id);
  }
  function emitEditEvent() {
    emits('editEvent', props.event.id);
  }
  function emitCancelEvent() {
    emits('cancelEvent', props.event.id);
  }
  function emitLeaveEvent() {
    emits('leaveEvent', props.event.id);
  }
  </script>
  
  <style scoped>
  .live-badge {
    display: inline-block;
    padding: 4px 8px;
    background: #7C3AED; /* или ваш primary цвет */
    color: white;
    border-radius: 12px;
    font-size: 12px;
  }
  .mb-2 {
    margin-bottom: 8px;
  }
  </style>
  