<template>
    <div class="personal-consultation">
        <div class="personal-consultation-text">{{ consultation.name }}</div>
        <div class="personal-consultation-second-text">{{ consultation.duration_minutes }} минут</div>
        <!-- <div class="personal-consultation-second-text">{{ (new Date(consultation.end_time) - new Date(consultation.start_time)) / 1000 / 60  }} минут</div> -->
        <div class="personal-consultation-btn" @click="onBookConsultation">
            <div class="personal-consultation-btn-text">Записаться</div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

interface Consultation {
    name: string;
    duration_minutes: number;
}

export default defineComponent({
    name: 'PersonalConsultation',
    props: {
        consultation: {
            type: Object as PropType<Consultation>,
            required: true,
        }
    },
    emits: ['book'], // определяем событие 'book'
    setup(props, { emit }) { // Теперь используем emit из контекста
        const onBookConsultation = () => {
            // Используем emit для передачи данных в родительский компонент
            emit('book', props.consultation);
        };

        return {
            onBookConsultation
        };
    }
});
</script>

<style scoped>
.personal-consultation {
    padding: 2vw;
    background-color: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.personal-consultation-text {
    font-size: 24px;
    font-weight: 500;
    color: #2A2037;
}

.personal-consultation-second-text {
    font-size: 18px;
    font-weight: 400;
    color: #434966;
    margin-bottom: 1rem;
}

.personal-consultation-btn {
    padding: 10px 20px;
    background: #705CE2;
    color: #FFFFFF;
    border-radius: 8px;
    cursor: pointer;
    width: 130px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    transition: background 0.3s ease;
}

.personal-consultation-btn:hover {
    background: #5b49c9;
}
</style>