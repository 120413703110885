<template>
    <teleport to="body">
        <transition name="modal">
            <div v-if="isOpen" class="modal-overlay" @click="handleOverlayClick">
                <div class="modal-content" @click.stop>
                    <button @click="closeModal" class="close-btn">&times;</button>
                    <div class="modal-body">
                        <slot />
                    </div>
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent, watch, PropType } from 'vue';

export default defineComponent({
    name: 'BaseModal',
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
        closeOnOverlayClick: {
            type: Boolean,
            default: true
        }
    },
    emits: ['close'],
    setup(props, { emit }) {
        // Следим за открытием/закрытием модалки,
        // чтобы дисаблить/возвращать скролл на боди
        watch(
            () => props.isOpen,
            (newVal) => {
                if (newVal) {
                    document.body.style.overflow = 'hidden';
                } else {
                    document.body.style.overflow = '';
                }
            }
        );

        // Метод закрытия модалки
        const closeModal = () => {
            emit('close'); // Родительский компонент решит, что делать
        };

        // Если разрешено закрывать по клику на оверлей
        const handleOverlayClick = () => {
            if (props.closeOnOverlayClick) {
                closeModal();
            }
        };

        return {
            closeModal,
            handleOverlayClick
        };
    }
});
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    background-color: white;
    padding: 24px;
    border-radius: 12px;
    max-width: 900px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
}

.modal-body {
    margin-top: 40px;
    /* чтобы кнопка закрытия не перекрывала контент */
}

/* Анимация появления/исчезания */
.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
    opacity: 0;
}
</style>