<template>
    <div>
      <transition name="fade" @before-leave="beforeLeave" @leave="leave">
        <div v-if="loading" class="loading">
          <JumpingDotLoaderWithShadows />
        </div>
      </transition>
      <div v-if="!loading" class="mentors-content">
        <MobileFilters
          v-if="isMobile"
          :searchQuery="searchQuery"
          :categories="categories"
          :selectedCategories="selectedCategories"
          :minPrice="minPrice"
          :maxPrice="maxPrice"
          :sortingOption="sortingOption"
          :mentorSearchMobileWidth="mentorSearchMobileWidth"
          :isExpanded="isExpanded"
          :showIcon="showIcon"
          :hideIcon="hideIcon"
          :contentStyle="contentStyle"
          @update:searchQuery="searchQuery = $event"
          @toggle-category="toggleCategory"
          @update:minPrice="minPrice = $event"
          @update:maxPrice="maxPrice = $event"
          @update:sortingOption="sortingOption = $event"
          @toggle-expand="toggleExpand"
          ref="mobileFiltersRef"
        />
        <div class="mentors-list">
          <component
            :is="componentType"
            v-for="mentor in mentors"
            :key="mentor.id"
            :mentor="mentor"
            @toggle-like="toggleLike"
          />
        </div>
        <SidebarFilters
          v-if="!isMobile"
          :searchQuery="searchQuery"
          :categories="categories"
          :selectedCategories="selectedCategories"
          :minPrice="minPrice"
          :maxPrice="maxPrice"
          :sortingOption="sortingOption"
          @update:searchQuery="searchQuery = $event"
          @toggle-category="toggleCategory"
          @update:minPrice="minPrice = $event"
          @update:maxPrice="maxPrice = $event"
          @update:sortingOption="sortingOption = $event"
        />
        
      </div>
    </div>
  </template>
  
  <script lang="ts">
  /* eslint-disable */
  import { ref, computed, onMounted, watch, nextTick, defineComponent, onBeforeUnmount } from 'vue';
  import MentorCard from '../components/pages/mentors/MentorCard.vue';
  import MentorCardMobile from '../components/pages/mentors/MentorCardMobile.vue';
  import JumpingDotLoaderWithShadows from '@/components/Animations/JumpingDotLoaderWithShadows.vue';
  import { useWindowSize } from '@vueuse/core';
  import MobileFilters from '@/components/filters/MobileFilters.vue';
  import SidebarFilters from '@/components/filters/SidebarFilters.vue';
  
  export default defineComponent({
    name: 'AllMentors',
    components: {
      MentorCard,
      MentorCardMobile,
      JumpingDotLoaderWithShadows,
      MobileFilters,
      SidebarFilters
    },
    setup() {
      interface ApiMentor {
        id: number;
        mentor_name: string;
        mentor_surname: string;
        mentor_title: string;
        mentor_tg_title: string;
        mentor_description: string;
        mentor_skills: string[];
        mentor_avatar_url: string;
      }
  
      interface Mentor {
        id: number;
        name: string;
        title: string;
        tg_title: string;
        description: string;
        price: string;
        likes: number;
        tags: string[];
        image: string;
        isLiked: boolean;
      }
  
      // Реактивные свойства
      const mentors = ref<Mentor[]>([]);
      const categories = ref<string[]>([
        'Здоровье',
        'Карьера',
        'Красота',
        'Образование',
        'Просвещение',
        'Хобби'
      ]);
      const searchQuery = ref<string>('');
      const selectedCategories = ref<string[]>([]);
      const minPrice = ref<string>('300');
      const maxPrice = ref<string>('500');
      const sortingOption = ref<string>('');
      const loading = ref<boolean>(true);
  
      const showIcon = `
        <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.77854 10L10.3341 1.44444L18.8896 10" stroke="#A2A5B3" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `;
  
      const hideIcon = `
        <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.8885 2L10.3329 10.5556L1.77734 2" stroke="#A2A5B3" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `;
  
      // Рефы для доступа к компонентам фильтров
      const mobileFiltersRef = ref<any>(null);
  
      const { width } = useWindowSize();
      const isMobile = computed<boolean>(() => width.value < 768);
  
      const componentType = computed(() => (isMobile.value ? MentorCardMobile : MentorCard));
  
      const mentorSearchMobileWidth = computed<string>(() => `${width.value - 100}px`);
  
      const isExpanded = ref<boolean>(false);
  
      const toggleExpand = () => {
        isExpanded.value = !isExpanded.value;
      };
  
      const contentHeight = ref<number>(0);
      const initialContentHeight = ref<number>(0);
  
      const contentStyle = computed<Record<string, string>>(() => ({
        maxHeight: isExpanded.value ? `${contentHeight.value}px` : `${initialContentHeight.value}px`,
        overflow: 'hidden',
        transition: 'max-height 0.5s ease-in-out',
        marginTop: '20px'
      }));
  
      // Функция для переключения категории
      const toggleCategory = (category: string): void => {
        const index = selectedCategories.value.indexOf(category);
        if (index === -1) {
          selectedCategories.value.push(category);
        } else {
          selectedCategories.value.splice(index, 1);
        }
      };
  
      // Функция для переключения лайка
      const toggleLike = (mentorId: number): void => {
        const mentor = mentors.value.find((m) => m.id === mentorId);
        if (mentor) {
          mentor.isLiked = !mentor.isLiked;
        }
      };
  
      // Функция для получения данных менторов из API
      const fetchMentors = async (): Promise<void> => {
        try {
          const response = await fetch('https://platforma34.tw1.ru/api/users/GetUsers/');
          if (!response.ok) {
            throw new Error('Ошибка сети при получении данных менторов');
          }
          const data: ApiMentor[] = await response.json();
  
          // Преобразуем данные из API в нужный формат
          mentors.value = data.map((apiMentor: ApiMentor) => ({
            id: apiMentor.id,
            name: `${apiMentor.mentor_name} ${apiMentor.mentor_surname}`,
            title: apiMentor.mentor_title,
            tg_title: apiMentor.mentor_tg_title,
            description: apiMentor.mentor_description,
            price: '11 000 ₽', // Замените на реальное значение, если доступно
            likes: Math.floor(Math.random() * 1000),
            tags: apiMentor.mentor_skills || [],
            image: apiMentor.mentor_avatar_url,
            isLiked: false
          }));
        } catch (error) {
          console.error('Ошибка при загрузке менторов:', error);
        } finally {
          setTimeout(() => {
            loading.value = false;
          }, 500);
        }
      };

  
      // Функция для обновления высоты контента
      const updateContentHeight = (): void => {
        console.log("updateContentHeight was executed");
        nextTick(() => {
          if (isMobile.value && mobileFiltersRef.value) {
            const { contentRef, searchRef, showMoreButtonRef } = mobileFiltersRef.value;
  
            if (contentRef && searchRef && showMoreButtonRef) {
              console.log("updateContentHeight was executed and contentRef.value exists.");
              contentHeight.value = contentRef.scrollHeight;
              console.log("contentRef.value : ", !!contentRef);
              console.log("contentHeight.value is ", contentHeight.value);
  
              // Рассчитываем начальную высоту контента на основе высоты поиска и кнопки
              const searchHeight = searchRef.clientHeight;
              const showMoreHeight = showMoreButtonRef.clientHeight;
              initialContentHeight.value = 0;
              console.log("initialContentHeight.value is ", initialContentHeight.value);
            }
          } else if (!isMobile.value) {
            // Для десктопа, можно добавить логику, если необходимо
            initialContentHeight.value = 0;
          }
        });
      };
  
      // Наблюдатель за изменением ширины окна
      watch(width, () => {
        updateContentHeight();
      });
  
      // Инициализация при монтировании
      onMounted(() => {
        fetchMentors();
        updateContentHeight();
        window.addEventListener('resize', updateContentHeight);
      });
  
      // Очистка при размонтировании
      onBeforeUnmount(() => {
        window.removeEventListener('resize', updateContentHeight);
      });
  
      return {
        contentHeight,
        initialContentHeight,
        mobileFiltersRef,
        mentors,
        categories,
        searchQuery,
        selectedCategories,
        minPrice,
        maxPrice,
        sortingOption,
        loading,
        showIcon,
        hideIcon,
        toggleCategory,
        toggleLike,
        isMobile,
        componentType,
        mentorSearchMobileWidth,
        isExpanded,
        toggleExpand,
        contentStyle,
      };
    }
  });
  </script>
  
  <style scoped>
  .loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 0.5s;
  }
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  
  .mentors-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 40px;
  }
  
  .mentors-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: end;
  }
  
  @media (max-width: 768px) {
    .mentors-content {
      display: block;
    }
  }
  </style>
  