import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mentor-info" }
const _hoisted_2 = { class: "mentor-details" }
const _hoisted_3 = { class: "mentor-name" }
const _hoisted_4 = { class: "session-info" }
const _hoisted_5 = { class: "info-grid" }
const _hoisted_6 = { class: "info-item" }
const _hoisted_7 = { class: "value" }
const _hoisted_8 = { class: "info-item" }
const _hoisted_9 = { class: "value" }
const _hoisted_10 = { class: "info-item" }
const _hoisted_11 = { class: "value" }
const _hoisted_12 = { class: "booking-details mb-6" }
const _hoisted_13 = { class: "detail-item" }
const _hoisted_14 = { class: "detail-item" }
const _hoisted_15 = { class: "form-section" }
const _hoisted_16 = {
  key: 0,
  class: "form-textarea question-card"
}
const _hoisted_17 = {
  key: 1,
  class: "form-textarea question-card"
}

import { ref, computed } from 'vue';
import { format, parseISO, addMinutes } from 'date-fns';
import { ru } from 'date-fns/locale';

interface EventData {
    id: number;
    title: string;
    date: string;
    description?: string;
    status?: 'live' | 'cancelled' | 'done' | 'pending';
    role: 'leading' | 'participating';
    sessionType?: string;
    bookingId?: number;
    mentorName?: string;
    mentorSurname?: string;
    mentorAvatar?: string;
    duration?: string;
    cost?: string;
    question?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ConsultationModal',
  props: {
    modelValue: { type: Boolean },
    event: {}
  },
  emits: ["update:modelValue", "confirmed"],
  setup(__props: any, { emit: __emit }) {

/* eslint-disable */
const props = __props;

const emit = __emit;

const dialog = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
});

const loading = ref(false);

const mentorName = computed(() => props.event?.mentorName || 'Имя');
const mentorSurname = computed(() => props.event?.mentorSurname || 'Фамилия');
const mentorAvatar = computed(() => props.event?.mentorAvatar || '/placeholder.svg?height=56&width=56');
const title = computed(() => props.event?.title || 'Сессия наставничества');
const duration = computed(() => props.event?.duration || '60 минут');
const cost = computed(() => props.event?.cost || '1500 руб');
const question = computed(() => props.event?.question || '');

const formattedDate = computed(() => {
    if (!props.event?.date) return '';
    const date = typeof props.event.date === 'string' ? parseISO(props.event.date) : props.event.date;
    return format(date, 'EEEE, dd.MM', { locale: ru });
});

const formattedTime = computed(() => {
    if (!props.event?.date) return '';
    const date = typeof props.event.date === 'string' ? parseISO(props.event.date) : props.event.date;
    const durationMinutes = parseInt(duration.value) || 60;
    const endTime = addMinutes(date, durationMinutes);
    return `${format(date, 'HH:mm')} - ${format(endTime, 'HH:mm')}`;
});

function close() {
    dialog.value = false;
}

async function confirm() {
    if (!props.event) return;
    loading.value = true;
    try {
        // Имитация асинхронного запроса (замените на реальный API-вызов)
        await new Promise((resolve) => setTimeout(resolve, 1000));
        emit('confirmed', props.event);
        close();
    } catch (error) {
        console.error('Ошибка при подтверждении:', error);
    } finally {
        loading.value = false;
    }
}

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: dialog.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dialog).value = $event)),
    "max-width": "900",
    "border-radius": "12px",
    "content-class": "consultation-modal",
    onKeydown: _withKeys(close, ["esc"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "consultation-modal-card" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "d-flex justify-space-between align-center pa-6" }, {
            default: _withCtx(() => [
              _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "modal-title" }, "Запись на консультацию", -1)),
              _createVNode(_component_v_btn, {
                icon: "",
                variant: "text",
                onClick: close,
                "aria-label": "Закрыть",
                class: "close-btn"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("mdi-close")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_card_text, { class: "pa-6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "5"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_v_avatar, {
                          size: "64",
                          class: "mentor-avatar mr-4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_img, {
                              src: mentorAvatar.value,
                              alt: "Аватар ментора"
                            }, null, 8, ["src"])
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("div", _hoisted_3, _toDisplayString(mentorName.value) + " " + _toDisplayString(mentorSurname.value), 1),
                          _cache[3] || (_cache[3] = _createElementVNode("p", null, "Ментор", -1))
                        ])
                      ]),
                      _createVNode(_component_v_divider, { class: "mb-6" }),
                      _createElementVNode("div", _hoisted_4, [
                        _cache[7] || (_cache[7] = _createElementVNode("h3", null, "Личная сессия", -1)),
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "label" }, "Длительность", -1)),
                            _createElementVNode("span", _hoisted_7, _toDisplayString(duration.value), 1)
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "label" }, "Тема", -1)),
                            _createElementVNode("span", _hoisted_9, _toDisplayString(title.value), 1)
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "label" }, "Стоимость", -1)),
                            _createElementVNode("span", _hoisted_11, _toDisplayString(cost.value), 1)
                          ])
                        ])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "7"
                  }, {
                    default: _withCtx(() => [
                      _cache[11] || (_cache[11] = _createElementVNode("h3", { class: "confirmation-title" }, "Детали записи", -1)),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _cache[8] || (_cache[8] = _createElementVNode("i", { class: "calendar-icon" }, null, -1)),
                          _createElementVNode("span", null, _toDisplayString(formattedDate.value), 1)
                        ]),
                        _createElementVNode("div", _hoisted_14, [
                          _cache[9] || (_cache[9] = _createElementVNode("i", { class: "clock-icon" }, null, -1)),
                          _createElementVNode("span", null, _toDisplayString(formattedTime.value), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _cache[10] || (_cache[10] = _createElementVNode("h3", { class: "text-h6 mb-2" }, "Вопрос к ментору:", -1)),
                        (question.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(question.value), 1))
                          : (_openBlock(), _createElementBlock("div", _hoisted_17, " Сопроводительный вопрос отсутствует. "))
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})