<template>
    <div class="notification-wrapper">
        <v-menu v-if="isAuthenticated" offset-y="5" :close-on-content-click="false" transition="fade-transition"
            :transition-duration="300">
            <template #activator="{ props }">
                <v-btn icon class="notification-icon" v-bind="props" elevation="0">
                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M25.6666 28.1111H31.7777L30.0606 26.394C29.5949 25.9283 29.3333 25.2967 29.3333 24.6381V20.7778C29.3333 17.5848 27.2926 14.8684 24.4444 13.8617V13.4444C24.4444 12.0944 23.35 11 21.9999 11C20.6499 11 19.5555 12.0944 19.5555 13.4444V13.8617C16.7073 14.8684 14.6666 17.5848 14.6666 20.7778V24.6381C14.6666 25.2967 14.405 25.9283 13.9393 26.394L12.2222 28.1111H18.3333M25.6666 28.1111V29.3333C25.6666 31.3584 24.025 33 21.9999 33C19.9749 33 18.3333 31.3584 18.3333 29.3333V28.1111M25.6666 28.1111H18.3333"
                            stroke="#525A7D" stroke-width="2.04545" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <v-badge v-if="notificationCount > 0" :content="notificationCount > 9 ? '9+' : notificationCount"
                        color="success" offset-x="10" offset-y="-10" />
                </v-btn>
            </template>
            <v-card class="notification-card"
                style="width: 440px; height: 470px; border-radius: 10px; padding: 20px 30px;">
                <v-card-actions color="#F3F4F5">
                    <v-btn text color="primary">Показать все</v-btn>
                    <v-spacer />
                    <v-btn text color="primary">Отметить прочитанными</v-btn>
                </v-card-actions>
                <v-list>
                    <template v-for="(notification, index) in notifications" :key="notification.id">
                        <v-list-item class="notification-item">
                            <div class="notification-content">
                                <svg width="22" height="26" viewBox="0 0 22 26" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" class="notification-icon-svg">
                                    <path
                                        d="M14.6666 19.1111H20.7777L19.0606 17.394C18.5949 16.9283 18.3333 16.2967 18.3333 15.6381V11.7778C18.3333 8.5848 16.2926 5.86844 13.4444 4.86173V4.44444C13.4444 3.09442 12.35 2 10.9999 2C9.64992 2 8.5555 3.09441 8.5555 4.44444V4.86173C5.70725 5.86844 3.66661 8.5848 3.66661 11.7778V15.6381C3.66661 16.2967 3.40499 16.9283 2.93931 17.394L1.22217 19.1111H7.33328M14.6666 19.1111V20.3333C14.6666 22.3584 13.025 24 10.9999 24C8.9749 24 7.33328 22.3584 7.33328 20.3333V19.1111M14.6666 19.1111H7.33328"
                                        stroke="#525A7D" stroke-width="2.04545" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                                <span class="notification-text">{{ notification.text }}</span>
                            </div>
                            <div class="more-link-wrapper">
                                <span class="more-link">подробнее</span>
                            </div>
                        </v-list-item>
                        <hr v-if="index < notifications.length - 1" class="notification-divider" />
                    </template>
                </v-list>
            </v-card>
        </v-menu>
        <button v-else text class="login-button" @click="$emit('login-click')">
            Войти
        </button>
    </div>
</template>

<script setup>
import { defineProps, computed, ref } from 'vue';
import { useStore } from 'vuex';

// Определяем пропсы с типами
const props = defineProps({
    avatarUrl: {
        type: String,
        default: 'https://storage.yandexcloud.net/testbucketplatforma34/woman_blue_background.jpeg',
    },
    notificationCount: {
        type: Number,
        default: 0,
    },
    isAuthenticated: {
        type: Boolean,
        default: false,
    },
});

// Используем Vuex для проверки аутентификации (если передан пропс, он имеет приоритет)
const store = useStore();
const isAuthenticated = computed(() => props.isAuthenticated || store.getters.isAuthenticated);

// Моковые данные для уведомлений
const notifications = ref([
    { id: 1, text: 'У вас назначено мероприятие на 12 июня в 18:00 с ведущим: Сергей Мейерхольд' },
    { id: 2, text: 'У вас назначено мероприятие на 12 июня в 18:00 с ведущим: Сергей Мейерхольд' },
    { id: 3, text: 'У вас назначено мероприятие на 12 июня в 18:00 с ведущим: Сергей Мейерхольд' },
    { id: 4, text: 'У вас назначено мероприятие на 15 июня в 14:00 с ведущим: Иван Иванов' },
    { id: 5, text: 'У вас назначено мероприятие на 20 июня в 10:00 с ведущим: Петр Петров' },
    { id: 6, text: 'У вас назначено мероприятие на 20 июня в 10:00 с ведущим: Петр Петров' },
    { id: 7, text: 'У вас назначено мероприятие на 20 июня в 10:00 с ведущим: Петр Петров' },
]);
</script>

<style scoped>
.notification-wrapper {
    display: flex;
    align-items: center;
}

.notification-icon {
    position: relative;
    box-shadow: none !important;
    /* Убираем тень в обычном состоянии */
}

.notification-icon:hover {
    background-color: transparent !important; /* Убираем изменение фона при наведении */
    box-shadow: none !important; /* Убеждаемся, что тень не появляется */
}

/* Остальные стили остаются без изменений */
.notification-card {
    overflow-y: auto;
    padding: 20px 30px;
}

.notification-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.notification-content {
    display: flex;
    align-items: center;
    width: 100%;
}

.notification-icon-svg {
    margin-right: 22px;
}

.notification-text {
    color: #434966;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    flex: 1;
}

.more-link-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
}

.more-link {
    color: #998BF8;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-transform: lowercase;
    cursor: pointer;
}

.more-link:hover {
    color: #7a6fd1;
}

.notification-divider {
    border: none;
    border-top: 1px solid #e0e0e0;
    margin: 16px 0;
}

.login-button:hover {
    color: #7a6fd1;
    transition: 0.3s;
}
</style>