import { createStore } from 'vuex';
import axios from 'axios';

axios.defaults.withCredentials = true;

interface User {
    id: number;
    mentor_name: string;
    mentor_surname: string;
    user_role: string;
    user_city_residence: string;
    mentor_description: string;
    mentor_events_id: [];
    menti_registered_events: [];
    mentor_avatar_url: string;
    mentor_title: string;
    mentor_tg_title: string;
}

interface State {
    user: User | null;
    userChecked: boolean;
}

export default createStore({
    state: {
        user: null,
        userChecked: false,
    } as State,
    mutations: {
        setUser(state: State, user: User) {
            state.user = user;
            state.userChecked = true;
        },
        clearUser(state: State) {
            state.user = null;
            state.userChecked = true;
        },
    },
    actions: {
        async fetchUser({ commit }) {
            try {
                const response = await axios.get('/users/me/');
                console.log('fetch user response:', response.data);
                commit('setUser', response.data);
            } catch (error) {
                console.error('Ошибка при получении пользователя:', error);
                commit('clearUser');
                throw error;
            }
        },
        async login({ dispatch }, { email, passcode }) {
            try {
                await axios.post('/verify-passcode/verify_login/', { email, passcode });
                await dispatch('fetchUser');
            } catch (error) {
                console.error('Ошибка при входе:', error);
                throw error;
            }
        },
        async register(_, { email, role }) {
            try {
                await axios.post('/registration/register/', { email, role });
            } catch (error) {
                console.error('Ошибка при регистрации:', error);
                throw error;
            }
        },
        async confirmRegistration({ dispatch }, { email, passcode, password, username }) {
            try {
                await axios.post('/registration/confirm_registration/', { email, passcode, password, username });
                await dispatch('fetchUser');
            } catch (error) {
                console.error('Ошибка при подтверждении регистрации:', error);
                throw error;
            }
        },
        async logout({ commit }) {
            try {
                await axios.post('/logout/');
                commit('clearUser');
            } catch (error) {
                console.error('Ошибка при выходе:', error);
                commit('clearUser');
            }
        },
    },
    getters: {
        isAuthenticated: (state: State) => !!state.user,
        userId: (state: State) => state.user?.id,
        userRole: (state: State) => state.user?.user_role || null,
    },
});













// import { createStore } from 'vuex';
// import axios from 'axios';

// // Настройка Axios для работы с HTTP-only cookies
// axios.defaults.withCredentials = true;

// interface User {
//     id: number;
//     mentor_name: string;
//     mentor_surname: string;
//     user_role: string; // 'mentor', 'mentee' или другое
//     user_city_residence: string;
//     mentor_description: string;
//     mentor_events_id: [];
//     menti_registered_events: [];
//     mentor_avatar_url: string;
//     mentor_title: string;
//     mentor_tg_title: string;
// }

// interface State {
//     user: User | null;
//     userChecked: boolean; // добавим флаг "мы уже проверяли /me?"
// }

// export default createStore({
//     state: {
//         user: null,
//         userChecked: false,
//     } as State,
//     mutations: {
//         setUser(state: State, user: User) {
//             state.user = user;
//             state.userChecked = true;
//         },
//         clearUser(state: State) {
//             state.user = null;
//             state.userChecked = true;
//         },
//     },
//     actions: {
//         async fetchUser({ commit }) {
//             try {
//                 const response = await axios.get('/users/me/');
//                 // const response = await axios.get('/auth/me/');
//                 console.log("fetch user response is: ")
//                 console.log(response.data)
//                 commit('setUser', response.data);
//             } catch (error) {
//                 commit('clearUser');
//                 throw error;
//             }
//         },
//         async login({ dispatch }, credentials: { username: string; password: string }) {
//             await axios.post('/token/', credentials);
//             await dispatch('fetchUser');
//         },
//         async logout({ commit }) {
//             try {
//                 await axios.post('/logout/');
//                 commit('clearUser');
//             } catch (error) {
//                 console.error('Ошибка при выходе:', error);
//                 commit('clearUser'); // Очищаем состояние даже при ошибке
//             }
//         },
//         // async logout({ commit }) {
//         //     await axios.post('/logout/');
//         //     commit('clearUser');
//         // },
//         async register({ dispatch }, userData: { username: string; password: string; email: string }) {
//             await axios.post('/auth/register/', userData);
//             // await axios.post('/users/register/', userData);
//             // Опционально: автоматический вход после регистрации
//             // await dispatch('login', { username: userData.username, password: userData.password });
//         },
//     },
//     getters: {
//         isAuthenticated: (state: State) => !!state.user,
//         userId: (state: State) => state.user?.id,
//         userRole: (state: State) => state.user?.user_role || null, // Добавляем геттер для роли
//     },
// });