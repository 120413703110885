<template>
    <v-dialog v-model="dialogVisible" max-width="600px" persistent>
        <v-card style="border-radius: 20px;">
            <v-card-title class="d-flex justify-space-between align-center pa-4">
                <div>
                    <h2 class="text-h5 font-weight-bold mb-1">
                        {{ isNewEvent ? 'Создание мероприятия' : 'Редактирование мероприятия' }}
                    </h2>
                    <p class="text-body-2 text-medium-emphasis">
                        Вы можете изменить детали мероприятия.
                    </p>
                </div>
                <v-btn color="primary" variant="text" @click="previewEvent">
                    Предпросмотр
                </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="pa-4">
                <v-form ref="form" v-model="isFormValid">
                    <!-- Загрузка обложки с кнопкой удаления -->
                    <div class="mb-4">
                        <v-sheet class="upload-area d-flex flex-column align-center justify-center" height="200" rounded
                            color="grey-lighten-3" @click="triggerFileInput">
                            <input ref="fileInput" type="file" accept="image/*" class="d-none"
                                @change="handleFileUpload" />
                            <template v-if="!eventData.coverImage">
                                <v-icon size="32" color="primary" icon="mdi-upload" class="mb-2"></v-icon>
                                <span class="text-subtitle-1 font-weight-medium">Загрузите обложку</span>
                                <span class="text-caption text-medium-emphasis">1920x1080, до 5 МБ</span>
                            </template>
                            <v-img v-else :src="eventData.coverImage" cover height="100%" width="100%">
                                <!-- Добавляем кнопку удаления поверх изображения -->
                                <div class="image-overlay d-flex justify-end">
                                    <v-btn icon variant="tonal" color="error" size="small" class="ma-2"
                                        @click.stop="deleteImage">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </div>
                            </v-img>
                        </v-sheet>
                    </div>

                    <!-- Название мероприятия -->
                    <div class="mb-4">
                        <label class="text-subtitle-1 font-weight-medium d-block mb-2">Название</label>
                        <v-text-field v-model="eventData.title" placeholder="Введите название" variant="outlined"
                            hide-details />
                    </div>

                    <!-- Дата и время мероприятия -->
                    <div class="d-flex mb-4">
                        <div class="flex-grow-1 mr-4">
                            <label class="text-subtitle-1 font-weight-medium d-block mb-2">Дата</label>
                            <v-text-field v-model="eventData.date" type="date" variant="outlined" hide-details
                                density="compact" />
                        </div>
                        <div class="flex-grow-1 mr-4"></div>
                        <div>
                            <label class="text-subtitle-1 font-weight-medium d-block mb-2">Время</label>
                            <simple-time-picker v-model="timeValue" @update:model-value="handleTimeChange" />
                        </div>
                    </div>

                    <!-- Цена и количество участников -->
                    <div class="d-flex mb-4">
                        <div class="flex-grow-1 mr-4">
                            <label class="text-subtitle-1 font-weight-medium d-block mb-2">Цена (руб.)</label>
                            <v-text-field v-model.number="eventData.price" type="number" min="0" placeholder="Бесплатно"
                                variant="outlined" density="compact" hide-details />
                        </div>

                        <div class="flex-grow-1">
                            <label class="text-subtitle-1 font-weight-medium d-block mb-2">Участники</label>
                            <v-text-field v-model.number="eventData.participantLimit" type="number" min="1"
                                placeholder="20" variant="outlined" density="compact" hide-details />
                        </div>
                    </div>

                    <!-- Описание мероприятия -->
                    <!-- Описание мероприятия -->
                    <div class="mb-4">
                        <label class="text-subtitle-1 font-weight-medium d-block mb-2">Описание</label>
                        <tip-tap-editor v-model="eventData.description" />
                    </div>
                </v-form>
            </v-card-text>


            <v-divider></v-divider>

            <v-card-actions class="pa-4">
                <v-spacer></v-spacer>
                <v-btn color="grey-darken-1" variant="text" @click="closeModal">Отмена</v-btn>
                <v-btn color="primary" variant="elevated" :disabled="!isFormValid || isLoading" :loading="isLoading"
                    @click="saveEvent">
                    {{ isNewEvent ? 'Создать' : 'Сохранить' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, watch, nextTick, defineEmits, defineProps } from 'vue';
import SimpleTimePicker from '../SimpleTimePicker.vue';
import { useStore } from 'vuex';
import TipTapEditor from '@/components/TipTapEditor/TipTapEditor.vue';
// Vuex store для получения ID пользователя
const store = useStore();
const myId = computed(() => store.getters['userId']);

// Пропсы компонента
const props = defineProps({
    modelValue: { type: Boolean, default: false },
    event: {
        type: Object,
        default: () => ({
            id: '',
            title: '',
            description: '',
            price: 0,
            date: '',
            time: '',
            participantLimit: 20,
            coverImage: null,
            mentor: null
        })
    }
});


// Эмиттеры событий
const emit = defineEmits(['update:modelValue', 'save', 'cancel']);

// Локальные состояния формы
const isFormValid = ref(true);
const isLoading = ref(false);
const dialogVisible = ref(props.modelValue);
const timeValue = ref('');
const fileInput = ref(null); // Ссылка на input для файла
const eventData = ref({
    id: '',
    title: '',
    description: '',
    price: 0,
    date: '',
    time: '',
    participantLimit: 20,
    coverImage: null,
    mentor: null,
    imageDeleted: false // Флаг для отслеживания удаления изображения
});

// Вычисляемое свойство для определения, новое ли это мероприятие
const isNewEvent = computed(() => !eventData.value.id);

// **Функция сброса формы**
const resetForm = (newEvent = props.event) => {
    if (newEvent && newEvent.id) {
        eventData.value = {
            ...newEvent,
            imageDeleted: false // Сбрасываем флаг при инициализации
        };
        timeValue.value = newEvent.time;
    } else {
        const now = new Date();
        eventData.value = {
            id: '',
            title: '',
            description: '',
            price: 0,
            date: now.toISOString().split('T')[0],
            time: now.toTimeString().substring(0, 5),
            participantLimit: 20,
            coverImage: null,
            mentor: myId.value,
            imageDeleted: false
        };
        timeValue.value = eventData.value.time;
    }
    nextTick(() => timeValue.value = eventData.value.time);
};

const previewEvent = () => {
    const previewData = formatEventForApi(eventData.value);
    const mentorStub = {
        mentor_name: 'Имя',
        mentor_surname: 'Фамилия',
        mentor_avatar_url: 'https://via.placeholder.com/150',
        mentor_description: 'Описание ментора для предпросмотра',
        mentor_tg_title: 'Титул ментора'
    };
    const fullPreviewData = {
        ...previewData,
        event_ageRestriction: '18+',
        available_spots: previewData.available_spots || previewData.total_spots,
        total_spots: previewData.total_spots || 20,
        mentor: mentorStub
    };
    localStorage.setItem('previewEvent', JSON.stringify(fullPreviewData));
    window.open('/event_info/preview', '_blank');
};

// **Наблюдатель за открытием/закрытием диалога**
watch(() => props.modelValue, (newValue) => {
    dialogVisible.value = newValue;
    if (newValue) resetForm();
});

// **Синхронизация диалога с `modelValue`**
watch(dialogVisible, (newValue) => {
    emit('update:modelValue', newValue);
});

// **Наблюдатель за изменением пропса event**
watch(() => props.event, (newEvent) => {
    resetForm(newEvent);
}, { immediate: true });

// **Обновление времени при выборе в `simple-time-picker`**
const handleTimeChange = (newTime) => {
    timeValue.value = newTime;
    eventData.value.time = newTime;
};

// **Методы для загрузки файла**
const triggerFileInput = () => {
    fileInput.value.click();
};

const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Проверка размера файла (5MB)
    if (file.size > 5 * 1024 * 1024) {
        alert('Файл слишком большой. Максимальный размер 5MB.');
        return;
    }

    // Создаем URL для предпросмотра
    const reader = new FileReader();
    reader.onload = (e) => {
        eventData.value.coverImage = e.target.result;
        eventData.value.imageDeleted = false; // Сбрасываем флаг удаления при загрузке нового изображения
    };
    reader.readAsDataURL(file);
};

// **Функция удаления изображения**
const deleteImage = (event) => {
    // Останавливаем всплытие события, чтобы не сработал клик по v-sheet
    event.stopPropagation();

    // Если это существующее мероприятие, устанавливаем флаг удаления
    if (eventData.value.id) {
        eventData.value.imageDeleted = true;
    }

    // Удаляем изображение
    eventData.value.coverImage = null;

    // Сбрасываем input file, чтобы можно было загрузить то же самое изображение снова
    if (fileInput.value) {
        fileInput.value.value = '';
    }
};

// **Закрытие модального окна**
const closeModal = () => {
    dialogVisible.value = false;
    emit('cancel');
};

const formatEventForApi = (componentEvent) => {
    if (!componentEvent.date || !componentEvent.time) {
        console.error('Ошибка: не указаны дата или время мероприятия!', componentEvent);
        throw new Error('Дата и время мероприятия обязательны.');
    }

    const localDate = new Date(`${componentEvent.date}T${componentEvent.time}:00`);
    const utcDate = new Date(localDate.getTime());

    if (isNaN(utcDate.getTime())) {
        console.error('Ошибка: некорректная дата или время.', componentEvent);
        throw new Error('Некорректные дата или время мероприятия.');
    }

    return {
        id: componentEvent.id,
        title: componentEvent.title,
        description: componentEvent.description,
        mentor_id: componentEvent.mentor || myId.value, // Используем mentor_id вместо mentor
        date: utcDate.toISOString(),
        price: componentEvent.price,
        available_spots: componentEvent.availableSpots || componentEvent.participantLimit,
        total_spots: componentEvent.participantLimit,
        // Не включаем image_url здесь, будем обрабатывать отдельно
        is_public: true,
        is_canceled: false,
        is_whitelisted: true
    };
};

// **Сохранение мероприятия**
const saveEvent = () => {
    isLoading.value = true;
    try {
        console.log('Сохранение мероприятия в местном времени:', eventData.value);
        emit('save', {
            ...eventData.value,
            // Передаем флаг удаления изображения в родительский компонент
            imageDeleted: eventData.value.imageDeleted
        });
        closeModal();
    } catch (error) {
        console.error('Ошибка при сохранении мероприятия:', error);
    } finally {
        isLoading.value = false;
    }
};
</script>

<style scoped>
.v-card-title {
    font-weight: bold;
}

.upload-area {
    border: 2px dashed rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
    overflow: hidden;
}

.upload-area:hover {
    border-color: var(--v-primary-base);
    background-color: rgba(var(--v-primary-base), 0.05);
}

/* Добавляем стили для оверлея с кнопкой удаления */
.image-overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.2s;
}

.v-img:hover .image-overlay {
    opacity: 1;
}

.v-card-text {
    overflow: visible;
    /* Убираем ограничение overflow */
}

.v-dialog .tiptap-editor {
    position: relative;
    z-index: 1000;
    /* Увеличиваем z-index для видимости меню */
}
</style>