<template>
    <header class="header">
        <a href="/" class="logo">ФОРМА</a>

        <div class="toggle-container">
            <div class="toggle-wrapper">
                <div class="toggle-slider" :style="{
                    transform: `translateX(${currentPath === '/AllMentors' ? '100%' : '0'})`,
                    background: `${currentPath === '/AllMentors' || currentPath === '/AllEvents'
                        ? 'var(--accent-accent100, #DCD8FC)'
                        : 'none'
                        }`
                }"></div>
                <router-link to="/AllEvents" class="toggle-button" :class="{ active: currentPath === '/AllEvents' }">
                    Мероприятия
                </router-link>
                <router-link to="/AllMentors" class="toggle-button" :class="{ active: currentPath === '/AllMentors' }">
                    Менторы
                </router-link>
            </div>
        </div>

        <div>
            <NotificationComponent :avatar-url="avatarUrl" :notification-count="notificationCount"
                :is-authenticated="isAuthenticated" @login-click="navigateToPage('/login')" />
        </div>
    </header>
</template>

<script setup>
/* eslint-disable */
import { useStore } from 'vuex';
import { ref, onMounted, watch, computed, defineProps } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import NotificationComponent from './NotificationComponent.vue'; // Убедись, что путь к файлу правильный


// const props = defineProps({
//     // avatarUrl: String,
//     notificationCount: Number,
//     isAuthenticated: Boolean, // Пропс для проверки аутентификации
// });
const route = useRoute();
const router = useRouter();
const currentPath = ref(route.path);
const avatarUrl = ref("https://storage.yandexcloud.net/testbucketplatforma34/woman_blue_background.jpeg")
const store = useStore();
const isAuthenticated = computed(() => store.getters.isAuthenticated);

// Добавляем данные для NotificationComponent
// const userIsLoggedIn = ref(false); // Пока задаем false, но можешь заменить на динамическую проверку
const notificationCount = ref(10); // Пример: 10 уведомлений

// Watch for route changes
watch(
    () => route.path,
    (newPath) => {
        currentPath.value = newPath;
    }
);

// onMounted(() => {
//     if (!props.isAuthenticated) {
//         document.querySelector('.calendar-main-content')?.classList.add('blurred');
//     }
// });

const navigateToPage = (route) => {
    router.push(route);
};

// Set initial path on component mount
onMounted(() => {
    currentPath.value = route.path;
});
</script>

<style scoped>
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    background: var(--neutral-neutral100, #F3F4F5);
    box-shadow: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.logo {
    color: #8162e9;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
}

.toggle-container {
    position: relative;
}

.toggle-wrapper {
    position: relative;
    display: flex;
    background-color: white;
    border-radius: 100px;
    padding: 4px;
    cursor: pointer;
    width: 280px;
}

.toggle-slider {
    position: absolute;
    width: 49%;
    height: calc(100% - 8px);
    background: var(--accent-accent100, #DCD8FC);
    border-radius: 100px;
    transition: all 0.3s ease;
}

.toggle-button {
    position: relative;
    z-index: 1;
    width: 50%;
    padding: 8px 16px;
    border: none;
    background: none;
    color: var(--accent-accent800, #5747AF);
    font-size: 0.9rem;
    cursor: pointer;
    transition: color 0.3s ease;
    text-decoration: none;
    text-align: center;
}

.toggle-button.active {
    color: var(--accent-accent600, #705CE2);
}
</style>
