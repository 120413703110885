<template>
    <div class="google-meet-picker">
        <label v-if="label" class="time-label">{{ label }}</label>

        <v-menu v-model="showPicker" :close-on-content-click="false" transition="scale-transition" offset="10">
            <template v-slot:activator="{ props }">
                <v-text-field v-model="displayTime" v-bind="props" @input="onInput"
                    @keydown.down.prevent="highlightNext" @keydown.up.prevent="highlightPrev"
                    @keydown.enter.prevent="selectHighlighted" @keydown.esc="showPicker = false" @blur="onBlur"
                    variant="outlined" density="compact" hide-details clearable @click:clear="clearTime"
                    class="time-display" :class="{ selected: showPicker }"></v-text-field>
            </template>

            <v-card class="time-picker-card">
                <v-card-text class="pa-0">
                    <div class="time-list">
                        <div v-for="(time, index) in filteredTimeOptions" :key="time" class="time-option"
                            :class="{ selected: time === modelValue, highlighted: index === highlightedIndex }"
                            @mousedown.prevent="selectTime(time)">
                            {{ time }}
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>

<script setup>
import { ref, computed, watch, nextTick, defineProps, defineEmits } from 'vue';

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    interval: {
        type: Number,
        default: 15,
    },
    label: {
        type: String,
        default: '',
    }
});

const emit = defineEmits(['update:modelValue']);

const showPicker = ref(false);
const displayTime = ref(props.modelValue || '00:00');
const highlightedIndex = ref(-1);

const timeOptions = computed(() => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += props.interval) {
            times.push(
                `${hour.toString().padStart(2, '0')}:${minute
                    .toString()
                    .padStart(2, '0')}`
            );
        }
    }
    return times;
});

const filteredTimeOptions = computed(() => {
    const value = displayTime.value.replace(/[^0-9]/g, '');
    if (!value) return timeOptions.value;

    if (value.length <= 2) {
        return timeOptions.value.filter((time) =>
            time.startsWith(value.padStart(2, '0'))
        );
    }

    if (value.length <= 4) {
        const formatted = `${value.slice(0, 2).padStart(2, '0')}:${value.slice(2)}`;
        return timeOptions.value.filter((time) => time.startsWith(formatted));
    }

    return timeOptions.value;
});

const clearTime = () => {
    // Устанавливаем значение по умолчанию вместо удаления
    displayTime.value = '00:00';
    emit('update:modelValue', '00:00');
    highlightedIndex.value = -1;

    // Добавляем небольшую задержку перед закрытием меню
    setTimeout(() => {
        showPicker.value = false;
    }, 100);
};

const selectTime = (time) => {
    displayTime.value = time;
    emit('update:modelValue', time);

    // Добавляем небольшую задержку перед закрытием меню
    setTimeout(() => {
        showPicker.value = false;
    }, 100);
};

const highlightNext = () => {
    if (!filteredTimeOptions.value.length) return;
    highlightedIndex.value =
        (highlightedIndex.value + 1) % filteredTimeOptions.value.length;
};

const highlightPrev = () => {
    if (!filteredTimeOptions.value.length) return;
    highlightedIndex.value =
        (highlightedIndex.value - 1 + filteredTimeOptions.value.length) %
        filteredTimeOptions.value.length;
};

const selectHighlighted = () => {
    if (highlightedIndex.value !== -1) {
        selectTime(filteredTimeOptions.value[highlightedIndex.value]);
    }
};

const onInput = (event) => {
    const input = event.target;
    let value = input.value.replace(/[^0-9]/g, '').slice(0, 4);

    let hours = value.slice(0, 2);
    let minutes = value.slice(2, 4);

    if (hours.length === 1 && parseInt(hours) > 2) {
        hours = '0' + hours;
    } else if (hours.length === 2 && parseInt(hours) > 23) {
        hours = '23';
    }

    if (value.length <= 2) {
        displayTime.value = hours;
    } else {
        displayTime.value = `${hours}:${minutes}`;
    }

    nextTick(() => {
        let pos = input.selectionStart;
        if (value.length >= 3 && pos === 2) pos++; // перескок через двоеточие
        input.setSelectionRange(pos, pos);
    });
};

const onBlur = () => {
    // Всегда устанавливаем значение по умолчанию, если поле пустое
    if (!displayTime.value) {
        displayTime.value = '00:00';
        emit('update:modelValue', '00:00');
        return;
    }

    let [hours = '00', minutes = '00'] = displayTime.value.split(':');
    hours = hours.padStart(2, '0');
    minutes = (minutes || '').padStart(2, '0');

    if (parseInt(hours) > 23) hours = '23';

    const roundedMinutes = Math.round(parseInt(minutes) / props.interval) * props.interval;
    if (roundedMinutes === 60) {
        minutes = '00';
        hours = (parseInt(hours) + 1).toString().padStart(2, '0');
        if (parseInt(hours) > 23) hours = '00';
    } else {
        minutes = roundedMinutes.toString().padStart(2, '0');
    }

    const validTime = `${hours}:${minutes}`;
    displayTime.value = validTime;
    emit('update:modelValue', validTime);
};

watch(
    () => props.modelValue,
    (newValue) => {
        // Если значение null или пустое, устанавливаем значение по умолчанию
        displayTime.value = newValue || '00:00';
    }
);
</script>

<style scoped>
.google-meet-picker {
    position: relative;
    width: 120px;
}

.time-display {
    border-radius: 4px;
}

.time-display :deep(.v-field__input) {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    font-size: 16px;
}

.time-display.selected :deep(.v-field) {
    border-color: rgb(var(--v-theme-primary));
    background: rgba(var(--v-theme-primary), 0.04);
}

.time-picker-card {
    background: #202124;
    border-radius: 8px;
    max-height: 300px;
    overflow: hidden;
}

.time-list {
    max-height: 250px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #5f6368 transparent;
}

.time-list::-webkit-scrollbar {
    width: 8px;
}

.time-list::-webkit-scrollbar-track {
    background: transparent;
}

.time-list::-webkit-scrollbar-thumb {
    background-color: #5f6368;
    border-radius: 4px;
}

.time-option {
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    /* color: #e8eaed; */
    color: #5747af;
    transition: background-color 0.2s;
}

.time-option:hover,
.time-option.highlighted {
    background-color: rgba(232, 234, 237, 0.08);
}

.time-option.selected {
    background-color: rgba(var(--v-theme-primary), 0.12);
    color: rgb(var(--v-theme-primary));
}

.time-label {
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #e8eaed;
}
</style>