<template>
    <v-container fluid class="py-6" style="padding: 0px;">
        <h1 class="text-h4 mb-4">Редактор сессий</h1>

        <!-- Tabs -->
        <v-tabs v-model="activeTab" class="mb-6">
            <v-tab value="group">Мероприятия</v-tab>
            <v-tab value="personal">Личные сессии</v-tab>
        </v-tabs>

        <v-window v-model="activeTab" background-color="primary" dark slider-color="#7B65F7" class="mb-4">
            <v-window-item value="group">
                <div v-if="loading" class="d-flex justify-center my-4">
                    <v-progress-circular indeterminate
                        style="box-shadow: none; border: none; background-color: rgb(129, 98, 233, 0.5); color: white"></v-progress-circular>
                </div>
                <div v-else>
                    <!-- Group sessions content -->
                    <div class="d-flex justify-space-between align-center mb-4">
                        <h2 class="text-h5">Групповые мероприятия</h2>
                        <v-btn prepend-icon="mdi-plus"
                            style="border-radius: 20px; box-shadow: none; border: none; background-color: rgb(129, 98, 233, 0.5); color: white"
                            @click="openCreateEventModal">
                            Добавить мероприятие
                        </v-btn>
                    </div>

                    <v-row>
                        <v-col v-for="event in events" :key="event.id" cols="12" sm="6" md="4"
                            style="margin-bottom: 4px;">
                            <event-card :event="event" @edit="openEditEventModal" @delete="deleteEvent" />
                        </v-col>
                    </v-row>

                    <div v-if="events.length === 0" class="text-center my-8">
                        <p class="text-body-1 text-medium-emphasis">У вас пока нет групповых мероприятий</p>
                        <v-btn class="mt-2" prepend-icon="mdi-plus"
                            style="border-radius: 20px; box-shadow: none; border: none; background-color: rgb(129, 98, 233, 0.5); color: white"
                            @click="openCreateEventModal">
                            Создать первое мероприятие
                        </v-btn>
                    </div>
                </div>
            </v-window-item>

            <v-window-item value="personal">
                <!-- Personal sessions content -->
                <div v-if="loading" class="d-flex justify-center my-4">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>

                <div v-else class="desktop-grid">
                    <consultation-type-card v-for="consultationType in consultationTypes" :key="consultationType.id"
                        :consultation-type="consultationType"
                        :slots="getUniqueSlotsByConsultationType(consultationType.id)" @update="fetchConsultationTypes"
                        style="border-radius: 100px" />

                </div>
                <v-btn color="primary" variant="text" @click="showAddConsultationTypeDialog = true" class="mt-4">
                    Добавить вид консультации
                </v-btn>
            </v-window-item>
        </v-window>

        <!-- Dialog for adding/editing consultation type -->
        <edit-consultation-type-dialog v-model="showAddConsultationTypeDialog" :consultation-type="{}" :slots="[]" @update="fetchConsultationTypes" />

        <!-- Dialog for adding/editing event -->
        <edit-event-dialog v-model="isEventModalOpen" :event="currentEvent" @save="saveEvent" />
    </v-container>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed } from 'vue';
import ConsultationTypeCard from '@/components/pages/create/ConsultationTypeCard.vue';
import EditConsultationTypeDialog from '@/components/pages/create/EditConsultationTypeDialog.vue';
import EventCard from '@/components/pages/create/event/EventCard.vue';
import EditEventDialog from '@/components/pages/create/event/EventEditModal.vue';
import { fetchConsultationTypesApi, fetchSlotsApi, getUserEventsAPI, updateEventApi, createEventApi, deleteEventApi } from '@/services/api';
import { useStore } from 'vuex'

const store = useStore()
const myId = computed(() => store.getters['userId'])

// State
const activeTab = ref('group');
const consultationTypes = ref([]);
const consultationSlots = ref([]);
const loading = ref(true);
const showAddConsultationTypeDialog = ref(false);

// Events state
const events = ref([]);
const isEventModalOpen = ref(false);
const currentEvent = ref({});

const showSnackbar = (text, color = 'success') => {
    snackbar.value = {
        show: true,
        text,
        color
    };
};

const snackbar = ref({
    show: false,
    text: '',
    color: 'success'
});

const getUniqueSlotsByConsultationType = (consultationTypeId) => {
    const slots = consultationSlots.value.filter(
        slot => slot.consultation_type === consultationTypeId
    );

    // Создаем Map для хранения уникальных слотов
    const uniqueSlots = new Map();

    slots.forEach(slot => {
        const recurrenceKey = slot.recurrence_id || slot.id;

        if (!uniqueSlots.has(recurrenceKey)) {
            uniqueSlots.set(recurrenceKey, { ...slot, custom_weekdays: new Set() });
        }

        // Добавляем день недели в custom_weekdays
        const slotDate = new Date(slot.start_time); // Преобразуем start_time в дату
        const weekDay = slotDate.toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase(); // Получаем сокращенное название дня недели

        uniqueSlots.get(recurrenceKey).custom_weekdays.add(weekDay);
    });

    // Преобразуем Set в массив перед возвратом
    return Array.from(uniqueSlots.values()).map(slot => ({
        ...slot,
        custom_weekdays: Array.from(slot.custom_weekdays)
    }));
};

// Methods for consultation types
const fetchConsultationTypes = async () => {
    loading.value = true;
    try {
        consultationTypes.value = await fetchConsultationTypesApi(myId.value);
        await fetchAllSlots();
    } catch (error) {
        console.error('Error fetching consultation types:', error);
    } finally {
        loading.value = false;
    }
};

const fetchAllSlots = async () => {
    try {
        consultationSlots.value = await fetchSlotsApi(myId.value);
    } catch (error) {
        console.error('Error fetching slots:', error);
    }
};

// Функция для преобразования формата даты API в формат компонента
const formatEventForComponent = (apiEvent) => {
    if (!apiEvent || !apiEvent.date) {
        console.error('Invalid or missing date from API:', apiEvent);
        return {};
    }

    // Парсим дату с сервера (может быть "2025-03-21T17:00:00+03:00" или "2025-03-21T14:00:00Z")
    const eventDate = new Date(apiEvent.date);
    if (isNaN(eventDate.getTime())) {
        console.error('Invalid date from API:', apiEvent.date);
        return {};
    }

    // Преобразуем в локальное время пользователя
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = new Date(eventDate.toLocaleString('en-US', { timeZone: localTimeZone }));

    return {
        id: apiEvent.id,
        title: apiEvent.title,
        description: apiEvent.description,
        price: apiEvent.price,
        date: localDate.toISOString().split('T')[0], // "2025-03-21"
        time: localDate.toTimeString().substring(0, 5), // "17:00" для "+03:00" или "14:00" для UTC с учетом часового пояса
        participantLimit: apiEvent.total_spots,
        availableSpots: apiEvent.available_spots,
        coverImage: apiEvent.image_url,
        mentor: apiEvent.mentor_id // Используем mentor_id из API
    };
};

// Функция для преобразования формата компонента в формат API
const formatEventForApi = (componentEvent) => {
    if (!componentEvent.date || !componentEvent.time) {
        console.error('Ошибка: не указаны дата или время мероприятия!', componentEvent);
        throw new Error('Дата и время мероприятия обязательны.');
    }

    // Создаем объект Date из локального времени пользователя
    const localDate = new Date(`${componentEvent.date}T${componentEvent.time}:00`);

    // Преобразуем в UTC, используя смещение часового пояса пользователя
    const utcDate = new Date(localDate.getTime());

    if (isNaN(utcDate.getTime())) {
        console.error('Ошибка: некорректная дата или время.', componentEvent);
        throw new Error('Некорректные дата или время мероприятия.');
    }

    return {
        id: componentEvent.id,
        title: componentEvent.title,
        description: componentEvent.description,
        mentor_id: componentEvent.mentor || myId.value, // Используем mentor_id вместо mentor
        date: utcDate.toISOString(), // Например, "2025-03-21T11:00:00.000Z" для 14:00 в Москве
        price: componentEvent.price,
        available_spots: componentEvent.availableSpots || componentEvent.participantLimit,
        total_spots: componentEvent.participantLimit,
        // Не включаем image_url здесь, будем обрабатывать отдельно
        is_public: true,
        is_canceled: false,
        is_whitelisted: true
    };
};

// Methods for events
const fetchEvents = async () => {
    loading.value = true;
    try {
        const apiEvents = await getUserEventsAPI(myId.value);
        events.value = apiEvents.map(formatEventForComponent);
    } catch (error) {
        console.error('Error fetching events:', error);
        events.value = [];
    } finally {
        loading.value = false;
    }
};

const openCreateEventModal = () => {
    currentEvent.value = {
        id: '',
        title: '',
        description: '',
        price: 0,
        date: new Date().toISOString().substr(0, 10),
        time: '18:00',
        participantLimit: 20,
        availableSpots: 20,
        coverImage: null,
        mentor: myId.value // Используем ID из Vuex
    };
    isEventModalOpen.value = true;
};

const openEditEventModal = (event) => {
    currentEvent.value = { ...event };
    isEventModalOpen.value = true;
};

const deleteEvent = async (eventId) => {
    try {
        await deleteEventApi(eventId);
        events.value = events.value.filter(e => e.id !== eventId);
        showSnackbar('Мероприятие успешно удалено');
    } catch (error) {
        console.error('Error deleting event:', error);
        showSnackbar(`Не удалось удалить: ${error.message}`, 'error');
    }
};

const saveEvent = async (eventData) => {
    console.log('Received eventData:', JSON.stringify(eventData, null, 2));
    try {
        const apiEventData = formatEventForApi(eventData);
        console.log('API event data:', apiEventData);
        
        // Извлекаем файл изображения, если это data URL
        let imageFile = null;
        if (eventData.coverImage && eventData.coverImage.startsWith('data:')) {
            // Конвертируем data URL в объект File
            const response = await fetch(eventData.coverImage);
            const blob = await response.blob();
            imageFile = new File([blob], 'event-image.jpg', { type: 'image/jpeg' });
        }
        
        if (eventData.id) {
            // Если изображение было удалено, добавляем флаг для удаления на сервере
            if (eventData.imageDeleted || eventData.coverImage === null) {
                apiEventData.remove_image = true;
            }
            
            // Обновление существующего мероприятия
            const updatedEvent = await updateEventApi(apiEventData.id, apiEventData, imageFile);
            console.log('Updated event from API:', updatedEvent);
            const index = events.value.findIndex(e => e.id === eventData.id);
            if (index !== -1) {
                events.value[index] = formatEventForComponent(updatedEvent);
            }
            showSnackbar('Мероприятие успешно обновлено');
        } else {
            // Создание нового мероприятия
            const newEvent = await createEventApi(apiEventData, imageFile);
            events.value.push(formatEventForComponent(newEvent));
            showSnackbar('Мероприятие успешно создано');
        }
    } catch (error) {
        console.error('Error saving event:', error);
        showSnackbar(`Не удалось сохранить: ${error.message}`, 'error');
    }
};

// const saveEvent = async (eventData) => {
//     console.log('Received eventData:', JSON.stringify(eventData, null, 2));
//     try {
//         const apiEventData = formatEventForApi(eventData);
//         console.log('API event data:', apiEventData);
        
//         // Извлекаем файл изображения, если это data URL
//         let imageFile = null;
//         if (eventData.coverImage && eventData.coverImage.startsWith('data:')) {
//             // Конвертируем data URL в объект File
//             const response = await fetch(eventData.coverImage);
//             const blob = await response.blob();
//             imageFile = new File([blob], 'event-image.jpg', { type: 'image/jpeg' });
//         }
        
//         if (eventData.id) {
//             // Обновление существующего мероприятия
//             const updatedEvent = await updateEventApi(apiEventData.id, apiEventData);
//             console.log('Updated event from API:', updatedEvent);
//             const index = events.value.findIndex(e => e.id === eventData.id);
//             if (index !== -1) {
//                 events.value[index] = formatEventForComponent(updatedEvent);
//             }
//             showSnackbar('Мероприятие успешно обновлено');
//         } else {
//             // Создание нового мероприятия
//             const newEvent = await createEventApi(apiEventData, imageFile);
//             events.value.push(formatEventForComponent(newEvent));
//             showSnackbar('Мероприятие успешно создано');
//         }
//     } catch (error) {
//         console.error('Error saving event:', error);
//         showSnackbar(`Не удалось сохранить: ${error.message}`, 'error');
//     }
// };

// Lifecycle hooks
onMounted(() => {
    fetchConsultationTypes();
    fetchEvents();
});
</script>

<style scoped>
.rounded-lg {
    border-radius: 20px !important;
}

@media (min-width: 600px) {
    .desktop-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
    }
}
</style>