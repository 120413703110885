<template>
    <div class="Review">
        <div class="ReviewItem">
            <div class="ReviewAuthor">
                <img src="https://storage.yandexcloud.net/testbucketplatforma34/photo_woman_beauty.webp"
                    alt="Mentor Image" class="ReviewPFP" />
                <div class="ReviewName">
                    Ольга Захарова
                </div>
            </div>
            <div class="ReviewText">
                {{ truncatedReviewText }}
                <button v-if="reviewText.length > 140" @click="openModal" class="modal-btn">
                    Показать больше
                </button>
            </div>
            <Spacer16 />
        </div>
    </div>

    <teleport to="body">
        <transition name="modal">
            <div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
                <div class="modal-content" @click.stop>
                    <button @click="closeModal" class="close-btn">&times;</button>
                    <div class="modal-body">
                        <div class="ReviewAuthor">
                            <img src="https://storage.yandexcloud.net/testbucketplatforma34/photo_woman_beauty.webp"
                                alt="Mentor Image" class="ReviewPFP" />
                            <div class="ReviewName">
                                Ольга Захарова
                            </div>
                        </div>
                        <div class="ReviewText">
                            {{ reviewText }}
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import Spacer16 from "../Spacers/SpacerModule16.vue";

export default defineComponent({
    name: 'ReviewItem',
    components: {
        Spacer16
    },
    props: {
        review: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props) {
        const isModalOpen = ref(false);

        // Обрезка текста отзыва до 140 символов
        const truncatedReviewText = computed(() => {
            const reviewText = props.review.review_text || '';
            if (reviewText.length > 140) {
                return reviewText.slice(0, 140) + '...';
            }
            return reviewText;
        });

        const reviewText = computed(() => props.review.review_text || '');

        const openModal = () => {
            isModalOpen.value = true;
            document.body.style.overflow = 'hidden';
        };

        const closeModal = () => {
            isModalOpen.value = false;
            document.body.style.overflow = '';
        };

        return {
            isModalOpen,
            openModal,
            closeModal,
            truncatedReviewText,
            reviewText
        };
    }
});
</script>

<style scoped>
.ReviewItem {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 224px;
    border-radius: 12px;
    padding: 20px;
}

.ReviewPFP {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    object-fit: cover;
    align-self: center;
    margin-right: 10px;
}

.ReviewAuthor {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.ReviewName {
    font-weight: 500;
    font-size: 20px;
    color: #434966;
}

.ReviewText {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

.modal-btn {
    background: none;
    color: #5850ec;
    font-size: 14px;
    cursor: pointer;
    border: none;
    padding: 5px 10px;
    transition: color 0.3s ease;
}

.modal-btn:hover {
    color: #4338ca;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 32px;
    border-radius: 12px;
    max-width: 600px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #2a2037;
}

.modal-body p {
    font-size: 16px;
    color: #666;
}

.modal-enter-active,
.modal-leave-active {
    transition: all 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
    opacity: 0;
}
</style>