<template>
    <v-dialog v-model="dialog" max-width="900" border-radius="12px" content-class="consultation-modal" @keydown.esc="close">
        <v-card class="consultation-modal-card">
            <v-card-title class="d-flex justify-space-between align-center pa-6">
                <h2 class="modal-title">Запись на консультацию</h2>
                <v-btn icon variant="text" @click="close" aria-label="Закрыть" class="close-btn">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-divider />

            <v-card-text class="pa-6">
                <v-row>
                    <v-col cols="12" md="5">
                        <div class="mentor-info">
                            <v-avatar size="64" class="mentor-avatar mr-4">
                                <v-img :src="mentorAvatar" alt="Аватар ментора" />
                            </v-avatar>
                            <div class="mentor-details">
                                <div class="mentor-name">{{ mentorName }} {{ mentorSurname }}</div>
                                <p>Ментор</p>
                            </div>
                        </div>

                        <v-divider class="mb-6" />

                        <div class="session-info">
                            <h3>Личная сессия</h3>
                            <div class="info-grid">
                                <div class="info-item">
                                    <span class="label">Длительность</span>
                                    <span class="value">{{ duration }}</span>
                                </div>
                                <div class="info-item">
                                    <span class="label">Тема</span>
                                    <span class="value">{{ title }}</span>
                                </div>
                                <div class="info-item">
                                    <span class="label">Стоимость</span>
                                    <span class="value">{{ cost }}</span>
                                </div>
                            </div>
                        </div>
                    </v-col>

                    <v-col cols="12" md="7">
                        <h3 class="confirmation-title">Детали записи</h3>

                        <div class="booking-details mb-6">
                            <div class="detail-item">
                                <i class="calendar-icon"></i>
                                <span>{{ formattedDate }}</span>
                            </div>
                            <div class="detail-item">
                                <i class="clock-icon"></i>
                                <span>{{ formattedTime }}</span>
                            </div>
                        </div>

                        <div class="form-section">
                            <h3 class="text-h6 mb-2">Вопрос к ментору:</h3>
                            <div v-if="question" class="form-textarea question-card">{{ question }}</div>
                            <div v-else class="form-textarea question-card"> Сопроводительный вопрос отсутствует. </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
/* eslint-disable */
import { ref, computed, defineProps, defineEmits } from 'vue';
import { format, parseISO, addMinutes } from 'date-fns';
import { ru } from 'date-fns/locale';

interface EventData {
    id: number;
    title: string;
    date: string;
    description?: string;
    status?: 'live' | 'cancelled' | 'done' | 'pending';
    role: 'leading' | 'participating';
    sessionType?: string;
    bookingId?: number;
    mentorName?: string;
    mentorSurname?: string;
    mentorAvatar?: string;
    duration?: string;
    cost?: string;
    question?: string;
}

const props = defineProps<{
    modelValue: boolean;
    event: EventData | null;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void;
    (e: 'confirmed', event: EventData): void;
}>();

const dialog = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
});

const loading = ref(false);

const mentorName = computed(() => props.event?.mentorName || 'Имя');
const mentorSurname = computed(() => props.event?.mentorSurname || 'Фамилия');
const mentorAvatar = computed(() => props.event?.mentorAvatar || '/placeholder.svg?height=56&width=56');
const title = computed(() => props.event?.title || 'Сессия наставничества');
const duration = computed(() => props.event?.duration || '60 минут');
const cost = computed(() => props.event?.cost || '1500 руб');
const question = computed(() => props.event?.question || '');

const formattedDate = computed(() => {
    if (!props.event?.date) return '';
    const date = typeof props.event.date === 'string' ? parseISO(props.event.date) : props.event.date;
    return format(date, 'EEEE, dd.MM', { locale: ru });
});

const formattedTime = computed(() => {
    if (!props.event?.date) return '';
    const date = typeof props.event.date === 'string' ? parseISO(props.event.date) : props.event.date;
    const durationMinutes = parseInt(duration.value) || 60;
    const endTime = addMinutes(date, durationMinutes);
    return `${format(date, 'HH:mm')} - ${format(endTime, 'HH:mm')}`;
});

function close() {
    dialog.value = false;
}

async function confirm() {
    if (!props.event) return;
    loading.value = true;
    try {
        // Имитация асинхронного запроса (замените на реальный API-вызов)
        await new Promise((resolve) => setTimeout(resolve, 1000));
        emit('confirmed', props.event);
        close();
    } catch (error) {
        console.error('Ошибка при подтверждении:', error);
    } finally {
        loading.value = false;
    }
}
</script>

<style scoped>
.consultation-modal-card {
    border-radius: 12px;
    overflow: hidden;
    background: white;
    max-height: 90vh;
    overflow-y: auto;
}

.modal-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #1a1a1a;
}

.close-btn {
    font-size: 24px;
    cursor: pointer;
    color: #666;
}

/* Mentor Info Styles */
.mentor-info {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
}

.mentor-avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
}

.mentor-details {
    display: flex;
    flex-direction: column;
}

.mentor-name {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #2A2037;
}

.mentor-details p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1%;
    color: #2A2037;
}

/* Session Info Styles */
.session-info {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    height: 17rem;
    margin-bottom: 24px;
}

.session-info h3 {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #2A2037;
}

.info-grid {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.info-item {
    display: flex;
    flex-direction: column;
}

.label {
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #525A7D;
}

.value {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #2A2037;
}

/* Confirmation Styles */
.confirmation-title {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #2A2037;
}

.booking-details {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
}

.detail-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #525A7D;
}

.calendar-icon,
.clock-icon {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
}

.calendar-icon {
    background-image: url('../../../assets/images/icons/calendar-icon.svg');
    /* Убедитесь, что путь к иконке правильный */
}

.clock-icon {
    background-image: url('../../../assets/images/icons/clock-icon.svg');
    /* Убедитесь, что путь к иконке правильный */
}

/* Form Section Styles */
.form-section {
    margin-bottom: 24px;
}

.form-textarea {
    width: 100%;
    min-height: 200px;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    outline: none;
    font-weight: 500;
    font-size: 18px;
    color: #2A2037;
    background-color: #fff;
}

.question-card {
    min-height: 200px;
    border-radius: 12px;
    white-space: pre-wrap;
}

/* Footer Styles */
.modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.step-indicator {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #434966;
}

.button-group {
    display: flex;
    gap: 12px;
}

.back-btn {
    padding: 12px 24px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background: white;
    color: #666;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.back-btn:hover {
    background: #f5f5f5;
}

.confirm-btn {
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    background: #6b4ee6;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.confirm-btn:hover {
    background: #5b3ed6;
}

/* Responsive Styles */
@media (max-width: 960px) {
    .consultation-modal-card {
        max-height: 90vh;
        overflow-y: auto;
    }

    .booking-details {
        flex-direction: column;
        gap: 12px;
    }
}

@media (max-width: 768px) {
    .info-grid {
        grid-template-columns: 1fr;
    }

    .button-group {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .modal-title {
        font-size: 24px;
    }

    .confirmation-title {
        font-size: 24px;
    }

    .mentor-name {
        font-size: 20px;
    }

    .detail-item {
        font-size: 16px;
    }
}
</style>