<template>
    <v-dialog v-model="dialog" max-width="700px">
        <v-card style="border-radius: 20px;">
            <v-card-title class="text-h5 pb-2 pt-4 px-6">
                {{ isNewConsultationType ? 'Создать тип консультации' : 'Редактировать тип консультации' }}
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="px-6 py-4">
                <v-form @submit.prevent="saveConsultationType">
                    <!-- Основная информация -->
                    <div class="mb-6">
                        <v-text-field v-model="editedConsultationType.name" label="Название" variant="outlined"
                            density="comfortable" hide-details class="mb-4" />
                        <!-- <div style="display: flex; flex-direction: row; gap: 1fr;">  -->
                        <!-- <div class="d-flex mb-4">
                            <div class="flex-grow-1 mr-4">
                                <label class="text-subtitle-1 font-weight-medium d-block mb-2">Цена (руб.)</label>
                                <v-text-field v-model.number="eventData.price" type="number" min="0"
                                    placeholder="Бесплатно" variant="outlined" density="compact" hide-details />
                            </div>

                            <div class="flex-grow-1">
                                <label class="text-subtitle-1 font-weight-medium d-block mb-2">Участники</label>
                                <v-text-field v-model.number="eventData.participantLimit" type="number" min="1"
                                    placeholder="20" variant="outlined" density="compact" hide-details />
                            </div>
                        </div> -->
                        <div class="d-flex mb-4">

                            <v-text-field v-model.number="editedConsultationType.duration_minutes"
                                label="Длительность (мин)" type="number" variant="outlined" density="compact"
                                hide-details class="flex-grow-1 mr-4 mb-4" />

                            <v-text-field v-model.number="editedConsultationType.costs" label="Стоимость (руб.)"
                                type="number" variant="outlined" density="compact" hide-details  class="flex-grow-1 mb-4"/>
                        </div>
                        <!-- </div> -->
                    </div>

                    <!-- Заголовок расписания -->
                    <div class="d-flex align-center mb-4">
                        <h3 class="text-subtitle-1 font-weight-bold">Расписание</h3>
                        <v-spacer></v-spacer>
                        <v-btn prepend-icon="mdi-plus" variant="tonal" color="primary" size="small" @click="addSlot">
                            Добавить время
                        </v-btn>
                    </div>

                    <!-- Список слотов -->
                    <v-card v-for="(slot, index) in editedSlots" :key="index" variant="outlined" class="mb-4 slot-card">
                        <div v-if="slot.isOneTime" class="one-time-indicator"></div>
                        <v-card-text class="pa-4">
                            <!-- Тип слота (toggle между двумя опциями) -->
                            <div class="toggle-container mb-4">
                                <div class="toggle-option" :class="{ 'active': !slot.isOneTime }"
                                    @click="slot.isOneTime = false">
                                    С повторением
                                </div>

                                <v-switch v-model="slot.isOneTime" color="primary" hide-details density="compact" inset
                                    class="toggle-switch mx-2"></v-switch>

                                <div class="toggle-option" :class="{ 'active': slot.isOneTime }"
                                    @click="slot.isOneTime = true">
                                    Единоразово
                                </div>
                            </div>

                            <!-- Верхняя строка: время и дата -->
                            <div class="d-flex align-center no-wrap">
                                <div class="time-picker-container d-flex align-center mr-4" style="margin-bottom: 36px">
                                    <google-meet-time-picker v-model="slot.startTimeFormatted"
                                        @update:modelValue="updateEndTime(slot)" />
                                    <span class="time-separator mx-2">—</span>
                                    <google-meet-time-picker v-model="slot.endTimeFormatted"
                                        @update:modelValue="updateStartTime(slot)" />
                                </div>
                                <div class="date-field-container flex-grow-1">
                                    <v-text-field v-if="!slot.isOneTime" v-model="slot.repeatUntil" label="Повторять до"
                                        variant="outlined" density="compact" type="date" :min="todayDate" :max="maxDate"
                                        :rules="dateRules" @input="validateDateInput" class="date-field fixed-width" />

                                    <v-text-field v-else v-model="slot.slotDate" label="Дата проведения"
                                        variant="outlined" density="compact" type="date" :min="todayDate" :max="maxDate"
                                        :rules="dateRules" @input="validateDateInput"
                                        @update:modelValue="updateSlotTimes(slot)" class="date-field fixed-width" />
                                </div>

                                <v-btn icon variant="text" color="error" density="comfortable"
                                    @click="removeSlot(index)" class="ml-2" style="margin-bottom: 36px">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </div>

                            <!-- Дни недели (только для повторяющихся) -->
                            <div v-if="!slot.isOneTime" class="weekday-selector-row">
                                <v-label class="text-caption text-medium-emphasis mb-1 d-block">
                                    Дни недели
                                </v-label>
                                <weekday-selector v-model="slot.custom_weekdays" />
                            </div>
                        </v-card-text>
                    </v-card>

                    <!-- Сообщение, если нет слотов -->
                    <v-sheet v-if="editedSlots.length === 0" class="text-center pa-6 rounded bg-grey-lighten-4">
                        <v-icon icon="mdi-calendar-clock" size="large" class="mb-2 text-grey"></v-icon>
                        <div class="text-body-1 text-medium-emphasis">
                            Нет добавленных слотов. Нажмите "Добавить время", чтобы создать новый слот.
                        </div>
                    </v-sheet>
                </v-form>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="pa-4">
                <v-spacer></v-spacer>
                <v-btn variant="text" @click="dialog = false" class="mr-2">
                    Отмена
                </v-btn>
                <v-btn color="primary" variant="elevated" @click="saveConsultationType">
                    Сохранить
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
/* eslint-disable */
import { ref, watch, computed, defineEmits, defineProps } from 'vue';
import WeekdaySelector from './WeekdaySelector.vue';
import GoogleMeetTimePicker from './SimpleTimePicker.vue';
import { updateConsultationTypeApi, createConsultationTypeApi, createSlotApi, updateSlotApi, deleteSlotApi, createRecurrentSlotsApi, deleteSeriesApi } from '@/services/api';
import { useStore } from 'vuex'

const store = useStore()
const myId = computed(() => store.getters['userId'])

const props = defineProps({
    modelValue: Boolean,
    consultationType: {
        type: Object,
        default: () => ({})
    },
    slots: {
        type: Array,
        default: () => []
    }
});

const emit = defineEmits(['update:modelValue', 'update']);

const dialog = ref(false);
const editedConsultationType = ref({ ...props.consultationType });
const editedSlots = ref([]);
const slotsToDelete = ref([]);

const isNewConsultationType = computed(() => !props.consultationType.id);

// Дни недели с понедельника по пятницу
const defaultWeekdays = ['mon', 'tue', 'wed', 'thu', 'fri'];

// const initializeEditedSlots = () => {
//     editedSlots.value = props.slots.map(slot => {
//         const startDate = new Date(slot.start_time);
//         const endDate = new Date(slot.end_time);
//         const isOneTime = slot.repeat_type === 'none';

//         // Извлекаем дату из slot.repeat_until и преобразуем в YYYY-MM-DD
//         let repeatUntilValue = null;
//         if (slot.repeat_until && !isOneTime) {
//             const repeatUntilDate = new Date(slot.repeat_until);
//             repeatUntilValue = repeatUntilDate.toISOString().split('T')[0]; // Берем только дату
//         }

//         return {
//             ...slot,
//             isOneTime: isOneTime,
//             custom_weekdays: slot.custom_weekdays || [...defaultWeekdays],
//             startTimeFormatted: `${startDate.getHours().toString().padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')}`,
//             endTimeFormatted: `${endDate.getHours().toString().padStart(2, '0')}:${endDate.getMinutes().toString().padStart(2, '0')}`,
//             slotDate: isOneTime ? startDate.toISOString().split('T')[0] : null,
//             repeatUntil: repeatUntilValue || (!isOneTime ? getDefaultRepeatUntil() : null),
//             markedForDeletion: false
//         };
//     });
// };

const initializeEditedSlots = () => {
    editedSlots.value = props.slots.map(slot => {
        const startDate = new Date(slot.start_time); // Предполагаем, что start_time в UTC
        const endDate = new Date(slot.end_time);     // Предполагаем, что end_time в UTC
        const isOneTime = slot.repeat_type === 'none';

        // Преобразуем UTC в локальное время пользователя
        const localStartDate = new Date(startDate.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));
        const localEndDate = new Date(endDate.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));

        let repeatUntilValue = null;
        if (slot.repeat_until && !isOneTime) {
            const repeatUntilDate = new Date(slot.repeat_until); // В UTC
            // Преобразуем repeat_until в локальную дату (только YYYY-MM-DD)
            const localRepeatUntilDate = new Date(repeatUntilDate.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));
            repeatUntilValue = localRepeatUntilDate.toISOString().split('T')[0]; // YYYY-MM-DD
        }

        return {
            ...slot,
            isOneTime: isOneTime,
            custom_weekdays: slot.custom_weekdays || [...defaultWeekdays],
            startTimeFormatted: `${localStartDate.getHours().toString().padStart(2, '0')}:${localStartDate.getMinutes().toString().padStart(2, '0')}`,
            endTimeFormatted: `${localEndDate.getHours().toString().padStart(2, '0')}:${localEndDate.getMinutes().toString().padStart(2, '0')}`,
            slotDate: isOneTime ? localStartDate.toISOString().split('T')[0] : null,
            repeatUntil: repeatUntilValue || (!isOneTime ? getDefaultRepeatUntil() : null),
            markedForDeletion: false
        };
    });
};

const getDefaultRepeatUntil = () => {
    const date = new Date();
    date.setMonth(date.getMonth() + 3); // По умолчанию повторять 3 месяца
    return date.toISOString().split('T')[0];
};

const getTodayDate = () => {
    return new Date().toISOString().split('T')[0];
};

const todayDate = computed(() => {
    return new Date().toISOString().split('T')[0];
});

const maxDate = computed(() => {
    const date = new Date();
    date.setDate(date.getDate() + 400);
    return date.toISOString().split('T')[0];
});

const dateRules = computed(() => [
    (v) => !!v || 'Поле даты обязательно для заполнения',
    (v) => (v && v >= todayDate.value) || 'Дата не может быть раньше сегодняшнего дня',
    (v) => (v && v <= maxDate.value) || 'Пожалуйста, выберите более раннюю дату',
]);

const validateDateInput = (event) => {
    const value = event.target.value;
    if (value.length > 10) {
        event.target.value = value.slice(0, 10);
    }
    const [year] = value.split('-');
    if (year && year.length > 4) {
        event.target.value = value.slice(0, 4) + value.slice(5);
    }
};

watch(() => props.modelValue, (val) => {
    dialog.value = val;
    if (val) {
        editedConsultationType.value = { ...props.consultationType };
        initializeEditedSlots();
        slotsToDelete.value = [];
    }
});

watch(dialog, (val) => {
    if (!val) emit('update:modelValue', false);
});

// const updateEndTime = (slot) => {
//     const [hours, minutes] = slot.startTimeFormatted.split(':');
//     const startDate = new Date();
//     startDate.setUTCHours(parseInt(hours), parseInt(minutes));
//     const endDate = new Date(startDate.getTime() + editedConsultationType.value.duration_minutes * 60000);
//     slot.endTimeFormatted = `${endDate.getUTCHours().toString().padStart(2, '0')}:${endDate.getMinutes().toString().padStart(2, '0')}`;
// };

// const updateStartTime = (slot) => {
//     const [hours, minutes] = slot.endTimeFormatted.split(':');
//     const endDate = new Date();
//     endDate.setUTCHours(parseInt(hours), parseInt(minutes));
//     const startDate = new Date(endDate.getTime() - editedConsultationType.value.duration_minutes * 60000);
//     slot.startTimeFormatted = `${startDate.getUTCHours().toString().padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')}`;
// };

const updateEndTime = (slot) => {
    const [hours, minutes] = slot.startTimeFormatted.split(':');
    const startDate = new Date();
    startDate.setHours(parseInt(hours), parseInt(minutes));
    const endDate = new Date(startDate.getTime() + editedConsultationType.value.duration_minutes * 60000);
    slot.endTimeFormatted = `${endDate.getHours().toString().padStart(2, '0')}:${endDate.getMinutes().toString().padStart(2, '0')}`;
};

const updateStartTime = (slot) => {
    const [hours, minutes] = slot.endTimeFormatted.split(':');
    const endDate = new Date();
    endDate.setHours(parseInt(hours), parseInt(minutes));
    const startDate = new Date(endDate.getTime() - editedConsultationType.value.duration_minutes * 60000);
    slot.startTimeFormatted = `${startDate.getHours().toString().padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')}`;
};

const updateSlotTimes = (slot) => {
    // Обновляем времена при изменении даты для разовых слотов
    if (slot.isOneTime && slot.slotDate) {
        // Логика обновления времен при необходимости
    }
};

// const validateDateInput = (event) => {
//     // Ограничиваем ввод даты (год не более 4 символов)
//     const value = event.target.value;
//     if (value && value.length > 10) {
//         event.target.value = value.slice(0, 10);
//     }
// };

watch(editedSlots, (newSlots) => {
    newSlots.forEach(slot => {
        watch(() => slot.startTimeFormatted, () => updateEndTime(slot));
        watch(() => slot.endTimeFormatted, () => updateStartTime(slot));

        // Инициализация значений по умолчанию при изменении isOneTime
        watch(() => slot.isOneTime, (isOneTime) => {
            if (isOneTime) {
                slot.slotDate = getTodayDate();
                slot.repeatUntil = null;
            } else {
                slot.slotDate = null;
                slot.repeatUntil = getDefaultRepeatUntil();
                if (!slot.custom_weekdays || slot.custom_weekdays.length === 0) {
                    slot.custom_weekdays = [...defaultWeekdays];
                }
            }
        });
    });
}, { deep: true });

const addSlot = () => {
    const startDate = new Date();
    startDate.setUTCHours(9, 0, 0, 0);
    const endDate = new Date(startDate.getTime() + editedConsultationType.value.duration_minutes * 60000);

    editedSlots.value.push({
        startTimeFormatted: `09:00`,
        endTimeFormatted: `${endDate.getUTCHours().toString().padStart(2, '0')}:${endDate.getMinutes().toString().padStart(2, '0')}`,
        isOneTime: false,
        custom_weekdays: [...defaultWeekdays],
        slotDate: null,
        repeatUntil: getDefaultRepeatUntil(),
        markedForDeletion: false
    });
};

const removeSlot = (index) => {
    const slot = editedSlots.value[index];
    if (slot.id) {
        slot.markedForDeletion = true;
        slotsToDelete.value.push(slot);
    }
    editedSlots.value.splice(index, 1);
};

const normalizeWeekdays = (weekdays) => {
    const validDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    return weekdays.filter(day => validDays.includes(day));
};

// const saveConsultationType = async () => {
//     try {
//         let savedConsultationType;

//         if (isNewConsultationType.value) {
//             savedConsultationType = await createConsultationTypeApi(editedConsultationType.value);
//         } else {
//             savedConsultationType = await updateConsultationTypeApi(editedConsultationType.value);
//         }

//         // Сохранение или обновление слотов
//         for (const slot of editedSlots.value) {
//             if (!slot.markedForDeletion) {
//                 let startTime, endTime;

//                 if (slot.isOneTime && slot.slotDate) {
//                     // Для разового слота используем конкретную дату
//                     const [startHours, startMinutes] = slot.startTimeFormatted.split(':');
//                     const [endHours, endMinutes] = slot.endTimeFormatted.split(':');

//                     startTime = new Date(slot.slotDate);
//                     startTime.setUTCHours(parseInt(startHours), parseInt(startMinutes), 0, 0);

//                     endTime = new Date(slot.slotDate);
//                     endTime.setUTCHours(parseInt(endHours), parseInt(endMinutes), 0, 0);
//                 } else {
//                     // Для повторяющихся слотов используем текущую дату
//                     const today = new Date();
//                     const [startHours, startMinutes] = slot.startTimeFormatted.split(':');
//                     const [endHours, endMinutes] = slot.endTimeFormatted.split(':');

//                     startTime = new Date(today);
//                     startTime.setUTCHours(parseInt(startHours), parseInt(startMinutes), 0, 0);

//                     endTime = new Date(today);
//                     endTime.setUTCHours(parseInt(endHours), parseInt(endMinutes), 0, 0);
//                 }

//                 const slotData = {
//                     start_time: startTime.toISOString(),
//                     end_time: endTime.toISOString(),
//                     consultation_type: savedConsultationType.id,
//                     repeat_type: slot.isOneTime ? 'none' : 'custom',
//                     custom_weekdays: slot.isOneTime ? [] : normalizeWeekdays(slot.custom_weekdays),
//                     user_id: 167,
//                     recurrence_id: slot.recurrence_id,
//                     repeat_until: slot.isOneTime ? null : new Date(slot.repeatUntil).toISOString(),
//                 };

//                 if (slot.id) {
//                     await updateSlotApi(slot.id, slotData);
//                 } else {
//                     if (slot.isOneTime) {
//                         await createSlotApi(slotData);
//                     } else {
//                         await createRecurrentSlotsApi(slotData);
//                     }
//                 }
//             }
//         }

//         // Удаление слотов, помеченных для удаления
//         for (const slot of slotsToDelete.value) {
//             if (slot.id) {
//                 await deleteSlotApi(slot.id, props.consultationType.user);
//             }
//         }

//         emit('update');
//         dialog.value = false;
//     } catch (error) {
//         console.error('❌ Ошибка при сохранении консультации:', error);
//     }
// };

const saveConsultationType = async () => {
    try {
        let savedConsultationType;

        if (isNewConsultationType.value) {
            savedConsultationType = await createConsultationTypeApi({...editedConsultationType.value, user_id: myId.value});
        } else {
            savedConsultationType = await updateConsultationTypeApi({...editedConsultationType.value, user_id: myId.value});
        }

        for (const slot of editedSlots.value) {
            if (!slot.markedForDeletion) {
                let startTime, endTime;

                const [startHours, startMinutes] = slot.startTimeFormatted.split(':');
                const [endHours, endMinutes] = slot.endTimeFormatted.split(':');

                if (slot.isOneTime) {
                    if (!slot.slotDate) {
                        console.error('Дата не указана для единоразового слота', slot);
                        throw new Error('Укажи дату для единоразового слота, бери и делай!');
                    }

                    // Разовый слот
                    const localStart = new Date(slot.slotDate);
                    localStart.setHours(parseInt(startHours), parseInt(startMinutes), 0, 0);
                    const utcStart = new Date(localStart.getTime());
                    startTime = utcStart;

                    const localEnd = new Date(slot.slotDate);
                    localEnd.setHours(parseInt(endHours), parseInt(endMinutes), 0, 0);
                    const utcEnd = new Date(localEnd.getTime());
                    endTime = utcEnd;
                } else {
                    // Повторяющийся слот
                    const today = new Date();
                    const localStart = new Date(today);
                    localStart.setHours(parseInt(startHours), parseInt(startMinutes), 0, 0);
                    const utcStart = new Date(localStart.getTime());
                    startTime = utcStart;

                    const localEnd = new Date(today);
                    localEnd.setHours(parseInt(endHours), parseInt(endMinutes), 0, 0);
                    const utcEnd = new Date(localEnd.getTime());
                    endTime = utcEnd;
                }

                // Преобразуем repeatUntil в UTC
                let repeatUntilInUTC = null;
                if (!slot.isOneTime && slot.repeatUntil) {
                    const localRepeatUntil = new Date(slot.repeatUntil);
                    // const utcRepeatUntil = new Date(localRepeatUntil.getTime());
                    const utcRepeatUntil = new Date(localRepeatUntil.getTime() + (localRepeatUntil.getTimezoneOffset() * 60000));
                    repeatUntilInUTC = utcRepeatUntil.toISOString().split('T')[0];
                }

                const slotData = {
                    start_time: startTime.toISOString(),
                    end_time: endTime.toISOString(),
                    consultation_type: savedConsultationType.id,
                    repeat_type: slot.isOneTime ? 'none' : 'custom',
                    custom_weekdays: slot.isOneTime ? [] : normalizeWeekdays(slot.custom_weekdays),
                    user_id: myId.value,
                    recurrence_id: slot.recurrence_id,
                    repeat_until: repeatUntilInUTC,
                    ...(slot.isOneTime && { slotDate: slot.slotDate })
                };

                if (slot.id) {
                    await updateSlotApi(slot.id, slotData);
                } else {
                    if (slot.isOneTime) {
                        await createSlotApi(slotData);
                    } else {
                        // await createSlotApi(slotData);
                        await createRecurrentSlotsApi(slotData);
                    }
                }
            }
        }

        for (const slot of slotsToDelete.value) {
            if (slot.id) {
                if (slot.isOneTime) {
                    // Удаление разового слота
                    await deleteSlotApi(slot.id, props.consultationType.user);
                } else {
                    // Удаление серии повторяющихся слотов
                    await deleteSeriesApi(slot.id, props.consultationType.user);
                }
            }
        }

        emit('update');
        dialog.value = false;
    } catch (error) {
        console.error('❌ Ошибка при сохранении:', error);
        alert(error.message || 'Всё сломалось, чини!');
    }
};
</script>

<style scoped>
.time-separator {
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: #6b7280;
}

.slot-card {
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.12);
    will-change: transform, opacity, border;
}

/* .slot-card-one-time {
    border-left: 4px solid var(--v-theme-primary);
} */

.one-time-indicator {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: var(--v-theme-primary);
}

.time-picker-container {
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    padding: 4px 8px;
}

/* Контейнер для полей времени и даты */
.no-wrap {
    display: flex;
    /* Используем flexbox */
    flex-wrap: nowrap;
    /* Запрещаем перенос элементов */
    align-items: flex-start;
    /* Выравнивание по верхнему краю */
}

/* Контейнер для полей времени */
.time-picker-container {
    display: flex;
    /* Flexbox для внутреннего выравнивания */
    align-items: center;
    /* Центрирование содержимого внутри */
    padding: 4px 8px;
    /* Отступы для единообразия */
    flex-shrink: 0;
    /* Запрещаем сжатие */
}

/* Контейнер для поля даты */
.date-field-container {
    flex-grow: 1;
    /* Позволяем контейнеру расти */
    display: flex;
    /* Flexbox для внутренней компоновки */
    align-items: flex-start;
    /* Выравнивание даты по верху */
}

/* Поле даты */
.date-field.fixed-width {
    width: 180px;
    /* Фиксированная ширина */
    min-width: 0;
    /* Убираем минимальную ширину по умолчанию */
}

/* Стили для сообщений об ошибках */
.date-field :deep(.v-messages) {
    min-height: 30px;
    /* Зарезервированное место для двух строк текста */
    display: flex;
    align-items: flex-start;
    /* Выравнивание текста по верху */
}

/* Текст сообщения об ошибке */
.date-field :deep(.v-messages__message) {
    white-space: pre-wrap;
    /* Разрешаем перенос текста */
    max-width: 100%;
    /* Ограничение ширины контейнером */
    line-height: 1.2;
    /* Улучшение читаемости */
    font-size: 12px;
    /* Компактный шрифт */
}

/* Адаптивность для маленьких экранов */
@media (max-width: 600px) {
    .no-wrap {
        flex-direction: column;
        /* Столбец на маленьких экранах */
        align-items: flex-start;
    }

    .time-picker-container {
        margin-right: 0;
        margin-bottom: 8px;
        /* Отступ снизу вместо справа */
    }

    .date-field.fixed-width {
        width: 100%;
        /* Полная ширина на маленьких экранах */
    }
}

/*
.date-field {
    min-width: 180px;
    max-width: 180px;
}

.date-field :deep(.v-messages__message) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}
*/
.weekday-selector-row {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    padding: 4px;
    /* max-width: 400px; */
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.toggle-option {
    padding: 6px 12px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    color: rgba(0, 0, 0, 0.6);
    user-select: none;
}

.toggle-option.active {
    background-color: rgba(var(--v-theme-primary), 0.1);
    color: rgb(var(--v-theme-primary));
    /* font-weight: 600; */
}

.toggle-switch {
    transform: scale(0.7);
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.toggle-switch :deep(.v-switch__track) {
    opacity: 0.5;
    height: 30px !important;
}

.toggle-switch :deep(.v-switch__thumb) {
    height: 16px !important;
    width: 16px !important;
}

@media (max-width: 600px) {
    .date-field-container {
        width: 100%;
    }

    .toggle-container {
        max-width: 100%;
    }
}
</style>