<template>
    <div>
      <transition name="fade" @before-leave="beforeLeave" @leave="leave">
        <div v-if="loading" class="loading">
          <JumpingDotLoaderWithShadows />
        </div>
      </transition>
      
      <div v-if="!loading" id="events">
        <Spacer-32 />
        
        <!-- Фильтры -->
        <EventFilters 
          :searchQuery="searchQuery"
          :categories="categories"
          :selectedCategories="selectedCategories"
          :minPrice="minPrice"
          :maxPrice="maxPrice"
          :sortingOption="sortingOption"
          :isMobile="isMobile"
          @update:searchQuery="searchQuery = $event"
          @toggle-category="toggleCategory"
          @update:minPrice="minPrice = $event"
          @update:maxPrice="maxPrice = $event"
          @update:sortingOption="sortingOption = $event"
          @reset="resetFilters"
        />
        
        <!-- Сетка мероприятий -->
        <div class="masonry-container">
          <!-- Каждая колонка -->
          <div 
            class="masonry-column" 
            v-for="n in columnCount" 
            :key="n"
          >
            <div 
              v-for="event in getColumnEvents(n)" 
              :key="event.id || index"
              class="event-card-wrapper"
            >
              <link-to-event :id="event.id">
                <EventCard 
                  :event_title="event.title" 
                  :event_date="event.date"
                  :event_price="event.price" 
                  :event_imageUrl="event.image_url"
                  :event_totalSpots="event.total_spots" 
                  :id="event.id" 
                  :event_id_mentor="event.mentor"
                />
              </link-to-event>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
/* eslint-disable */ 

  import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
  import EventCard from "@/components/EventCard.vue";
  import JumpingDotLoaderWithShadows from "@/components/Animations/JumpingDotLoaderWithShadows.vue";
  import EventFilters from "@/components/filters/EventFilters.vue";
  import LinkToEvent from "@/components/Links/LinkToEvent.vue";
  import Spacer32 from '@/components/Spacers/SpacerModule32.vue';
  import { useEvents } from '@/services/composables/useEvents';
  
  // Используем композабл для логики мероприятий
  const {
    events,
    filteredEvents,
    loading,
    error,
    hasNext,
    searchQuery,
    selectedCategories,
    minPrice,
    maxPrice,
    sortingOption,
    fetchEvents,
    loadMoreEvents,
    resetFilters,
    toggleCategory
} = useEvents();
  
  // Доступные категории
  const categories = ref([
    'Все категории',
    'Здоровье',
    'Карьера',
    'Образование',
    'Красота',
    'Хобби',
    'Просвещение'
  ]);
  
  // Состояние для адаптивного дизайна
  const isMobile = ref(false);
  const columnCount = ref(3);

//   const setupScrollHandler = () => {
//     const handleScroll = () => {
//         const bottomOfWindow =
//             document.documentElement.scrollTop + window.innerHeight >=
//             document.documentElement.offsetHeight - 200;

//         if (bottomOfWindow && hasNext.value && !loading.value) {
//             loadMoreEvents();
//         }
//     };

//     window.addEventListener('scroll', handleScroll);

//     onBeforeUnmount(() => {
//         window.removeEventListener('scroll', handleScroll);
//     });
// };
  
  // Получить мероприятия для конкретной колонки
  const getColumnEvents = (columnIndex) => {
    return filteredEvents.value.filter((_, i) => i % columnCount.value === columnIndex - 1);
  };
  
  // Хуки для анимации загрузки
  const beforeLeave = (el) => {
    // Хук для анимации загрузки
  };
  
  const leave = (el, done) => {
    // Хук для анимации загрузки
    setTimeout(done, 500);
  };
  
  // Обработка изменения размера окна
  const handleResize = () => {
    isMobile.value = window.innerWidth <= 1024;
    columnCount.value = isMobile.value ? 2 : 3;
  };
  
  // Хуки жизненного цикла
  onMounted(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    // setupScrollHandler();
  });
  
  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
  });
  </script>
  
  <style scoped>
  .loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 0.5s;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  
  #events {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .masonry-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2vw;
    margin-left: 2vw;
    margin-right: 2vw;
  }
  
  .masonry-column {
    flex: 0 0 calc(33.33% - 2vw);
    max-width: calc(33.33% - 2vw);
  }
  
  .event-card-wrapper {
    margin-bottom: 2rem;
  }
  
  @media (max-width: 1024px) {
    .masonry-container {
      gap: 4vw;
      margin-left: 1vw;
      margin-right: 1vw;
    }
  
    .masonry-column {
      flex: 0 0 calc(50% - 2vw);
      max-width: calc(50% - 2vw);
    }
  }
  </style>