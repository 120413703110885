<template>
    <div class="experience-block" ref="blockRef">
        <div class="experience-header">
            <div class="experience-header-text">{{ title }}</div>
            <span class="items-count">{{ items.length }}</span>
        </div>

        <div class="experience-content" ref="contentRef">
            <div v-for="(item, index) in visibleItems" :key="index" class="experience-item" ref="itemRefs">
                <div class="date">{{ item.dateRange }}</div>
                <div class="position">{{ item.position }}</div>
                <div class="company">{{ item.company }}</div>
            </div>
        </div>

        <button v-if="items.length > initialItemsCount" @click="openModal" class="show-more-btn">
            Показать всё
        </button>

        <teleport to="body">
            <transition name="modal">
                <div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
                    <div class="modal-content platforma-font" @click.stop>
                        <button @click="closeModal" class="close-btn">&times;</button>
                        <h2>{{ title }}</h2>
                        <div v-for="(item, index) in items" :key="index" class="experience-item">
                            <div class="date">{{ item.dateRange }}</div>
                            <div class="position">{{ item.position }}</div>
                            <div class="company">{{ item.company }}</div>
                        </div>
                    </div>
                </div>
            </transition>
        </teleport>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType, /*onMounted, watch */} from 'vue'

interface ExperienceItem {
    dateRange: string;
    position: string;
    company: string;
}

export default defineComponent({
    name: 'ExperienceBlockDesktop',
    props: {
        title: {
            type: String,
            default: 'Опыт работы 10 лет'
        },
        items: {
            type: Array as PropType<ExperienceItem[]>,
            default: () => ([])
        },
        initialItemsCount: {
            type: Number,
            default: 1
        },
        parentHeight: {
            type: Number,
            default: 200
        },
    },
    setup(props) {
        const isModalOpen = ref(false);
        const blockRef = ref<HTMLElement | null>(null);
        const contentRef = ref<HTMLElement | null>(null);
        const itemRefs = ref<HTMLElement[]>([]);

        const visibleItems = computed(() => props.items.slice(0, props.initialItemsCount));

        const openModal = () => {
            isModalOpen.value = true;
            document.body.style.overflow = 'hidden';
        };

        const closeModal = () => {
            isModalOpen.value = false;
            document.body.style.overflow = '';
        };

        // onMounted(() => {
        //     // if (blockRef.value && blockRef.value.parentElement) {
        //     //     blockRef.value.style.height = `${blockRef.value.parentElement.offsetHeight}px`;
        //     // }
        // });

        // watch(() => props.items, () => {
        //     if (blockRef.value && blockRef.value.parentElement) {
        //         blockRef.value.style.height = `${blockRef.value.parentElement.offsetHeight}px`;
        //     }
        // }, { deep: true });

        return {
            isModalOpen,
            openModal,
            closeModal,
            blockRef,
            contentRef,
            itemRefs,
            visibleItems
        };
    },
})
</script>

<style scoped>
/* Глобальные стили */
@font-face {
    font-family: 'PlatformaFont';
    src: url('/root/Platforma_maxim/Platforma/static/frontend/vueapp/src/assets/fonts/TildaSans-VF_TTF_Web/TildaSans-VF.ttf') format('truetype');
}

.platforma-font {
    font-family: 'PlatformaFont', sans-serif;
}


.experience-block {
    background-color: white;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: 224px;
    overflow: hidden;
    font-family: 'PlatformaFont', sans-serif;
    
}

.experience-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.experience-header h2 {
    font-size: 24px;
    font-weight: 600;
    color: #2A2037;
    margin: 0;
}

.items-count {
    background: #4a5568;
    color: white;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 14px;
}

.experience-content {
    flex-grow: 1;
    overflow-y: auto;
}

.experience-item {
    margin-bottom: 20px;
}

.date {
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
}

.position {
    font-size: 18px;
    font-weight: 600;
    color: #2A2037;
    margin-bottom: 2px;
}

.company {
    font-size: 16px;
    color: #666;
}

.show-more-btn {
    background: none;
    border: none;
    color: #5850ec;
    font-size: 14px;
    cursor: pointer;
    padding: 0;
    margin-top: 10px;
    transition: color 0.3s ease;
}

.show-more-btn:hover {
    color: #4338ca;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 32px;
    border-radius: 12px;
    max-width: 600px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    font-family: 'PlatformaFont', sans-serif;
}

.modal-content h2 {
    font-size: 24px;
    font-weight: 600;
    color: #2A2037;
    margin-bottom: 20px;
}

.modal-content .experience-item {
    margin-bottom: 20px;
}

.modal-content .date {
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
}

.modal-content .position {
    font-size: 18px;
    font-weight: 600;
    color: #2A2037;
    margin-bottom: 2px;
}

.modal-content .company {
    font-size: 16px;
    color: #666;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #2A2037;
}

/* Анимация для плавного появления модального окна */
.modal-enter-active,
.modal-leave-active {
    transition: all 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
    opacity: 0;
}

/* .modal-enter-from .modal-content,
.modal-leave-to .modal-content {
    transform: scale(0.9);
} */

.experience-header-text {
    font-size: 32px;
    font-weight: 400;
    color: #2A2037;
}
</style>

