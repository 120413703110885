<template>
    <div class="loader-container">
        <div class="flex justify-center items-center ">
            <h1 class="text-6xl font-bold">
                <span v-for="(letter, index) in letters" :key="letter + index" class="inline-block"
                    :class="[`letter-${index + 1}`]">
                    {{ letter }}
                </span>
            </h1>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const text = "ФОРМА";
const letters = computed(() => text.split(''));
</script>

<style scoped>
.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--neutral-neutral100, #F3F4F5);
    z-index: 25; 
    opacity: 1;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    visibility: visible;

}

h1 {
    color: #998BF8;
}



@keyframes pulse {

    0%,
    100% {
        opacity: 1;
        transform: scale(1);
    }

    50% {
        opacity: 0.5;
        transform: scale(0.95);
    }
}

.letter-1,
.letter-2,
.letter-3,
.letter-4,
.letter-5 {
    animation: pulse 1.5s infinite;
}

.letter-1 {
    animation-delay: 0s;
}

.letter-2 {
    animation-delay: 0.3s;
}

.letter-3 {
    animation-delay: 0.6s;
}

.letter-4 {
    animation-delay: 0.9s;
}

.letter-5 {
    animation-delay: 1.2s;
}
</style>