<template>
    <div class="auth-wrapper">
        <v-card class="auth-card" elevation="0">
            <v-fade-transition mode="out-in">
                <div v-if="!showVerification" key="login" class="auth-content">
                    <h1 class="auth-title">Вход в аккаунт</h1>
                    <p class="auth-description">Введите email, чтобы получить код для входа.</p>

                    <v-form @submit.prevent="handleRequestPasscode" ref="loginForm">
                        <v-text-field v-model="email" :rules="emailRules" label="Email" prepend-inner-icon="mdi-email"
                            variant="outlined" class="mb-6" hide-details="auto"
                            placeholder="sergey@mail.ru"></v-text-field>

                        <v-btn block color="#7b65f7" size="large" type="submit" class="white--text mb-8"
                            :loading="loading" height="56">
                            Получить код
                        </v-btn>
                    </v-form>

                    <div class="register-link">
                        <span>Нет аккаунта? </span>
                        <a href="/register" class="register-link-text">Зарегистрироваться</a>
                    </div>
                </div>

                <div v-else key="verification" class="auth-content">
                    <h1 class="auth-title">Введите код</h1>
                    <p class="auth-description">Мы отправили код на {{ email }}</p>

                    <v-otp-input v-model="otpCode" :length="4" variant="underlined"
                        @finish="handleVerifyPasscode"></v-otp-input>

                    <v-btn block variant="text" :disabled="resendTimer > 0" class="mb-4" @click="resendCode">
                        Отправить код повторно через {{ resendTimer }} сек.
                    </v-btn>

                    <v-btn block variant="text" color="#7b65f7" class="mb-8" @click="showVerification = false">
                        Войти с другим email
                    </v-btn>
                </div>
            </v-fade-transition>
        </v-card>
    </div>
</template>

<script setup lang="ts">
/* eslint-disable */
import { ref, onMounted, onUnmounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

axios.defaults.withCredentials = true;

const store = useStore();
const router = useRouter();

const email = ref('');
const otpCode = ref('');
const loading = ref(false);
const showVerification = ref(false);
const resendTimer = ref(30);
let timerInterval = null;

const emailRules = [
    (v: string) => !!v || 'Email обязателен',
    (v: string) => /.+@.+\..+/.test(v) || 'Неверный формат email',
];

const handleRequestPasscode = async () => {
    loading.value = true;
    try {
        await axios.post('/auth/request_login_passcode/', { email: email.value });
        showVerification.value = true;
        startResendTimer();
    } catch (error) {
        console.error('Ошибка при запросе кода:', error);
        alert('Пользователь не найден или произошла ошибка');
    } finally {
        loading.value = false;
    }
};

const handleVerifyPasscode = async (code: string) => {
    loading.value = true;
    try {
        await store.dispatch('login', { email: email.value, passcode: code });
        router.push('/AllMentors');
    } catch (error) {
        console.error('Ошибка при входе:', error);
        alert('Неверный код или ошибка сервера');
    } finally {
        loading.value = false;
    }
};

const startResendTimer = () => {
    resendTimer.value = 30;
    if (timerInterval) clearInterval(timerInterval);
    timerInterval = setInterval(() => {
        if (resendTimer.value > 0) resendTimer.value--;
        else clearInterval(timerInterval);
    }, 1000);
};

const resendCode = async () => {
    await handleRequestPasscode();
};

onUnmounted(() => {
    if (timerInterval) clearInterval(timerInterval);
});
</script>

<!-- <script setup lang="ts">
 /* eslint-disable */
import { ref, onMounted, onUnmounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

axios.defaults.withCredentials = true;

const store = useStore();
const router = useRouter();

const email = ref('');
const otpCode = ref('');
const loading = ref(false);
const showVerification = ref(false);
const resendTimer = ref(30);
let timerInterval = null;

const emailRules = [
    (v: string) => !!v || 'Email обязателен',
    (v: string) => /.+@.+\..+/.test(v) || 'Неверный формат email',
];

const handleRequestPasscode = async () => {
    loading.value = true;
    try {
        await axios.post('/auth/request_login_passcode/', { email: email.value });
        showVerification.value = true;
        startResendTimer();
    } catch (error) {
        console.error('Ошибка при запросе кода:', error);
        alert('Пользователь не найден или произошла ошибка');
    } finally {
        loading.value = false;
    }
};

const handleVerifyPasscode = async (code: string) => {
    loading.value = true;
    try {
        await axios.post('/verify-passcode/VerifyMail/', { email: email.value, passcode: code });
        await store.dispatch('fetchUser'); // Получаем данные пользователя после входа
        router.push('/AllMentors');
    } catch (error) {
        console.error('Ошибка при проверке кода:', error);
        alert('Неверный код или ошибка сервера');
    } finally {
        loading.value = false;
    }
};

const startResendTimer = () => {
    resendTimer.value = 30;
    if (timerInterval) clearInterval(timerInterval);
    timerInterval = setInterval(() => {
        if (resendTimer.value > 0) resendTimer.value--;
        else clearInterval(timerInterval);
    }, 1000);
};

const resendCode = async () => {
    await handleRequestPasscode();
};

onUnmounted(() => {
    if (timerInterval) clearInterval(timerInterval);
});
</script> -->



<style scoped>
.auth-wrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background-color: #f5f5f5;
}

.auth-card {
    width: 100%;
    max-width: 480px;
    border-radius: 10px;
    background-color: white;
    padding: 32px;
}

.auth-title {
    font-size: 32px;
    font-weight: bold;
    color: #525a7d;
    text-align: center;
    margin-bottom: 16px;
}

.auth-description {
    font-size: 16px;
    line-height: 1.5;
    color: #525a7d;
    text-align: center;
    margin-bottom: 32px;
}

.register-link {
    text-align: center;
    font-size: 16px;
    color: #525a7d;
}

.register-link-text {
    color: #7b65f7;
    text-decoration: none;
}
</style>
