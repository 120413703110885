import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "personal-consultation" }
const _hoisted_2 = { class: "personal-consultation-text" }
const _hoisted_3 = { class: "personal-consultation-second-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.consultation.name), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.consultation.duration_minutes) + " минут", 1),
    _createElementVNode("div", {
      class: "personal-consultation-btn",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onBookConsultation && _ctx.onBookConsultation(...args)))
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "personal-consultation-btn-text" }, "Записаться", -1)
    ]))
  ]))
}