<template>
    <div class="HomeHow">
        <div class="title">
            Плюсы работать с нами
        </div>
        <Spacer64 />
        <div class="benefits">
            <div class="benefit-card">
                <div class="benefit-icon">
                    <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M23.2615 19.1158C25.2027 20.091 26.867 21.6559 28.0818 23.6128C28.3224 24.0004 28.4427 24.1941 28.4843 24.4624C28.5688 25.0077 28.1959 25.678 27.6881 25.8938C27.4382 26 27.1571 26 26.5948 26M20.5948 13.3763C22.5705 12.3945 23.9282 10.3558 23.9282 8C23.9282 5.64419 22.5705 3.60548 20.5948 2.62368M17.9282 8C17.9282 11.3137 15.2419 14 11.9282 14C8.61446 14 5.92817 11.3137 5.92817 8C5.92817 4.68629 8.61446 2 11.9282 2C15.2419 2 17.9282 4.68629 17.9282 8ZM2.67381 23.2511C4.79955 20.0594 8.154 18 11.9282 18C15.7023 18 19.0568 20.0594 21.1825 23.2511C21.6482 23.9503 21.8811 24.2999 21.8543 24.7466C21.8334 25.0943 21.6054 25.52 21.3276 25.7301C20.9707 26 20.4799 26 19.4984 26H4.35798C3.3764 26 2.88561 26 2.52875 25.7301C2.2509 25.52 2.02295 25.0943 2.00208 24.7466C1.97527 24.2999 2.20812 23.9503 2.67381 23.2511Z"
                            stroke="#5747AF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </div>
                <h3>Сами определяете количество учеников</h3>
                <p>Каждый месяц мы организовываем семинары от самых выдающихся членов профессионального
                    сообщества</p>
            </div>
            <div class="benefit-card">
                <div class="benefit-icon">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M22.666 19.3331V15.3257C22.666 15.0864 22.666 14.9667 22.6296 14.8611C22.5974 14.7677 22.5448 14.6826 22.4756 14.612C22.3974 14.5321 22.2904 14.4786 22.0764 14.3716L15.9993 11.3331M5.33268 12.6664V21.7419C5.33268 22.2378 5.33268 22.4857 5.41004 22.7028C5.47843 22.8947 5.58988 23.0684 5.73585 23.2106C5.90097 23.3713 6.12638 23.4746 6.57715 23.6813L15.1105 27.5924C15.4375 27.7422 15.601 27.8172 15.7713 27.8467C15.9222 27.8729 16.0765 27.8729 16.2274 27.8467C16.3977 27.8172 16.5612 27.7422 16.8882 27.5924L25.4215 23.6813C25.8723 23.4746 26.0977 23.3713 26.2628 23.2106C26.4088 23.0684 26.5203 22.8947 26.5887 22.7028C26.666 22.4857 26.666 22.2378 26.666 21.7419V12.6664M2.66602 11.3331L15.5223 4.90494C15.6972 4.81749 15.7847 4.77376 15.8764 4.75655C15.9577 4.74131 16.041 4.74131 16.1223 4.75655C16.214 4.77376 16.3015 4.81749 16.4764 4.90494L29.3327 11.3331L16.4764 17.7612C16.3015 17.8487 16.214 17.8924 16.1223 17.9096C16.041 17.9249 15.9577 17.9249 15.8764 17.9096C15.7847 17.8924 15.6972 17.8487 15.5223 17.7612L2.66602 11.3331Z"
                            stroke="#5747AF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <h3>Бесплатно учитесь онлайн-преподаванию</h3>
                <p>Каждый месяц мы организовываем семинары от самых выдающихся членов профессионального
                    сообщества</p>
            </div>
            <div class="benefit-card">
                <div class="benefit-icon">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M22.666 19.3331V15.3257C22.666 15.0864 22.666 14.9667 22.6296 14.8611C22.5974 14.7677 22.5448 14.6826 22.4756 14.612C22.3974 14.5321 22.2904 14.4786 22.0764 14.3716L15.9993 11.3331M5.33268 12.6664V21.7419C5.33268 22.2378 5.33268 22.4857 5.41004 22.7028C5.47843 22.8947 5.58988 23.0684 5.73585 23.2106C5.90097 23.3713 6.12638 23.4746 6.57715 23.6813L15.1105 27.5924C15.4375 27.7422 15.601 27.8172 15.7713 27.8467C15.9222 27.8729 16.0765 27.8729 16.2274 27.8467C16.3977 27.8172 16.5612 27.7422 16.8882 27.5924L25.4215 23.6813C25.8723 23.4746 26.0977 23.3713 26.2628 23.2106C26.4088 23.0684 26.5203 22.8947 26.5887 22.7028C26.666 22.4857 26.666 22.2378 26.666 21.7419V12.6664M2.66602 11.3331L15.5223 4.90494C15.6972 4.81749 15.7847 4.77376 15.8764 4.75655C15.9577 4.74131 16.041 4.74131 16.1223 4.75655C16.214 4.77376 16.3015 4.81749 16.4764 4.90494L29.3327 11.3331L16.4764 17.7612C16.3015 17.8487 16.214 17.8924 16.1223 17.9096C16.041 17.9249 15.9577 17.9249 15.8764 17.9096C15.7847 17.8924 15.6972 17.8487 15.5223 17.7612L2.66602 11.3331Z"
                            stroke="#5747AF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <h3>Сами устанавливаете расписание</h3>
                <p>Каждый месяц мы организовываем семинары от самых выдающихся членов профессионального
                    сообщества</p>
            </div>
            <div class="benefit-card">
                <div class="benefit-icon">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M28 13.3337H4M21.3333 2.66699V8.00033M10.6667 2.66699V8.00033M10.4 29.3337H21.6C23.8402 29.3337 24.9603 29.3337 25.816 28.8977C26.5686 28.5142 27.1805 27.9023 27.564 27.1496C28 26.294 28 25.1739 28 22.9337V11.7337C28 9.49345 28 8.37334 27.564 7.5177C27.1805 6.76505 26.5686 6.15313 25.816 5.76963C24.9603 5.33366 23.8402 5.33366 21.6 5.33366H10.4C8.15979 5.33366 7.03969 5.33366 6.18404 5.76963C5.43139 6.15313 4.81947 6.76505 4.43597 7.5177C4 8.37334 4 9.49345 4 11.7337V22.9337C4 25.1739 4 26.294 4.43597 27.1496C4.81947 27.9023 5.43139 28.5142 6.18404 28.8977C7.03969 29.3337 8.15979 29.3337 10.4 29.3337Z"
                            stroke="#5747AF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <h3>Попадаете в сообщество профессионалов</h3>
                <p>Вас не нужно искать в ученики или приглашать его к себе домой</p>
            </div>
            <div class="benefit-card">
                <div class="benefit-icon">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.3327 19.3799C14.9774 18.4293 17.0211 18.4293 18.6659 19.3799M2.66602 20.0003L3.60133 10.6471C3.6368 10.2925 3.65453 10.1152 3.68172 9.9631C4.00231 8.16968 5.49428 6.81947 7.31071 6.67891C7.46477 6.66699 7.64296 6.66699 7.99935 6.66699M29.3327 20.0003L28.3974 10.6471C28.3619 10.2925 28.3442 10.1152 28.317 9.9631C27.9964 8.16968 26.5044 6.81947 24.688 6.67891C24.5339 6.66699 24.3557 6.66699 23.9993 6.66699M11.7706 16.2291C13.8534 18.3119 13.8534 21.6888 11.7706 23.7716C9.6878 25.8544 6.31091 25.8544 4.22811 23.7716C2.14532 21.6888 2.14532 18.3119 4.22811 16.2291C6.3109 14.1463 9.68779 14.1463 11.7706 16.2291ZM27.7706 16.2291C29.8534 18.3119 29.8534 21.6888 27.7706 23.7716C25.6878 25.8544 22.3109 25.8544 20.2281 23.7716C18.1453 21.6888 18.1453 18.3119 20.2281 16.2291C22.3109 14.1463 25.6878 14.1463 27.7706 16.2291Z"
                            stroke="#5747AF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <h3>Искать учеников теперь проще</h3>
                <p>Вас не нужно искать в ученики или приглашать его к себе домой</p>
            </div>
            <div class="benefit-card">
                <div class="benefit-icon">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.3333 16.6667C11.3333 16.6667 13.0833 18.6667 16 18.6667C18.9167 18.6667 20.6667 16.6667 20.6667 16.6667M19.6667 10H19.68M12.3333 10H12.3467M9.33333 24V27.114C9.33333 27.8245 9.33333 28.1797 9.47897 28.3622C9.60563 28.5208 9.79769 28.6131 10.0007 28.6129C10.2342 28.6127 10.5116 28.3908 11.0663 27.9469L14.247 25.4024C14.8967 24.8826 15.2216 24.6228 15.5833 24.4379C15.9043 24.274 16.2459 24.1541 16.599 24.0817C16.9969 24 17.4129 24 18.245 24H21.6C23.8402 24 24.9603 24 25.816 23.564C26.5686 23.1805 27.1805 22.5686 27.564 21.816C28 20.9603 28 19.8402 28 17.6V10.4C28 8.15979 28 7.03968 27.564 6.18404C27.1805 5.43139 26.5686 4.81947 25.816 4.43597C24.9603 4 23.8402 4 21.6 4H10.4C8.15979 4 7.03969 4 6.18404 4.43597C5.43139 4.81947 4.81947 5.43139 4.43597 6.18404C4 7.03968 4 8.15979 4 10.4V18.6667C4 19.9066 4 20.5266 4.1363 21.0353C4.50617 22.4156 5.58436 23.4938 6.96472 23.8637C7.47339 24 8.09337 24 9.33333 24ZM20.3333 10C20.3333 10.3682 20.0349 10.6667 19.6667 10.6667C19.2985 10.6667 19 10.3682 19 10C19 9.63181 19.2985 9.33333 19.6667 9.33333C20.0349 9.33333 20.3333 9.63181 20.3333 10ZM13 10C13 10.3682 12.7015 10.6667 12.3333 10.6667C11.9651 10.6667 11.6667 10.3682 11.6667 10C11.6667 9.63181 11.9651 9.33333 12.3333 9.33333C12.7015 9.33333 13 9.63181 13 10Z"
                            stroke="#5747AF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <h3>Можно работать из любой точки мира</h3>
                <p>Вас не нужно искать в ученики или приглашать его к себе домой</p>
            </div>
        </div>
        <Spacer64 />
        <div class="title">
            Как начать сотрудничать
        </div>
        <SpacerModule24 />
        <SpacerModule16 />
        <!-- <div class="tiles">
            <div v-for="(tile, index) in tiles" :key="tile.id" class="tile" :class="'tile-' + (index + 1)">
                <tit>{{ tile.title }}</tit>
                <tex>{{ tile.description }}</tex>
                <div :style="getDynamicStyle(index)" class="tile-number">{{ index + 1 }}</div>
            </div>
        </div> -->
        <div class="tiles">
            <div v-for="(tile, index) in tiles" :key="tile.id" class="tile" :class="'tile-' + (index + 1)">
                <tit>{{ tile.title }}</tit>
                <tex>{{ tile.description }}</tex>
                <div class="tile-number" :class="'tile-number-' + (index + 1)">{{ index + 1 }}</div>
            </div>
        </div>
        <div class="button-container">
            <div class="button" @click="navigateToPage('/register')">
                Стать наставником
            </div>
        </div>
    </div>

</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import SpacerModule24 from '../Spacers/SpacerModule24.vue';
import SpacerModule16 from '../Spacers/SpacerModule16.vue';
import Spacer64 from '../Spacers/SpacerModule64.vue';

const router = useRouter();

function getDynamicStyle(index) {
    // Пример изменения цвета текста в зависимости от индекса
    const colors = ['#FAF7FD1A', '#C8C2FB33', '#C8C2FB26', '#C8C2FB1A'];
    return {
        color: colors[index % colors.length] || '#000' // Обычный fallback цвет
    };
}

const tiles = ref([
    {
        id: 1,
        title: 'Заполните анкету',
        description: 'После отправки анкеты мы свяжемся с вами в WhatsApp или Telegram'
    },
    {
        id: 2,
        title: 'Пройдите интервью',
        description: 'Интервью длится 30 минут и проходит по Zoom в удобное для вас время'
    },
    {
        id: 3,
        title: 'Познакомьтесь с платформой',
        description: 'Расскажем о правилах работы сервиса, его особенностях и поможем с первыми клиентами'
    },
    {
        id: 4,
        title: 'Попробуйте открытые мастер-классы',
        description: 'Проведите групповое занятие и привлеките внимание к своим услугам, а мы поднимем ваше событие в топ и поможем набрать участников'
    }
]);

const navigateToPage = (route) => {
    router.push(route);
};
</script>
<style scoped>
.HomeHow {
    border-radius: 32px;
    background-color: #998BF8;
    color: #525A7D;
    padding-left: 32px;
    padding-right: 32px;
}

.title {
    padding-top: 60px;
    font-size: 64px;
    font-weight: 550;
    line-height: 72px;
    letter-spacing: -0.02em;
    text-align: center;
    color: white;
}

.tiles {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-bottom: 56px;
}

.tile {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    height: 22.5em;
    flex: 1;
    position: relative;
    padding-left: 38px;
    margin-left: -38px;
}

.tile:first-child {
    margin-left: 0;
}

.tile:nth-child(1) {
    background: var(--accent-accent200, #C8C2FB);
    z-index: 4;
    padding-left: 0px;
    overflow: hidden;
}

.tile:nth-child(2) {
    background: var(--accent-accent100, #DCD8FC);
    z-index: 3;
    overflow: hidden;
}

.tile:nth-child(3) {
    background: var(--accent-accent50, #FAF7FD);
    z-index: 2;
    overflow: hidden;
}

.tile:nth-child(4) {
    background: #FEFDFF;
    z-index: 1;
    overflow: hidden;
}


/* ['#FAF7FD1A', '#C8C2FB33', '#C8C2FB26', '#C8C2FB1A']; */
@media (min-width: 768px) {
    .tile-number-1 {
        color: #FAF7FD1A;
    }

    .tile-number-2 {
        color: #C8C2FB33;
    }

    .tile-number-3 {
        color: #C8C2FB26;
    }

    .tile-number-4 {
        color: #C8C2FB1A;
    }
}


.tile-number {
    z-index: -1;
    font-size: 500px;
    position: absolute;
    padding: 0;
    margin: 0;
    bottom: -200px;
    right: -40px;
    font-weight: 900;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform;
}


.tile tit {
    padding: 24px 44px 20px 24px;
    font-size: 32px;
    font-weight: 550;
    line-height: 36px;
    text-align: left;
    margin-bottom: auto;
}

.tile tex {
    padding: 24px 44px 20px 24px;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.button-container {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
}

.button {
    display: inline-flex;
    padding: 14px 24px;
    border-radius: 16px;
    background: var(--accent-accent600, rgba(112, 92, 226, 1));
    color: #DAD9DC;
}

.button:hover {
    cursor: pointer;
    background-color: #DAD9DC;
    color: #525A7D;
    transition: background 0.3s ease;
    transition: color 0.3s ease;
}

.benefits {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 0.7fr;
    gap: 20px;
}

.benefit-card {
    background: white;
    padding: 24px;
    border-radius: 16px;
    margin-bottom: 0px;
}

.benefit-icon {
    margin-bottom: 16px;
}

.benefit-card h3 {
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.benefit-card p {
    font-family: Tilda Sans VF;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--accent-accent800, #5747AF);
}

/* Mobile styles */
@media (max-width: 768px) {
    .HomeHow {
        border-radius: 0;
        padding-left: 16px;
        padding-right: 16px;
        min-height: 100vh;
        border-radius: 32px;
        background-color: #998BF8;
        color: #525A7D;
    }

    .title {
        padding-top: 32px;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 24px;
    }

    .tiles {
        flex-direction: column;
        gap: 16px;
        padding-bottom: 40px;
    }

    .tile {
        /* height: auto; */
        padding-left: 0;
        margin-left: 0;
        border-radius: 16px;
        padding: 24px;
        min-height: 260px;
        justify-content: space-between;
        overflow: hidden;
    }

    .tile:nth-child(1) {
        padding-left: 24px;
        background: var(--accent-accent100, #DCD8FC);
    }

    .tile:nth-child(2),
    .tile:nth-child(4) {
        background: #FEFDFF;
    }

    .tile:nth-child(3) {
        background: var(--accent-accent100, #DCD8FC);
    }



    .tile-number {
        position: absolute;
        font-size: 446px;
        transform: translate(10px, 120px);
        z-index: -1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        will-change: transform;
    }

    .tile-number-1,
    .tile-number-3 {
        color: #C8C2FB40;
        filter: blur(4px);
    }

    .tile-number-2,
    .tile-number-4 {
        color: #C8C2FB1A;
        filter: blur(4px);
    }

    .tile tit {
        padding: 0;
        margin-bottom: 8px;
        color: var(--accent-accent700, #6553CA);
        font-size: 32px;
        font-weight: 600;
        line-height: 38px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        width: 100%;
    }

    .tile tex {
        padding: 0;
        color: var(--accent-accent800, #5747AF);

        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        width: 100%;


    }

    .benefits {
        grid-template-columns: 1fr;
        gap: 16px;
    }

    .benefit-card {
        padding: 24px;
    }

    .benefit-icon {
        width: 48px;
        height: 48px;
        background-color: #FAF7FD;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .benefit-card h3 {
        font-size: 18px;
        line-height: 22px;
    }

    .benefit-card p {
        font-size: 14px;
        line-height: 18px;
    }

    .button-container {
        padding-bottom: 32px;
    }

    .button {
        width: 100%;
        justify-content: center;
    }
}
</style>