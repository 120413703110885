import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-center mb-2"
}

import { computed } from 'vue';


interface EventData {
  id: number;
  title: string;
  date: string;
  description?: string;
  status?: 'live' | 'cancelled' | 'done' | 'pending';
  role: 'leading' | 'participating';
  sessionType?: string;
  bookingId?: number;
  mentorName?: string;
  mentorAvatar?: string;
  duration?: string;
  cost?: string;
  question?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EventCard',
  props: {
    event: {},
    mode: {}
  },
  emits: ["openEvent", "joinEvent", "editEvent", "cancelEvent", "leaveEvent"],
  setup(__props: any, { emit: __emit }) {

/* eslint-disable*/
const props = __props;


const emit = __emit;

const cardColor = computed(() => {
  if (props.mode === 'leading') return '#7B65F7';
  if (props.mode === 'participating') return '#EAE8FD';
  return undefined;
});

const formattedDate = computed(() => {
    const d = new Date(props.event.date);
    return d.toLocaleString('ru-RU', {
        day: 'numeric',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit',
    });
});

const getLiveStatusText = computed(() => {
    if (props.event.status === 'live') {
        // Можно добавить логику для отображения времени в эфире
        return '20 минут в эфире';
    }
    return '';
});

function emitOpenEvent() {
    emit('openEvent', props.event.id);

}

function emitjoinEvent() {
    emit('joinEvent', props.event.id);

}

function emitEditEvent() {
    emit('editEvent', props.event.id);
}

function emitCancelEvent() {
    emit('cancelEvent', props.event.id);
}

function emitLeaveEvent() {
    emit('leaveEvent', props.event.id);
}

return (_ctx: any,_cache: any) => {
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card_item = _resolveComponent("v-card-item")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    color: cardColor.value,
    class: _normalizeClass({ 'white--text': _ctx.mode === 'leading' }),
    variant: "elevated",
    rounded: "lg"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_item, null, {
        default: _withCtx(() => [
          (_ctx.event.sessionType)
            ? (_openBlock(), _createBlock(_component_v_chip, {
                key: 0,
                size: "small",
                color: _ctx.mode === 'leading' ? 'rgba(255, 255, 255, 0.2)' : 'primary',
                "text-color": _ctx.mode === 'leading' ? 'white' : '',
                variant: "flat",
                class: "mb-2"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.event.sessionType), 1)
                ]),
                _: 1
              }, 8, ["color", "text-color"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.event.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_subtitle, {
            class: _normalizeClass({ 'white--text': _ctx.mode === 'leading' })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(formattedDate.value), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          (_ctx.event.status === 'live')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_v_icon, {
                  color: _ctx.mode === 'leading' ? 'white' : 'success',
                  size: "small",
                  class: "mr-2"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" mdi-circle ")
                  ])),
                  _: 1
                }, 8, ["color"]),
                _createElementVNode("span", {
                  class: _normalizeClass({ 'white--text': _ctx.mode === 'leading' })
                }, _toDisplayString(getLiveStatusText.value), 3)
              ]))
            : _createCommentVNode("", true),
          (_ctx.event.description)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["mb-2", { 'white--text': _ctx.mode === 'leading' }])
              }, _toDisplayString(_ctx.event.description), 3))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createElementVNode("div", null, " conference_status: " + _toDisplayString(_ctx.event.conferenceStatus), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          (_ctx.event.status === 'live')
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                color: _ctx.mode === 'leading' ? 'white' : 'primary',
                "text-color": _ctx.mode === 'leading' ? 'primary' : '',
                variant: "text",
                onClick: emitjoinEvent
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Открыть ")
                ])),
                _: 1
              }, 8, ["color", "text-color"]))
            : (_ctx.mode === 'participating' || (_ctx.mode === 'all' && _ctx.event.role === 'participating'))
              ? _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                  key: 1,
                  color: _ctx.mode === 'leading' ? 'white' : 'primary',
                  "text-color": _ctx.mode === 'leading' ? 'primary' : '',
                  variant: "text",
                  onClick: emitjoinEvent
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Присоединиться ")
                  ])),
                  _: 1
                }, 8, ["color", "text-color"])), [
                  [_vShow, _ctx.event.status !== 'done']
                ])
              : _createCommentVNode("", true),
          _createVNode(_component_v_btn, {
            color: _ctx.mode === 'leading' ? 'white' : 'primary',
            "text-color": _ctx.mode === 'leading' ? 'primary' : '',
            variant: "text",
            onClick: emitOpenEvent
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" Подробнее ")
            ])),
            _: 1
          }, 8, ["color", "text-color"]),
          _createVNode(_component_v_spacer),
          (_ctx.mode === 'leading' || (_ctx.mode === 'all' && _ctx.event.role === 'leading'))
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 2,
                color: _ctx.mode === 'leading' ? 'white' : 'error',
                "text-color": _ctx.mode === 'leading' ? 'error' : '',
                variant: "text",
                onClick: emitCancelEvent
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Отменить ")
                ])),
                _: 1
              }, 8, ["color", "text-color"]))
            : (_ctx.mode === 'participating' || (_ctx.mode === 'all' && _ctx.event.role === 'participating'))
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 3,
                  color: _ctx.mode === 'leading' ? 'white' : 'error',
                  "text-color": _ctx.mode === 'leading' ? 'error' : '',
                  variant: "text",
                  onClick: emitLeaveEvent
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Отказаться ")
                  ])),
                  _: 1
                }, 8, ["color", "text-color"]))
              : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["color", "class"]))
}
}

})