<template>
    <div class="masonry-item" :class="{ 'overlay-layout': shouldOverlay }">
        <div class="image-container" ref="imageContainer">
            <img class="masonry-item-img" :src="event_imageUrl" :alt="event_title" @load="onImageLoad" />
            <div v-if="shouldOverlay" class="card-overlay">
                <div class="overlay-content">
                    <div class="bottom-content">
                        <div class="top-row">
                            <div class="date">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M16 2V6" stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M8 2V6" stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M3 10H21" stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                                {{ formated(event_date) }}
                            </div>
                            <!-- <div class="likes">
                                <svg class="like-icon" width="20" height="16" viewBox="0 0 20 18" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M2.31802 2.31802C0.56066 4.07538 0.56066 6.92462 2.31802 8.68198L10.0001 16.364L17.682 8.68198C19.4393 6.92462 19.4393 4.07538 17.682 2.31802C15.9246 0.56066 13.0754 0.56066 11.318 2.31802L10.0001 3.63609L8.68198 2.31802C6.92462 0.56066 4.07538 0.56066 2.31802 2.31802Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                                {{ event_price }}
                            </div> -->
                            <v-chip size="small" class="info-tag">
                                <template v-slot:prepend>
                                    <v-icon size="x-small" class="mr-1">mdi-currency-rub</v-icon>
                                </template>
                                {{ event_price == 0 ? "Бесплатно" : event_price}}
                            </v-chip>
                        </div>
                        <div class="content-divider"></div>
                        <h2 class="event-title" :style="titleStyle" ref="eventTitle">{{ displayTitle }}</h2>
                        <div class="mentor-info">
                            <img class="mentor-image" :src="mentor.mentor_avatar_url">
                            <span class="mentor-name">{{ mentor.mentor_name + " " + mentor.mentor_surname }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!shouldOverlay" class="content-below">
            <div class="stats-row">
                <div class="date">

                    <svg class="calendar-icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
                            stroke="#454152" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M16 2V6" stroke="#454152" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M8 2V6" stroke="#454152" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M3 10H21" stroke="#454152" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                    {{ formated(event_date) }}
                </div>
                <!-- <div class="likes">
                    <svg width="20" height="16" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.31802 2.31802C0.56066 4.07538 0.56066 6.92462 2.31802 8.68198L10.0001 16.364L17.682 8.68198C19.4393 6.92462 19.4393 4.07538 17.682 2.31802C15.9246 0.56066 13.0754 0.56066 11.318 2.31802L10.0001 3.63609L8.68198 2.31802C6.92462 0.56066 4.07538 0.56066 2.31802 2.31802Z"
                            stroke="#7B65F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    {{ event_totalSpots - event_availiableSpots }}
                </div> -->
                <v-chip size="small" class="info-tag">
                    <template v-slot:prepend>
                        <v-icon size="x-small" class="mr-1">mdi-currency-rub</v-icon>
                    </template>
                    {{ event_price == 0 ? "Бесплатно" : event_price}}
                </v-chip>
            </div>
            <h2 class="event-title">{{ displayTitle }}</h2>
            <div class="mentor-info">
                <img class="mentor-image" :src="mentor.mentor_avatar_url">
                <span class="mentor-name">{{ mentor.mentor_name + " " + mentor.mentor_surname }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, nextTick } from 'vue';
import moment from 'moment';
import axios from 'axios';

export default {
    name: 'EventCard',
    props: {
        id: {
            type: String,
            required: true
        },
        event_title: {
            type: String,
            required: true
        },
        event_date: {
            type: Date,
            required: true
        },
        event_price: {
            type: Number,
            required: true
        },
        event_imageUrl: {
            type: String,
            required: true
        },
        event_ageRestriction: {
            type: Number,
            required: true
        },
        event_availiableSpots: {
            type: Number,
            required: true
        },
        event_totalSpots: {
            type: Number,
            required: true
        },
        event_category: {
            type: String,
            required: true
        },
        event_id_mentor: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const imageContainer = ref(null);
        const eventTitle = ref(null);
        const titleStyle = ref({});
        const mentor = ref({});

        // const shouldOverlay = computed(() => props.event_title.length > 120);
        const shouldOverlay = computed(() => {
            if (props.event_title) {
                return window.innerWidth > 1024 && props.event_title.length > 120;
            }
            return false
        });

        const displayTitle = computed(() => {
            if (props.event_title) {
                if (props.event_title.length > 180) {
                    return props.event_title.slice(0, 180) + '...';
                }
            }
            return props.event_title;
        });

        const adjustTitleHeight = () => {
            if (shouldOverlay.value && imageContainer.value && eventTitle.value) {
                const imageHeight = imageContainer.value.offsetHeight;
                const maxTitleHeight = imageHeight * 0.3;
                const titleHeight = eventTitle.value.scrollHeight;

                if (titleHeight > maxTitleHeight) {
                    titleStyle.value = {
                        maxHeight: `${maxTitleHeight}px`,
                        overflow: 'hidden',
                        display: '-webkit-box',
                        '-webkit-line-clamp': Math.floor(maxTitleHeight / 24) - 1, // Assuming line-height is about 24px
                        '-webkit-box-orient': 'vertical'
                    };
                } else {
                    titleStyle.value = {};
                }
            }
        };

        const onImageLoad = () => {
            nextTick(adjustTitleHeight);
        };

        async function fetchMentor() {
            try {
                const mentorId = props.event_id_mentor;
                const response = await axios.get('https://platforma34.tw1.ru/api/users/GetUser/?id=' + mentorId);
                mentor.value = response.data;
                console.log(response.data)
                console.log(mentor.value)
            } catch (error) {
                console.error('Error fetching mentor data:', error);
            }
        }

        onMounted(() => {
            fetchMentor();
            adjustTitleHeight();
            window.addEventListener('resize', adjustTitleHeight);
            // window.addEventListener('resize', this.handleResize);
        });

        // onBeforeUnmount(() => {
        //     window.removeEventListener('resize', this.handleResize);
        // });

        return {
            imageContainer,
            eventTitle,
            titleStyle,
            shouldOverlay,
            displayTitle,
            onImageLoad,
            mentor
        };
    },
    methods: {
        formated(value) {
            if (value) {
                return moment(String(value.toLocaleString('ru-RU'))).format('DD.MM');
            }
            return value
        },
    },
}
</script>

<style scoped>
.masonry-item {
    border-radius: 8px;
    overflow: hidden;
    background: white;
    transition: transform 0.3s ease;
}

.masonry-item:hover {
    transform: scale(1.02);
    cursor: pointer;
}

.image-container {
    position: relative;
    width: 100%;
}

.masonry-item-img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px 8px 0 0;
}

/* Overlay Layout */
.overlay-layout .image-container {
    border-radius: 8px;
}

.overlay-layout .masonry-item-img {
    border-radius: 8px;
}

.card-overlay {
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.9) 100%);
    border-radius: 8px;
}

.overlay-content {
    position: absolute;
    inset: 0;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: white;
}

.top-row,
.stats-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Content Below Layout */
.content-below {
    padding: 16px;
}

.event-title {
    font-size: 24px;
    line-height: 1.2;
    margin: 16px 0;
    font-weight: 500;
}

.overlay-layout .event-title {
    color: white;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.content-below .event-title {
    color: #232323;
}

.mentor-info {
    display: flex;
    align-items: center;
    gap: 8px;
}

.mentor-image {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    object-fit: cover;
}

.mentor-name {
    font-size: 20px;
}

.overlay-layout .mentor-name {
    color: white;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.content-below .mentor-name {
    color: #6f6f6f;
}

.date,
.likes {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 20px;
}

.overlay-layout .date,
.overlay-layout .likes {
    color: white;
}

.content-below .date {
    color: #454152;
}

.content-below .likes {
    color: #7B65F7;
}

.content-divider {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.3);
    margin: 8px 0;
}

.bottom-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.calendar-icon {
    width: 24px;
    height: 24px;
}

.like-icon {
    width: 20px;
    height: 16px;
}

.info-tag {
    color: #5747af;
    font-size: 12px;
    font-weight: 500;
    background: #dcd8fcc7;
}

@media (max-width: 1024px) {
    .masonry-item {
        border-radius: 8px;
        overflow: hidden;
        background: none;
    }

    .image-container {
        border-radius: 8px 8px 0 0;
    }

    .masonry-item-img {
        border-radius: 8px 8px 8px 8px;
    }

    .card-overlay {
        display: none;
    }

    .content-below {
        display: block;
        padding: 8px;
    }

    .event-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: var(--Base-Base-Black, #2A2037);
        margin: 8px 0;
        margin-top: 4px;
    }

    .mentor-name {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.01em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: var(--neutral-neutral900, #434966);
    }

    .date,
    .likes {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }
    .calendar-icon {
        width: 18px;
        height: 18px;
    }

    .like-icon {
        width: 16px;
        height: 12px;
    }
}
</style>






<!-- <template>
    <div class="masonry-item">
        <img class="masonry-item-img" :src="event_imageUrl" :alt="event_title" />
        <div class="mentor-info">
            <img class="mentor-image" src="https://avatars.dzeninfra.ru/get-zen_doc/1860870/pub_630be06b4adab22ce4602f20_630be0af5847ad612e927127/scale_1200">
            <div class="truncate">
                Сергей Мейерхольд
            </div>
        </div>
        <div class="multiline-truncate">
            {{ event_title }}
        </div>
        <div class="date-n-likes">

            <span class="event-date">{{ formated(event_date) }}</span>
            <div class="likes">
                <svg width="20" height="16" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2.31802 2.31802C0.56066 4.07538 0.56066 6.92462 2.31802 8.68198L10.0001 16.364L17.682 8.68198C19.4393 6.92462 19.4393 4.07538 17.682 2.31802C15.9246 0.56066 13.0754 0.56066 11.318 2.31802L10.0001 3.63609L8.68198 2.31802C6.92462 0.56066 4.07538 0.56066 2.31802 2.31802Z"
                        stroke="#7B65F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                {{ event_totalSpots - event_availiableSpots }}
            </div>
        </div>
    </div>
</template>

<script>
//   import axios from 'axios';
// import { useDateFormat, useNow } from '@vueuse/core'
import moment from 'moment';
export default {
    name: 'EventCard',
    props: {
        id: {
            type: String,
            required: true
        },
        event_title: {
            type: String,
            required: true
        },
        event_date: {
            type: Date,
            required: true
        },
        event_price: {
            type: Number,
            required: true
        },
        event_imageUrl: {
            type: String,
            required: true
        },
        event_ageRestriction: {
            type: Number,
            required: true
        },
        event_availiableSpots: {
            type: Number,
            required: true
        },
        event_totalSpots: {
            type: Number,
            required: true
        },
        event_category: {
            type: String,
            required: true
        }
    },
    methods: {
        formatDate(d) {
            return d.toLocaleString('ru-RU').replace(',', '').slice(0, -3);
        },
        formated(value) {
            // var some_date = moment(String(value.toLocaleString('ru-RU'))).format('DD.MM.YYYY hh:mm');
            var some_date = moment(String(value.toLocaleString('ru-RU'))).format('DD.MM');
            // some_date = some_date.replace("September", "Сентября");
            // some_date = some_date.replace("October", "Октября");
            // some_date = some_date.replace("November", "Ноября");
            // some_date = some_date.replace("December", "Декабря");
            // some_date = some_date.replace("January", "Января");
            // some_date = some_date.replace("February", "Февраля");
            // some_date = some_date.replace("March", "Марта");
            // some_date = some_date.replace("April", "Апреля");
            // some_date = some_date.replace("May", "Мая");
            // some_date = some_date.replace("June", "Июня");
            // some_date = some_date.replace("July", "Июля");
            // some_date = some_date.replace("August", "Августа");
            return some_date;
        },
    },
}
</script>

<style scoped>
/*
    html, body {
        margin: 0;
        padding: 0;
        width: 100%;
    }
    */

.event-card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #fff;
    width: 100%;
    border-radius: 20px;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    /* Длительность увеличена до 0.5s и плавный переход */
}


.event-card:hover {
    background-color: #f3f4f5;
    color: white;
    cursor: pointer;
    transform: scale(1.02);
    /* Небольшое увеличение карточки при наведении */
    opacity: 0.95;
    /* Изменение прозрачности */
    transition-delay: 0.01s;
    /* Задержка перед началом анимации */
}

.event-image {
    height: 219px;
    object-fit: cover;
    border-radius: 10px;
    width: 100%;
}

.category-image {
    width: 28px;
    border-radius: 14px;
    margin-left: -4px;
    position: relative;
}

.event-info {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.event-time-n-cost {
    display: flex;
    justify-content: left;
    align-items: center;
}

.event-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
}

/* #overlay {
        background-color: white;
        position:relative;
        margin-left: -4px;
        z-index: 2;
    } */

/* .event-date {
    font-size: 20px;
    color: #555;
    padding: 4px;
} */

.event-price {
    font-size: 13px;
    color: #555;
    padding: 4px 8px 4px 8px;
    border-radius: 100px;
    border: solid 1px #DAD9DC;
    background-color: white;
    margin-left: -4px;
    position: relative;
    z-index: 3;
}

.event-price-free {
    font-size: 13px;
    color: white;
    padding: 4px 8px 4px 8px;
    border-radius: 100px;
    border: solid 1px #DAD9DC;
    background: #7552E9;
}

.event-title {
    font-size: 20px;
    font-weight: lighter;
    /* font-weight: 400; */
    color: #454152;
    padding: 4px;
}

.event-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #777;
}

.some-class {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: right;
}

.sold {
    font-size: 13px;
    color: white;
    padding: 4px 8px 4px 8px;
    border-radius: 100px;
    border: solid 1px #DAD9DC;
    background: #454152;
    position: relative;
    z-index: 4;
    margin-left: -4px;
}

.ageRest {
    font-size: 13px;
    color: white;
    padding: 4px 8px 4px 8px;
    border-radius: 100px;
    border: solid 1px #DAD9DC;
    background: #454152;
    position: relative;
    z-index: 5;
    margin-left: -4px;
}

.router {
    text-decoration: none;
    padding: 0;
    margin: 0;
}



/* Each item */
.masonry-item {
    overflow: hidden;
    /* Clip overflowing content */
    border-radius: 8px;
    /* Optional: Rounded corners */
    display: flex;
    flex-direction: column;
}

.masonry-item:hover {
    background-color: #ffffff;
    color: white;
    cursor: pointer;
    transform: scale(1.02);
    /* Небольшое увеличение карточки при наведении */
    opacity: 0.95;
    /* Изменение прозрачности */
    transition-delay: 0.01s;
    /* Задержка перед началом анимации */
}

.masonry-item-img {
    width: 100%;
    /* Full width of the container */
    height: auto;
    /* Maintain aspect ratio */
    display: block;
    /* Remove inline spacing issues */
    border-radius: 8px;
}

.break-words {
    padding: 8px;
    word-wrap: break-word;
    /* Legacy */
    word-break: break-word;
    /* Break long words */
    overflow-wrap: break-word;
    /* Modern standard */
}

.multiline-truncate {
    font-size: 24px;
    color: #232323;
    display: -webkit-box;
    /* Flex-like behavior */
    -webkit-line-clamp: 2;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    /* Vertical orientation */
    overflow: hidden;
    padding: 4px 0px 4px 8px;
    /* Hide the rest */
}

.truncate {
    font-size: 20px;
    color: #6f6f6f;
    white-space: nowrap;
    /* Prevent wrapping */
    overflow: hidden;
    /* Hide overflowed text */
    text-overflow: ellipsis;
    padding: 4px 0px 4px 8px;
    /* Add ellipsis */
}

.event-date {
    font-size: 20px;
    color: #555;
}

.date-n-likes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
    padding-top: 16px;

}

.mentor-image {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    object-fit: cover;

}

.mentor-info {
    display: flex;
    flex-direction: row;
    padding: 8px;
    padding-bottom: 0px;
}

.likes {
    color: #7B65F7;
    font-size: 20px;
    padding-right: 16px;
    font-weight: 600;
}
</style> -->