<template>
    <div class="time-slot" :class="{ 'selected': isSelected }" @click="$emit('click')">
        <div class="time-range">
            {{ startTime }} - {{ endTime }}
        </div>
        <div class="repeat-info">
            {{ repeatInfo }}
        </div>
        <v-btn icon size="x-small" variant="text" @click.stop="$emit('delete')">
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
    startTime: {
        type: String,
        required: true
    },
    endTime: {
        type: String,
        required: true
    },
    repeatType: {
        type: String,
        default: 'none'
    },
    customWeekdays: {
        type: Array,
        default: () => [] // Гарантируем, что всегда массив
    },
    isSelected: {
        type: Boolean,
        default: false
    }
});

defineEmits(['click', 'delete']);

const dayMap = {
    'mon': 'Пн',
    'tue': 'Вт',
    'wed': 'Ср',
    'thu': 'Чт',
    'fri': 'Пт',
    'sat': 'Сб',
    'sun': 'Вс'
};

// Порядок дней недели
const dayOrder = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const repeatInfo = computed(() => {
    switch (props.repeatType) {
        case 'daily':
            return 'Каждый день';
        case 'weekly':
            return 'Каждую неделю';
        case 'custom': {
            // Добавлены фигурные скобки для блока case
            if (!props.customWeekdays || props.customWeekdays.length === 0) {
                return 'По дням: не указано';
            }
            const sortedDays = [...props.customWeekdays].sort((a, b) => {
                return dayOrder.indexOf(a.toLowerCase()) - dayOrder.indexOf(b.toLowerCase());
            });
            return `По дням: ${sortedDays.map(day => dayMap[day.toLowerCase()] || day).join(', ')}`;
        }
        default:
            return 'Без повторений';
    }
});
</script>

<style scoped>
.time-slot {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 100px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: #666;
}

.time-slot:hover {
    background-color: #f0f0f0;
}

.time-range {
    font-weight: bold;
    margin-right: 8px;
}

.repeat-info {
    font-size: 0.8em;
    margin-right: 8px;
}
</style>
